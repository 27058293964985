import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import * as fromContactus from '../Actions/ContactUs.action';
import * as urls from '../../../home/Store/Services/ServiceUrls';
import { Observable, of, pipe } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/Operators';
@Injectable()
export class ContactUsEffect{
    constructor(public cs2:Cs2LibService,public action:Actions){

    }
    @Effect()
    constctUs$:Observable<any>=this.action.pipe(ofType(fromContactus.SENDENQUIRY),
    mergeMap((action:any)=>this.cs2.PostData(urls.UrlModel.Common.ContactUs,action.payload)
    .pipe(map((res)=>new fromContactus.SendEnquirySuccess(res)),
    catchError(err=>of(new fromContactus.SendEnquiryFailed(err))))))
}