import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { APIService } from '../Services/api.service';
import * as photoActions from '../Actions/Photos.action';
import * as galleryActions from '../Actions/Gallery.action';
import * as urls from '../Services/ServiceUrls';
import { mergeMap, map, catchError } from 'rxjs/Operators';
import { Observable, of } from 'rxjs';
@Injectable()
export class PhotoEffect {
  constructor(private action: Actions, private api: APIService) {

  }
  @Effect()
  getPhotos$: Observable<any> = this.action.pipe(ofType(photoActions.LOADPHOTOS),
    mergeMap((action: any) => this.api.PostData(urls.UrlModel.Application.SearchPhotos, action.payload)
      .pipe(map(data => new photoActions.LoadPhotosSuccess(data)))),
    catchError(error => of(new photoActions.LoadPhotosFailed()))
  );
  @Effect()
  getPhotosByALbumid$: Observable<any> = this.action.pipe(ofType(photoActions.LOADPHOTOSBYALBUMID),
    mergeMap((action: any) => this.api.PostData(urls.UrlModel.Application.SearchPhotosByAlbum,action.payload)
    .pipe(map(data =>new photoActions.LoadPhotoByAlbumSuccess(data)))),
    catchError(error => of(new photoActions.LoadPhotosFailed()))
  );
  @Effect()
  getPortfolioImages$: Observable<any> = this.action.pipe(ofType(galleryActions.GETPORTFOLIOIMAGES),
    mergeMap((action: any) => this.api.getData(urls.UrlModel.Application.GetPortfolioImages+action.payload)
    .pipe(map(data =>new galleryActions.GetPortfolioImagesSuccess(data)))),
    catchError(error => of(new galleryActions.GetPortfolioImagesFailed(error)))
  );
}
