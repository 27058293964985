import { createSelector } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
import * as hma from '../../Store/Actions/HomepageMenu.Action';
export const HomeMenuInitialState = {
  loading: false,
  mainMenu: [
    // { path: '/home/Listing', title: 'Wedding Vendors', icon: 'people_outline' },
    { path: '/home/Photos', title: 'Photos', icon: 'photo' },
    { path: '/home/Tips', title: 'Tips', icon: 'done' },
    { path: '/home/Events', title: 'Events', icon: 'date_range' },
    { path: '/home/Deals', title: 'Deals', icon: 'swap_horiz' },
    { path: '/home/honeymoondestinations', title: 'Honeymoon', icon: 'deck' },
    // { path: '/home/Login', title: 'Login' }
  ]
};
export function HomeMenuReducer(state = HomeMenuInitialState, action: hma.HomepageMenuActions) {
  switch (action.type) {
    case hma.LOADMENU:
      return state;
    default:
      return state;
  }
}
export const getHomeMenuReducer = (state: APPSTATE) => state;
export const SelectMainMenu_Home = createSelector(getHomeMenuReducer, (state: any) => state.homemenureducers ? state.homemenureducers.mainMenu : []);
