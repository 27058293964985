import { Action, createSelector } from '@ngrx/store';
import * as fromBudgetAction from '../../Actions/Couple/MyBudget.action';
import { APPSTATE } from '../../HomePageState';
export const BudgetInitialState = {
    loading: false,
    expenses: {},
    budgetItems: [],
    budgetByCategory: [],
    expenseByCategory: [],
    budgetcategories:[],
    addItemComplet:null,
    
}
export function BudgetReducer(state = BudgetInitialState, action: fromBudgetAction.BudgetActions) {
    switch (action.type) {
        case fromBudgetAction.GETMYEXPENSETOTAL:
            return { ...state, loading: true, }
        case fromBudgetAction.GETMYEXPENSETOTAL_SUCCESS:
            return { ...state, loading: false, expenses: action.payload }
        case fromBudgetAction.GETMYBUDGETITEM:
            return { ...state, loading: true }
        case fromBudgetAction.GETMYBUDGETITEM_SUCCESS:
            return { ...state, loading: false, budgetItems: action.payload }
        case fromBudgetAction.GETMYBUDGETITEMBYCATEGORY:
            return { ...state, loading: true }
        case fromBudgetAction.GETMYBUDGETITEMBYCATEGORY_SUCCESS:
            return { ...state, loading: false, budgetByCategory: action.payload }
        case fromBudgetAction.CREATEUPDATEBUDGETITEM:
            return { ...state, loading: true,addItemComplet:null }
        case fromBudgetAction.CREATEUPDATEBUDGETITEM_SUCCESS:
            return { ...state, loading: false,addItemComplet:action.payload }
        case fromBudgetAction.GETMYEXPENSEBYCATEGORYCOUNT:
            return { ...state, loading: true }
        case fromBudgetAction.GETMYEXPENSEBYCATEGORYCOUNT_SUCCESS:
            return { ...state, loading: false, expenseByCategory: action.payload }
        case fromBudgetAction.DELETEMYBUDGETITEM:
            return { ...state, loading: true }
        case fromBudgetAction.DELETEMYBUDGETITEM_SUCCESS:
            return { ...state, loading: false }
        case fromBudgetAction.GET_MYBUDGETCATEGORY:
            return{...state,loading:true}
        case fromBudgetAction.GET_MYBUDGETCATEGORY_SUCCESS:
            return{...state,loading:false,budgetcategories:action.payload}
        default:
            return { ...state, loading: true }
    }
}
export const getBudgetReducer = (state: APPSTATE) => state;
export const SelectMyExpenses = createSelector(getBudgetReducer, (state: any) => state.budgetreducer ? state.budgetreducer.expenses : null);
export const SelectMyBudgetItems = createSelector(getBudgetReducer, (state: any) => state.budgetreducer ? state.budgetreducer.budgetItems : null);
export const SelectMyBudgetItemsByCategory = createSelector(getBudgetReducer, (state: any) => state.budgetreducer ? state.budgetreducer.budgetByCategory : null);
export const SelectExpenseByCategory = createSelector(getBudgetReducer, (state: any) => state.budgetreducer ? state.budgetreducer.expenseByCategory : null);
export const SelectBudgetCategories=createSelector(getBudgetReducer,(state:any)=> state.budgetreducer?state.budgetreducer.budgetcategories:null);
export const SelectAddUpdateBudgetItemComplete=createSelector(getBudgetReducer,(state:any)=> state.budgetreducer?state.budgetreducer.loading:null);
