import { Injectable } from '@angular/core';
import { ajax } from 'rxjs/ajax';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import * as catAction from '../../../home/Store/Actions/Category.action';
import * as urls from '../../../home/Store/Services/ServiceUrls';
import { Store } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { AuthGuardService } from './../../Store/Services/auth-guard.service';
import { BreakpointObserver } from '@angular/cdk/layout';


@Injectable({ providedIn: 'root' })
export class APIService implements HttpInterceptor {
  public deviceInfo = { ismobile: false };
  public blankImg = 'https://s3.us-east-2.amazonaws.com/prefect-image/wed.jpeg';
  public noImage = 'https://s3.us-east-2.amazonaws.com/prefect-image/store_noimg.jpg';
  public noImg = './assets/img/noImg.png';

  // public ServerPath =   'http://testapp-env.tyad3n63sa.ap-south-1.elasticbeanstalk.com/api/';
  httpOptions: {};
  objUrls = urls.UrlModel;
  mobileView = '(max-width: 599px)';
  userDetails: Observable<any>;
  constructor(public store: Store<APPSTATE>, private client: HttpClient,
    // tslint:disable-next-line: align
    public ObjDeviceSrvice: DeviceDetectorService,
    // tslint:disable-next-line: align
    public ObjRouter: Router, private ObjAuth: AuthGuardService,
    public breakpointobserver: BreakpointObserver) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('authtoken'),
      }),
    };

  }
  getData(url): Observable<any> {
    return this.client.get(url,this.httpOptions);
  }
  responsiveLayout(colsize1, colsize2): string {
    const response = this.breakpointobserver.isMatched(this.mobileView);
    if (response) {
      this.deviceInfo.ismobile = true;
      return colsize1;
    } else {
      return colsize2;
    }
    // return this.breakpointobserver.isMatched(this.mobileView) ? colsize1 : colsize2;
  }
  PostData(url, body): Observable<any> {
    return this.client.post(url,
      body, this.httpOptions);
  }
  DeleteData(url, obj): Observable<any> {
    return this.client.delete(url, obj);
  }
  PutData(url, body): Observable<any> {
    return this.client.put(url, body);
  }
  returnJsonDate(DashedDate) {
    const date = {
      // tslint:disable-next-line: radix
      year: parseInt(DashedDate.split('-')[0]),
      // tslint:disable-next-line: radix
      month: parseInt(DashedDate.split('-')[1]),
      // tslint:disable-next-line: radix
      day: parseInt(DashedDate.split('-')[2]),
    };
    return date;
  }

  dateTFormatToJson(TformatedDate) {
    const date = {
      // tslint:disable-next-line: radix
      year: parseInt(TformatedDate.split('-')[0]),
      // tslint:disable-next-line: radix
      month: parseInt(TformatedDate.split('-')[1]),
      // tslint:disable-next-line: radix
      day: parseInt(TformatedDate.split('-')[2]),
    };
    return date;
  }
  dateJsonTodashed(format) {
    const date = format.year + '-' + format.month + '-' + format.day;
    return date;
  }
  am_pm_to_hours(time) {
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === 'pm' && hours < 12) {
      hours = hours + 12;
    }
    if (AMPM === 'am' && hours === 12) {
      hours = hours - 12;
    }
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) {
      sHours = '0' + sHours;
    }
    if (minutes < 10) {
      sMinutes = '0' + sMinutes;
    }
    return sHours + ':' + sMinutes;
  }

  public bookMark(data, type, ActionWhichLacation, page) {
    let id = 0;
    switch (page) {
      case 'albums': {
        id = data.id;
        break;
      }
      case 'honeymoonpage': {
        id = data.vendorId;
        break;
      }
      case 'searchresult': {
        id = data.vendorId;
        break;
      }
      case 'eventPage': {
        id = data.eventId;
        break;
      }
      case 'promoted_list': {
        id = data.vendorId;
        break;
      }
      case 'inspiration_event': {
        id = data.eventId;
        break;
      }
      case 'inspiration_photo': {
        id = data.inspirationsId;
        break;
      }
      case 'supplier': {
        id = data.vendorId;
        break;
      }
      default: {
        break;
      }
    }
    if (this.ObjAuth.userActive()) {
      this.fillBookmark(id, type, ActionWhichLacation).subscribe(res => {
        
        data.isbookmark = !data.isbookmark;
      });
    } else {
      this.ObjRouter.navigateByUrl('/home?returnURl=' + this.ObjRouter.url);
    }
  }

  fillBookmark(id, type, ActionWhichLacation) {
    const data = {
      id,
      type,
    };
    return this.client.post( 'couple/markasbookmark', data);
  }

  public fillLikeUser(data, type, ActionWhichLacation, page) {
    let id = 0;
    switch (page) {
      case 'albums': {
        id = data.id;
        break;
      }
      case 'honeymoonpage': {
        id = data.vendorId;
        break;
      }
      case 'searchresult': {
        id = data.vendorId;
        break;
      }
      case 'eventPage': {
        id = data.eventId;
        break;
      }
      case 'promoted_list': {
        id = data.vendorId;
        break;
      }
      case 'inspiration_event': {
        id = data.eventId;
        break;
      }
      case 'inspiration_photo': {
        id = data.inspirationsId;
        break;
      }
      case 'supplier': {
        id = data.vendorId;
        break;
      }
      default: {
        break;
      }
    }
    this.fillLike(id, type, ActionWhichLacation).subscribe(Data => {
      
    });
  }
  fillLike(id, type, ActionWhichLacation) {
    const data = {
      id,
      type,
      action: ActionWhichLacation,
      promoted: true,
    };
    return this.client.post('PerfectWedding/loguseraction', data);
  }
  logUserAction(id: number, type: string, action: string, promoted: boolean): Observable<any> {
    return this.client.post('PerfectWedding/loguseraction', { id, type, action, promoted });
  }

  deleteAction(url, data): Observable<any> {
    const authToken = localStorage.getItem('userToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + authToken,
      }),
    };
    return this.client.delete( url, data);
  }

  deleteAction_usingquerystr(url): Observable<any> {
    const authToken = localStorage.getItem('userToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + authToken,
      }),
    };

    return this.client.delete( url, httpOptions);
  }
  removeWhiteSpace(value): number {
    if (value === 'undefined' || value === null || value === undefined) {
      return 0;
    }
    return value.replace(/\s/g, '');
  }

  deleteAction2(url, data) {
    const authToken = localStorage.getItem('userToken');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      }),
      body: data,
    };
    return this.client.delete( url, options);
  }

  deleteActionPath(url, body: any): Observable<any> {
    const authToken = localStorage.getItem('userToken');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + authToken,
      }),
    };
    return this.client.delete(url + body, httpOptions);
  }

  isValidNumber(value) {
    const regex = /^[0-9]+$/;
    return value.match(regex);
  }
  clearSession() {
    sessionStorage.clear();
    localStorage.clear();
    this.ObjRouter.navigateByUrl('/home');
  }
  countLinesInString(str) {
    return str.split(/\r\n|\r|\n/).length;
  }
  getCsvOptions(columnsArray: Array<string>, title: string): any {
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title,
      useBom: true,
      noDownload: false,
      headers: columnsArray,
    };
    return csvOptions;
  }
  getLast_N_Years(Value): Array<any> {
    const yearsList = [];
    const curYear = new Date().getFullYear() - Value;
    for (let i = curYear + 1; i <= curYear + Value; i++) {
      yearsList.push(i);
    }
    return yearsList;
  }
  goBack(url) {
    this.ObjRouter.navigateByUrl(url);
  }
  checkEmailExist(email): Observable<any> {
    return this.getData('PerfectWedding/isemailexists?email=' + email);
  }
  noDataHTML(data): string {
    if (data === undefined || data.length === 0) {
      return '<span class="noData"><i class="material-icons">close</i>No Data Found</span>';
    }
    return '';
  }
  isValidUrl(StringValue) {
    const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    return matcher.test(StringValue);
  }
  intercept(req,next){
      let tokenizeRequest=req.clone({
      setHeaders:{
        Authorization:`Bearer ${localStorage.getItem('authtoken')}`
      }
    });
    return next.handlel(tokenizeRequest);  
    }
}
// export enum DbActions {
//   Create = 1,
//   Read = 2,
//   Update = 3,
//   Delete = 4,
// }
export enum UserType {
  Vendor = 1,
  User = 2,
}
