import * as fromMychecklistActions from '../../Actions/Couple/MyChecklist.action';
import { APPSTATE } from '../../HomePageState';
import { createSelector } from '@ngrx/store';
export const MyChecklistInitialState = {
    loading: false,
    todos: [],
    todosByCategory: [],
    todosBytime: [],
    msg: {},

}
export function MyChecklistReducer(state = MyChecklistInitialState, action: fromMychecklistActions.MyChecklistActions) {
    switch (action.type) {
        case fromMychecklistActions.MYTODOADDUPDATE:
            return { ...state, loading: true }
        case fromMychecklistActions.MYTODOADDUPDATESUCCESS:
            return { ...state, loading: false}
        case fromMychecklistActions.MYTODOGET:
            return { ...state, loading: true}
        case fromMychecklistActions.MYTODOGETSUCCESS:
            return { ...state, loading: false,todos: action.payload }
        case fromMychecklistActions.MYTODOGETFAILED:
            return state
        case fromMychecklistActions.MYTODOBYCATEGORY:
            return { ...state, loading: true }
        case fromMychecklistActions.MYTODOBYCATEGORYSUCCESS:
            return { ...state, loading: false, todosByCategory: action.payload, }
        case fromMychecklistActions.MYTODOBYCATEGORYFAILED:
            return { ...state, loading: false}
        case fromMychecklistActions.MYTODOBYCATEGORYFAILED:
            return state;
        case fromMychecklistActions.MYTODOBYTIME:
            return { ...state, loading: true}
        case fromMychecklistActions.MYTODOBYTIMESUCCESS:
            return { ...state, loading: true,todosBytime: action.payload}
        case fromMychecklistActions.MYTODOBYTIMEFAILED:
            return state;
        default:
            return state;
    }
}
export const getChecklistReducer = (state: APPSTATE) => state;
export const SelectMyTodos = createSelector(getChecklistReducer, (state: any) => state.mychecklistreducer ? state.mychecklistreducer.todos : null);
export const SelectToDosByCategory = createSelector(getChecklistReducer, (state: any) => state.mychecklistreducer ? state.mychecklistreducer.todosByCategory : null);
export const SelectToDosByTime = createSelector(getChecklistReducer, (state: any) => state.mychecklistreducer ? state.mychecklistreducer.todosBytime : null);