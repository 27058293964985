import { Observable } from "rxjs";

export class SearchListingVM {
  page: number;
  pageSize: number;
  searchQuery = "";
  searchInDreamLocation: boolean = false;
  searchInFeaturedVendors: boolean = false;
  districts: number;
  categoryId: number;
  servicesId: number;
  rating: number;
  price: string;
  deals: number;
  listing: number;
  customsFields: Array<FieldSearchVM>;
  customField: FieldSearchVM;
  loggedInUserId = "";
  categories$: Observable<any>;
  honeymoonLocationId: number;
  constructor() {
    this.pageSize = 32;
    this.page = 0;
    this.districts = 0;
    this.categoryId = 0;
    this.servicesId = 0;
    this.price = "";
    this.rating = 0;
    this.deals = 0;
    this.listing = 0;
    this.honeymoonLocationId = 0;
    this.customsFields = new Array<FieldSearchVM>();
    // this.customField = new FieldSearchVM();
  }
}
export class FieldSearchVM {
  customFieldId: number;
  userValue: string;
}
