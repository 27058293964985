import * as FaqsActions from '../Actions/Faqs.action';
import { APPSTATE } from '../HomePageState';
import { createSelector } from '@ngrx/store';

export const InitialFaqsState={
    faqs:[]
}
export function FaqsReducer(state=InitialFaqsState,action:FaqsActions.FaqsActions){
    switch(action.type){
        case FaqsActions.LOADFAQS:
            return {...state,faqs:action.payload}
            case FaqsActions.LOADFAQ__SUCCESS:
                return {...state,faqs:action.payload}
            case FaqsActions.LOADFAQ__FAILED:
                return state;
                default:
                     return state;
    }
}
export const GetFaqState=(state:APPSTATE)=>state;
export const SelectFaqs=createSelector(GetFaqState,(state:any)=>state.faqsreducer?state.faqsreducer.faqs:null);