import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class Cs2LibDataService {
  //urlPattern='/^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/';
  urlPattern='/^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/';
  stunUrl = 'stun.l.google.com:19302';
  commonIcons = IconsList;
  TradingHrs = TradingHours;
  Timings = timings;
  // socialButtons=['facebook','twitter','linkedin','google','pinterest','reddit','tumblr',
  // 'mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','print','copy'];
  socialButtons = ['facebook', 'twitter', 'linkedin', 'messenger', 'whatsapp', 'sms', 'copy',];
  MonthsNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  constructor(public metaService: Meta) {

  }
  getIcon(name): string {
    return this.commonIcons.find(x => x.name.includes(name.toLowerCase())).icon;
  }
  SetMeta(title, url, image, description) {
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'title', content: title });
    this.metaService.updateTag({ name: 'url', content: url });
    this.metaService.updateTag({ name: 'image', content: image });
    //Fb
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:url', content: url });
    this.metaService.updateTag({ property: 'og:image', content: image });
    //Twitter
    this.metaService.updateTag({ name: "twitter:description", content: description });
    this.metaService.updateTag({ name: "twitter:title", content: title });
    this.metaService.updateTag({ name: "twitter:url", content: url });
    this.metaService.updateTag({ name: "twitter:image", content: image });
  }
}
export const IconsList = [
  { name: 'back,previous,last', icon: 'arrow_back' },
  { name: 'close,cancel', icon: 'clear' },
  { name: 'save', icon: 'save' },
  { name: 'delete', icon: 'delete_outline' },
  { name: 'create,edit', icon: 'create' },
  { name: 'block', icon: 'block' },
  { name: 'next', icon: 'navigate_next' },
  { name: 'setting,config', icon: 'settings' },
  { name: 'camera', icon: 'camera_alt' },
  { name: 'add', icon: 'add_circle_outline' },
  { name: 'today,calendar,dt', icon: 'today' },
  { name: 'waiting,inprogress,dt', icon: 'hourglass_empty' },
  { name: 'done,tick', icon: 'done' },
  { name: 'pause,hold', icon: 'pause_circle_outline' },
  { name: 'find,search', icon: 'search' },
  { name: 'show,view', icon: 'remove_red_eye' },
  { name: 'password', icon: 'vpn_key' },
  { name: 'location', icon: 'location_on' },
  { name: 'user', icon: 'person_outline' },
  { name: 'switch', icon: 'import_export' },
  { name: 'share', icon: 'share' },
  { name: 'outgoingcall', icon: 'call_made' },
  { name: 'incomingcall', icon: 'call_received' },
  { name: 'halfstar', icon: 'star_half' },
  { name: 'sunny', icon: 'wb_sunny' },
  { name: 'filter', icon: 'filter_list' },
  { name: 'flag', icon: 'golf_course' },
  { name: 'contact', icon: 'contacts' },
  { name: 'category', icon: 'view_module' },
  { name: 'more_hori', icon: 'more_horiz' },
  { name: 'more_vert', icon: 'more_vert' },
  { name: 'email', icon: 'email' },
  { name: 'desktop', icon: 'personal_video' },
  { name: 'mobile', icon: 'stay_primary_portrait' },
  { name: 'tablet', icon: 'tablet_mac' },
  { name: 'print', icon: 'print' },
  { name: 'home', icon: 'home' },
  { name: 'bed', icon: 'single_bed' },
  { name: 'deck', icon: 'deck' },
  { name: 'membership', icon: 'card_membership' },
  { name: 'info', icon: 'error_outline' },
  { name: 'star', icon: 'stars' },
  { name: 'like,favourate', icon: 'favorite' },
  { name: 'storefront', icon: 'storefront' },
  { name: 'photos', icon: 'photo_album' },
  { name: 'view', icon: 'view' }
];
export const TradingHours = ['24Hrs', '01:00 Am', '01:30 Am', '02:00 Am', '02:30 Am',
  '03:00 Am', '03:30 Am', '04:00 Am', '04:30 Am', '05:00 Am', '05:30 Am',
  '06:00 Am', '06:30 Am', '07:00 Am', '07:30 Am', '08:00 Am', '08:30 Am',
  '09:00 Am', '09:30 Am', '10:00 Am', '10:30 Am', '11:00 Am', '11:30 Am',
  '12:00 Am', '12:00 Am', '01:00 Pm', '01:30 Pm', '02:00 Pm', '02:30 Pm',
  '03:00 Pm', '03:30 Pm', '04:00 Pm', '04:30 Pm', '05:00 Pm', '05:30 Pm',
  '06:00 Pm', '06:30 Pm', '07:00 Pm', '07:30 Pm', '08:00 Pm', '08:30 Pm',
  '09:00 Pm', '09:30 Pm', '10:00 Pm', '10:30 Pm', '11:00 Pm', '11:30 Pm',
  '12:00 Pm', '12:00 Pm',]
export const CS2_DT_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
export const timings = [
  { id: 1, timing: 'From 10 To 12 Months' },
  { id: 2, timing: 'From 07 To 09 Months' },
  { id: 3, timing: 'From 04 To 06 months' },
  { id: 4, timing: 'From 02 To 03 Months' },
  { id: 5, timing: 'Last Month' },
  { id: 6, timing: 'Two Weeks' },
  { id: 7, timing: 'Last Week' },
  { id: 8, timing: 'After The Wedding' },
];
export const bannerHeights = [
  { viewport: 1, height: '450px' },
  { viewport: 2, height: '450px' },
  { viewport: 3, height: '350px' },
  { viewport: 4, height: '450px' },
  { viewport: 5, height: '450px' },
  { viewport: 6, height: '300px' }
];
export enum ViewPorts {
  Desktop = 1,
  Mobile = 2,
  Tablet = 3,
  XlScreen = 4,
  MobileLandScape = 5,
  TabletLandScape = 6,

}
export enum DBActions {
  Create = 1,
  Update = 2,
  Read = 3,
  Delete = 4,
  Cancel = 5,
}
export enum BannerSize {
  DesktopWidth = 1200,
  DesktopHeight = 400,
  MobileWidth = 411,
  MobileHeight = 300,
  TabletWidth = 1025,
  TabletHeight = 395
}
export enum Gender {
  Male = 1,
  Female = 2,
  Other = 3
}
export enum Costtype {
  EstimatedCost = 1,
  FinalCost = 2,
  Paid = 3,
  Pending = 4,
}
export enum FileType {
  Image = 1,
  Video = 2,
  Audio = 3
}  
export enum BackgroundPresetPurpose{
  UserDashboard=1,
  Messenger=2
}  
export enum AlbumType{
  All=0,
  PreWeddingShoots=1,
  MainWedding=2,
  Reception=3,
  Honeymoon=4
}
