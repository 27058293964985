import { Injectable } from '@angular/core';
import { Cs2LibService } from 'projects/cs2-lib/src/lib/cs2-lib.service';
import { Observable, of } from 'rxjs';
import * as FromLeadActions from '../../Actions/Couple/LeadsAction';
import { mergeMap, map, catchError } from 'rxjs/Operators';
import * as urls from '../../../../home/Store/Services/ServiceUrls';
import { Actions, ofType, Effect } from '@ngrx/effects';
@Injectable()
export class MyLeadEffect{
    constructor(public cs2:Cs2LibService,public action:Actions){
    }
    @Effect()
    PostLead$: Observable<any> = this.action.pipe(ofType(FromLeadActions.POSTLEAD),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.Lead.PostLead,action.payload)
            .pipe(map((res) => new FromLeadActions.PostLeadSuccess(res),
                catchError(err => of(new FromLeadActions.PostLeadFailed(err)))
    ))));
    @Effect()
    getMyLead$: Observable<any> = this.action.pipe(ofType(FromLeadActions.GETMYLEADS),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.Lead.GetMyLead,action.payload)
            .pipe(map((res) => new FromLeadActions.GetMyLeadsSuccess(res),
                catchError(err => of(new FromLeadActions.GetMyLeadsFailed(err)))
    ))));
    @Effect()
    getOffer$: Observable<any> = this.action.pipe(ofType(FromLeadActions.GETOFFERSBYLEAD),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.Lead.GetOffer,action.payload)
            .pipe(map((res) => new FromLeadActions.GetOffersByLeadSuccess(res),
                catchError(err => of(new FromLeadActions.GetOffersByLeadFailed(err)))
    ))));
    @Effect()
    ReplyTOOffer$: Observable<any> = this.action.pipe(ofType(FromLeadActions.REPLYTOOFFER),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.Lead.ReplyToOffer,action.payload)
            .pipe(map((res) => new FromLeadActions.ReplyToOfferSuccess(res),
                catchError(err => of(new FromLeadActions.ReplyToOfferFailed(err)))
    ))));
}