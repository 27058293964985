import * as userLogActions from '../Actions/CommonActionLog.action';
import { Injectable } from '@angular/core';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
import * as urls from '../Services/ServiceUrls'
import { map, mergeMap, catchError } from 'rxjs/Operators';
import { of } from 'rxjs';
@Injectable()
export class UserActionLogEffect {
    constructor(public cs2: Cs2LibService, public action: Actions, public store: Store<APPSTATE>) {

    }
    @Effect()
    LogUserAction = this.action.pipe(ofType(userLogActions.USERACTION),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.Common.MarkAsBookmark, action.payload)
            .pipe(map((res) => new userLogActions.UserLogActionSuccess(res),
                catchError(error => of(new userLogActions.UserLogActionFailed(error))
    )))));
    @Effect()
    COnvertMURTOAUD = this.action.pipe(ofType(userLogActions.CONVERTMURTOAUD),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.Common.ConvertMURTOAUD+action.amount)
            .pipe(map((res) => new userLogActions.ConvertMUR_TO_AUD_Success(res),
                catchError(error => of(new userLogActions.ConvertMUR_TO_AUD_failed(error))
    )))));
}