import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { APIService } from '../Services/api.service';

import { Observable, of } from 'rxjs';
import { LOGIN, LoginSuccess, LoginFailed, FORGETPASSWORD, ForgetPasswordSuccess, RESETPASSWORD, ResetPasswordSuccess, FACEBOOKLOGIN, FacebookLoginSuccess, FacebookLoginFailed, GOOGLELOGIN, GoogleLoginSuccess, GoogleLoginFailed } from '../Actions/Login.action';
import { mergeMap, catchError, map, switchMap } from 'rxjs/Operators';

@Injectable()
export class LoginEffect {
  constructor(public action: Actions, public api: APIService) {

  }
  @Effect()
  loginUser$: Observable<any> = this.action.pipe(ofType(LOGIN),
    mergeMap((action: any) => this.api.PostData(this.api.objUrls.Common.Login, action.payload)
      .pipe(map((res: any) => new LoginSuccess(res)),
        catchError(error => of(new LoginFailed(error))
        ))));
  @Effect()
  forgetPassword$: Observable<any> = this.action.pipe(ofType(FORGETPASSWORD),
    mergeMap((action: any) => this.api.PostData(this.api.objUrls.Common.ForgetPassword, action.payload)
      .pipe(map((res: any) => new ForgetPasswordSuccess(res)),
        catchError(error => of(new LoginFailed(error))
        ))));
  @Effect()
  resetPassword$: Observable<any> = this.action.pipe(ofType(RESETPASSWORD),
    mergeMap((action: any) => this.api.PostData(this.api.objUrls.Common.ResetPassword, action.payload)
      .pipe(map((res: any) => new ResetPasswordSuccess(res)),
        catchError(error => of(new LoginFailed(error))
  ))));
  @Effect()
  facebookLogin$: Observable<any> = this.action.pipe(ofType(FACEBOOKLOGIN),
    mergeMap((action: any) => this.api.PostData(this.api.objUrls.Application.FacebookLogin, action.accessToken)
      .pipe(map((res: any) => new FacebookLoginSuccess(res)),
        catchError(error => of(new FacebookLoginFailed(error))
  ))));
  @Effect()
  GoogleLogin$: Observable<any> = this.action.pipe(ofType(GOOGLELOGIN),
    mergeMap((action: any) => this.api.PostData(this.api.objUrls.Application.GoogleLogin, action.accessToken)
      .pipe(map((res: any) => new GoogleLoginSuccess(res)),
        catchError(error => of(new GoogleLoginFailed(error))
  ))));
}
