import * as EnquirieActions  from '../../Actions/Couple/Enquiries.Action';
import { APPSTATE } from '../../HomePageState';
import { createSelector } from '@ngrx/store';

export const EnquiriesInitialState={
    loading:false,
    enquiries:[],
    msg:{},
}
export function MyEnquiriesReducer(state=EnquiriesInitialState,action:EnquirieActions.EnquirieActions){
    switch(action.type){
        case EnquirieActions.POSTENQUIRIES:
            return{...state,loading:true}
        case EnquirieActions.POSTENQUIRIES_SUCCESS:
            return{...state,msg:action.payload,loading:false}
        case EnquirieActions.POSTENQUIRIES_FAILED:
            return{...state,loading:false}
        case EnquirieActions.MYENQUIRIES:
            return{...state,loading:false}
        case EnquirieActions.MYENQUIRIES_SUCCESS:
            return{...state,enquiries:action.payload,loading:false}
        case EnquirieActions.MYENQUIRIES_FAILED:
            return{...state,loading:false}
        default:
            return state;
    }
}
export const GetmyenquiriesReducer=(state:APPSTATE)=>state;
export const SelectPostEnquiryMsg=createSelector(GetmyenquiriesReducer,(state:any)=>state.myenquiriesreducer?state.myenquiriesreducer.msg:null);
export const SelectMyenquiries=createSelector(GetmyenquiriesReducer,(state:any)=>state.myenquiriesreducer?state.myenquiriesreducer.enquiries:null);