import * as urls from '../../../Store/Services/ServiceUrls';
import * as fromMyFeedback from '../../Actions/Couple/MyFeedback.action';
import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/Operators';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
@Injectable()
export class MyFeedbackEffect{
constructor(public cs2:Cs2LibService,public action:Actions){
}
@Effect()
sendFeedback$:Observable<any>=this.action.pipe(ofType(fromMyFeedback.SENDFEEDBACK),
switchMap((action:any)=>this.cs2.postImageData(urls.UrlModel.User.Feedback.SendFeedback,action.payload)
.pipe(map(res=>new fromMyFeedback.SendFeedbackSuccess(res),
catchError(err=>of(new fromMyFeedback.SendFeedbackFailed(err)))
))));
}