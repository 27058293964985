export const GETMYVENDORCOUNT = '[MySuppliers]Get My VendorCount';
export const GETMYVENDORCOUNT_SUCCESS = '[MySuppliers]Get My VendorCount Success';
export const GETMYVENDORCOUNT_FAILED = '[MySuppliers]Get My VendorCount Failed';
export const GETMYVENDORCOUNTBYCATEGORY = '[MySuppliers]Get Vendor Count By Category';
export const GETMYVENDORCOUNTBYCATEGORY_SUCCESS = '[MySuppliers]Get Vendor Count By Category Success';
export const GETMYVENDORCOUNTBYCATEGORY_FAILED = '[MySuppliers]Get Vendor Count By Category Failed';
export const SEARCHMYSUPPLIERS = '[MySuppliers]Search My Suppliers';
export const SEARCHMYSUPPLIERS_SUCCESS = '[MySuppliers]Search My Suppliers Success';
export const SEARCHMYSUPPLIERS_FAILED = '[MySuppliers]Search My Suppliers Failed';
export const CHANGEMYSUPPLIERSTATUS = '[MySuppliers]Change Status';
export const CHANGEMYSUPPLIERSTATUS_SUCCESS = '[MySuppliers]Change Status Success';
export const CHANGEMYSUPPLIERSTATUS_FAILED = '[MySuppliers]Change Status Failed';
export const DELETEMYVENDOR = '[MySuppliers]Delete My Supplier';
export const DELETEMYVENDOR_SUCCESS = '[MySuppliers]Delete My Supplier Success';
export const DELETEMYVENDOR_FAILED = '[MySuppliers]Delete My Supplier Failed';
export const SENDNOTETOSUPPLIER='[MySuppliers]Send Note To Suppliers';
export const SENDNOTETOSUPPLIER_SUCCESS='[MySuppliers]Send Note To Suppliers Success';
export const SENDNOTETOSUPPLIER_FAILED='[MySuppliers]Send Note To Suppliers Failed';
export class GetMyVendorCount {
    public readonly type = GETMYVENDORCOUNT;
    constructor() { }
}
export class GetMyVendorCountSuccess {
    public readonly type = GETMYVENDORCOUNT_SUCCESS;
    constructor(public payload: any) { }
}
export class GetMyVendorCountFailed {
    public readonly type = GETMYVENDORCOUNT_FAILED;
    constructor(public payload: any) { }
}
export class GetMyVendorByCategory {
    public readonly type = GETMYVENDORCOUNTBYCATEGORY;
    constructor() { }
}
export class GetMyVendorByCategorySuccess {
    public readonly type = GETMYVENDORCOUNTBYCATEGORY_SUCCESS;
    constructor(public payload: any) { }
}
export class GetMyVendorByCategoryFailed {
    public readonly type = GETMYVENDORCOUNTBYCATEGORY_FAILED;
    constructor(public payload: any) { }
}
export class SearchMySuppliersByCategoryAndStatus {
    public readonly type = SEARCHMYSUPPLIERS;
    constructor(public payload: any) { }
}
export class SearchMySuppliersByCategoryAndStatusSuccess {
    public readonly type = SEARCHMYSUPPLIERS_SUCCESS;
    constructor(public payload: any) { }
}
export class SearchMySuppliersByCategoryAndStatusFailed {
    public readonly type = SEARCHMYSUPPLIERS_FAILED;
    constructor(public payload: any) { }
}
export class ChangeMySupplierStatus {
    public readonly type = CHANGEMYSUPPLIERSTATUS;
    constructor(public payload: any) { }
}
export class ChangeMySupplierStatusSuccess {
    public readonly type = CHANGEMYSUPPLIERSTATUS_SUCCESS;
    constructor(public payload: any) { }
}
export class ChangeMySupplierStatusFailed {
    public readonly type = CHANGEMYSUPPLIERSTATUS_FAILED;
    constructor(public payload: any) { }
}
export class DeleteMyVendor {
    public readonly type = DELETEMYVENDOR;
    constructor(public payload: any) { }
}
export class DeleteMyVendorSuccess {
    public readonly type = DELETEMYVENDOR_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteMyVendorFailed {
    public readonly type = DELETEMYVENDOR_FAILED;
    constructor(public payload: any) { }
}
export class SendNoteToSupplier{
    public readonly type=SENDNOTETOSUPPLIER;
    constructor(public payload:any){

    }
}
export class SendNoteToSupplierSuccess{
    public readonly type=SENDNOTETOSUPPLIER_SUCCESS;
    constructor(public payload:any){
        
    }
}
export class SendNoteToSupplierFailed{
    public readonly type=SENDNOTETOSUPPLIER_FAILED;
    constructor(public payload:any){
        
    }
}

export type MySuppliersActions = GetMyVendorCount | GetMyVendorCountSuccess | GetMyVendorCountFailed |
    GetMyVendorByCategory | GetMyVendorByCategorySuccess |
    GetMyVendorByCategoryFailed | SearchMySuppliersByCategoryAndStatus |
    SearchMySuppliersByCategoryAndStatusSuccess | SearchMySuppliersByCategoryAndStatusFailed |
    ChangeMySupplierStatus | ChangeMySupplierStatusSuccess | ChangeMySupplierStatusFailed |
    DeleteMyVendor | DeleteMyVendorSuccess | DeleteMyVendorFailed|SendNoteToSupplier|SendNoteToSupplierSuccess|
    SendNoteToSupplierFailed;