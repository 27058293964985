import { Action } from '@ngrx/store';


export const GETREVIEWS='[Reviews]Get Reviews';
export const GETREVIEWSUCCESS='[Reviews]Get Reviews Success';
export const GETREVIEWFAILED='[Reviews]Get Reviews Failed';
export class GetReviews  {
     readonly type =GETREVIEWS;
     constructor(public payload:any){}
}
export class GetReviewsSuccess{
    readonly type =GETREVIEWSUCCESS;
    constructor(public payload:any){}
}
export class GetReviewsFailed  {
    readonly type =GETREVIEWFAILED;
    constructor(public payload:any){}
}
export  type ReviewsActions=GetReviews|GetReviewsSuccess|GetReviewsFailed;
