import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { APPSTATE } from '../Store/HomePageState';
import { Store, select } from '@ngrx/store';
import { ForgetPassword } from '../Store/Actions/Login.action';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectForgetPwdMsg, getLoginState } from '../Store/Reducers/Login.reducer';
import { Observable } from 'rxjs';
import { selectHomePageBanners } from '../Store/Reducers/Homepage.reducer';
import { Cs2LibService } from 'projects/cs2-lib/src/lib/cs2-lib.service';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  formForgetPwd:FormGroup;
  isSubmited=false;
  shareImageUrl=''
  msg$:Observable<any>;
  constructor(public dialog:MatDialog,private _store:Store<APPSTATE>,public fb:FormBuilder,
    public cs2:Cs2LibService) {
     this.msg$=this._store.pipe(select(SelectForgetPwdMsg));
     
   }

  ngOnInit() {
    this.cs2.updateMetaTags('/assets/img/PerfectWeddings.MuRootImg.jpg','Forgot Password | Perfect Weddings','Forgot your password. Fill in the required information and we will have you back in action.')
    this.formForgetPwd=this.fb.group({
      email:['',[Validators.required,Validators.email]],
    })
  }
  get f(){
    return this.formForgetPwd.controls;
  }
  SendforgetPwdRequest(){
    this.isSubmited=true;
    if(this.formForgetPwd.valid){
    this._store.dispatch(new ForgetPassword({email:this.formForgetPwd.controls['email'].value}));
    setTimeout(() => {
      this.msg$= this._store.pipe(select(SelectForgetPwdMsg));  
    }, 3000);
    
  }
  }
  
}
