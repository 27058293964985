
import { Action } from '@ngrx/store';

export const GETPRICINGPLAN = '[Vendorsignup] Get Pricing Plan';
export const PRICINGPLANS_SUCCESS = '[Vendorsignup]Get Pricing Success';
export const PRICINGPLSN_FAILED = '[Vendorsignup]Get Pricing Failed';
// export const GETPRICINGPLANOFFER='[Vendorsignup]Get Pricing Plan Offers'
// export const GETPRICINGPLANOFFER_SUCCESS='[Vendorsignup]Get Pricing Plan Offers Success'
// export const GETPRICINGPLANOFFER_FAILED='[Vendorsignup]Get Pricing Plan Offers Failed'
export const SIGNUP = '[Vendorsignup] Vendor Signup';
export const SIGNUP_PAYMENTSUMMARY='[Vendorsignup] Vendor Signup Payment Summary';
export const SIGNUP_SUCCESS = '[Vendorsignup] Vendor Signup Success';
export const SIGNUP_FAILED = '[Vendorsignup] Vendor Signup FAILED';
export const VENDORSIGNUP_VALIDATE_VOUCHER='[Vendorsignup] Vendor Signup Validate Voucher';
export const VENDORSIGNUP_VALIDATE_VOUCHER_SUCCESS='[Vendorsignup] Vendor Signup Validate Voucher Success';
export const VENDORSIGNUP_VALIDATE_VOUCHER_FAILED='[Vendorsignup] Vendor Signup Validate Voucher Failed';
export const STEP1='[Vendorsignup] Step1';
export const STEP2='[Vendorsignup] Step2';
export const STEP3='[Vendorsignup] Step3';
export const STEP4='[Vendorsignup] Step4';
export const VENDORSIGNUP_INITPAYMENT='[Vendorsignup] Vendor Signup Init Payment';
export const VENDORSIGNUP_INITPAYMENT_SUCCESS='[Vendorsignup] Vendor Signup Init Payment Success';
export const VENDORSIGNUP_INITPAYMENT_FAILED='[Vendorsignup] Vendor Signup Init Payment Failed';
export const CHECKEMAIL_EXIST='[Vendorsignup] Check Email Exist';
export const CHECKEMAIL_EXIST_SUCCESS='[Vendorsignup] Check Email Exist Success';
export const CHECKEMAIL_EXIST_FAILED='[Vendorsignup] Check Email Exist Failed';
export const VALIDATEMEMBERSHIPVOUCHER='[Vendorsignup] Validate Membership Voucher';
export const VALIDATEMEMBERSHIPVOUCHER_SUCCESS='[Vendorsignup] Validate Membership Voucher Success';
export const VALIDATEMEMBERSHIPVOUCHER_FAILED='[Vendorsignup] Validate Membership Voucher Failed';

export class GetPricingPlan implements Action {
  public type = GETPRICINGPLAN;
  constructor(public payload: any) {
  }
}
export class GetPricingPlanSuccess implements Action {
  public type = PRICINGPLANS_SUCCESS;
  constructor(public payload: any) {
  }
}
export class GetPricingPlanFailed implements Action {
  public type = PRICINGPLSN_FAILED;
  constructor(public payload: any) {}
}
export class VendorSignup{
  public readonly type=SIGNUP;
  constructor(public payload:any){}
}
export class VendorSignupSuccess{
  public readonly type=SIGNUP_SUCCESS;
  constructor(public payload:any){}
}
export class VendorSignupFailed{
  public readonly type=SIGNUP_FAILED;
  constructor(public payload:any){}
}
export class VendorSignupPaymentSummary{
  public readonly type=SIGNUP_PAYMENTSUMMARY;
  constructor(public payload:any){
  }
}
export class VendorSignupValidateVoucher{
  public readonly type=VENDORSIGNUP_VALIDATE_VOUCHER;
  constructor(public payload:any){
   
  }
}
export class VendorSignupValidateVoucherSuccess{
  public readonly type=VENDORSIGNUP_VALIDATE_VOUCHER_SUCCESS;
  constructor(public payload:any){
  }
}
export class VendorSignupValidateVoucherFailed{
  public readonly type=VENDORSIGNUP_VALIDATE_VOUCHER_FAILED;
  constructor(public payload:any){
  }
}
export class Step1{
  public readonly type=STEP1;
  constructor(public payload:any){}
}
export class Step2{
  public readonly type=STEP2;
  constructor(public payload:any){}
}
export class Step3{
  public readonly type=STEP3;
  constructor(public payload:any){}
}
export class Step4{
  public readonly type=STEP4;
  constructor(public payload:any){}
}
export class VendorSignupInitPayment{
  public readonly type=VENDORSIGNUP_INITPAYMENT;
  constructor(public payload:any){}
}
export class VendorSignupInitPaymentSuccess{
  public readonly type=VENDORSIGNUP_INITPAYMENT_SUCCESS;
  constructor(public payload:any){}
}
export class VendorSignupInitPaymentSuccessFailed{
  public readonly type=VENDORSIGNUP_INITPAYMENT_FAILED;
  constructor(public payload:any){}
}
export class VendorSignupCheckEmailExist{
  public readonly type=CHECKEMAIL_EXIST;
  constructor(public payload:string){}
}
export class VendorSignupCheckEmailExist_Success{
  public readonly type=CHECKEMAIL_EXIST_SUCCESS;
  constructor(public payload:any){
    console.log(payload);
  }
}
export class VendorSignupCheckEmailExist_Failed{
  public readonly type=CHECKEMAIL_EXIST_FAILED;
  constructor(public payload:any){}
}
export class ValidateMembershipVoucher{
  public readonly type=VALIDATEMEMBERSHIPVOUCHER;
  constructor(public payload:any){}
}
export class ValidateMembershipVoucherSuccess{
  public readonly type=VALIDATEMEMBERSHIPVOUCHER_SUCCESS;
  constructor(public payload:any){}
}
export class ValidateMembershipVoucherFailed{
  public readonly type=VALIDATEMEMBERSHIPVOUCHER_FAILED;
  constructor(public payload:any){}
}
// export class GetPricinglanOffers{
//   public readonly type=GETPRICINGPLAN;
//   constructor(public payload:any){
//   }
// }
// export class GetPricinglanOffersSuccess{
//   public readonly type=PRICINGPLANS_SUCCESS;
//   constructor(public payload:any){
//   }
// }
// export class GetPricinglanOffersFailed{
//   public readonly type=PRICINGPLSN_FAILED;
//   constructor(public payload:any){
//   }
// }

export type VendorSignupActions = GetPricingPlan|GetPricingPlanSuccess|GetPricingPlanFailed|VendorSignupPaymentSummary|
                                  VendorSignupValidateVoucher|VendorSignupValidateVoucherSuccess|VendorSignupValidateVoucherFailed|
                                  Step1|Step3|Step3|Step4|VendorSignupInitPayment|VendorSignupInitPaymentSuccess|VendorSignupInitPaymentSuccessFailed|
                                  VendorSignupCheckEmailExist|VendorSignupCheckEmailExist_Success|VendorSignupCheckEmailExist_Failed|
                                  ValidateMembershipVoucher|ValidateMembershipVoucherSuccess|ValidateMembershipVoucherFailed
                                  //GetPricinglanOffers|GetPricinglanOffersSuccess|GetPricinglanOffersFailed
                                  ;
