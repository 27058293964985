
export const GETMYMESSAGES='[MESSAGES] Get My Messages';
export const GETMYMESSAGES_SUCCESS='[MESSAGES] Get My Messages Success';
export const GETMYMESSAGES_FAILED='[MESSAGES] Get My Messages Failed';
export const MARKASREAD='[MESSAGES] Mark As Read';
export const MARKASREAD_SUCCESS='[MESSAGES] Mark As Read Success';
export const GETMSGDETAILS='[MESSAGES] Get Message Details';
export const DELETEMYMESSAGE='[MESSAGES] Delete My Message';
export const MARKASSTARED='[MESSAGES] Mark As Stared';
export const POSTMESSAGE='[MESSAGES] Post Message';
export const MESSAGEHISTORY='[MESSAGES]Message History'
export const MESSAGEHISTORY_SUCCESS='[MESSAGES]Message History Success'
export const MESSAGEHISTORY_FAILED='[MESSAGES]Message History Failed'
export class GetMyMessages{
    public readonly type=GETMYMESSAGES
    constructor(public payload:any){}
}
export class GetMyMessagesSuccess{
    public readonly type=GETMYMESSAGES_SUCCESS
    constructor(public payload:any){
    }
}
export class GetMyMessagesFailed{
    public readonly type=GETMYMESSAGES_FAILED
    constructor(public payload:any){}
}
export class ReadMessage{
    public readonly type=MARKASREAD;
    constructor(public payload:any){}
}
export class ReadMessageSuccess{
    public readonly type=MARKASREAD_SUCCESS;
    constructor(public payload:any){}
}
export class GetMessageDetails{
    public readonly type=GETMSGDETAILS;
    constructor(public payload:any){}
}
export class DeleteMyMessage{
    public readonly type=DELETEMYMESSAGE;
    constructor(public payload:any){}
}
export class MarkAsStared{
    public readonly type=MARKASSTARED;
    constructor(public payload:any){
        
    }
}
export class PostMessage{
    public readonly type=POSTMESSAGE;
    constructor(public payload:any){}
}
export class GetMessageHistory{
    public readonly type=MESSAGEHISTORY;
    constructor(public messageId:string){}
}
export class GetMessageHistorySuccess{
    public readonly type=MESSAGEHISTORY_SUCCESS;
    constructor(public payload:any){}
}
export class GetMessageHistoryFailed{
    public readonly type=MESSAGEHISTORY_FAILED;
    constructor(public payload:any){}
}


export type MessagesActions=GetMyMessages|GetMyMessagesSuccess|GetMyMessagesFailed|ReadMessage|
                            ReadMessageSuccess|GetMessageDetails|DeleteMyMessage|MarkAsStared|PostMessage|
                            GetMessageHistory|GetMessageHistorySuccess|GetMessageHistoryFailed;