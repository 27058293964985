import { createSelector } from '@ngrx/store';
import * as GalleryAction from '../../Store/Actions/Gallery.action';
import { APPSTATE } from '../HomePageState';
export const GalleryInitialState={
    videos:null,
    reviews:null,
    albums:null,
    albumimages:null,
    portfolioImages:null,
    deal:null,
    loading:false,
   
}
export function GalleryReducer(state=GalleryInitialState,action:GalleryAction.GalleryActions){
    switch(action.type){
       
        case GalleryAction.GETVIDEOS_BYVENDOR:
            return{...state,loading:true}
        case GalleryAction.GETVIDEOS_BYVENDOR_SUCCESS:
            return{...state,loading:false,videos:action.payload}
        case GalleryAction.GETVIDEOS_BYVENDOR_FAILED:
            return{...state,loading:false}
        case GalleryAction.GETALBUMS_BYVENDOR:
            return{...state,loading:true}
        case GalleryAction.GETALBUMS_BYVENDOR_SUCCESS:
            return{...state,loading:false,albums:action.payload}
        case GalleryAction.GETALBUMS_BYVENDOR_FAILED:
            return{...state,loading:false}
        case GalleryAction.GETALBUMSIMAGES_BYALBUM:
            return{...state,loading:true}
        case GalleryAction.GETALBUMSIMAGES_BYALBUM_SUCCESS:
            return{...state,albumimages:action.payload,loading:false}
        case GalleryAction.GETALBUMSIMAGES_BYALBUM_FAILED:
            return{...state,loading:false}
        case GalleryAction.GETDEALS_BYVENDOR:
            return{...state,loading:true}
        case GalleryAction.GETDEALS_BYVENDOR_SUCCESS:
            return{...state,deals:action.payload,loading:false}
        case GalleryAction.GETDEALS_BYVENDOR_FAILED:
            return{...state,loading:false}
        case GalleryAction.VIDEOVIEWLOG:
                return{...state}
        case GalleryAction.VIDEOVIEWLOG_SUCCESS:
            return{...state}
            case GalleryAction.VIDEOVIEWLOG_FAILED:
            return{...state}
        case GalleryAction.GETPORTFOLIOIMAGES:
            return{...state,loading:true}
        case GalleryAction.GETPORTFOLIOIMAGES_SUCCESS:
            return{...state,portfolioImages:action.payload,loading:false}
        case GalleryAction.GETPORTFOLIOIMAGES_FAILED:
            return{...state,loading:false}
        default:
            return state;
        
    }
}
export const galleryReducer= (state: APPSTATE) => state;
export const SelectVideoByVendor=createSelector(galleryReducer,((state:any)=>state.galleryreducer?state.galleryreducer.videos:null));
export const SelectAlbumsByVendor=createSelector(galleryReducer,((state:any)=>state.galleryreducer?state.galleryreducer.albums:null));
export const SelectAlbumImagesByVendor=createSelector(galleryReducer,((state:any)=>state.galleryreducer?state.galleryreducer.albumimages:null));
export const SelectDealsByVendor=createSelector(galleryReducer,((state:any)=>state.galleryreducer?state.galleryreducer.deals:null));
export const SelectAlbumsByVendorLoader=createSelector(galleryReducer,((state:any)=>state.galleryreducer?state.galleryreducer.loading:null));
export const SelectPortfolioImages=createSelector(galleryReducer,((state:any)=>state.galleryreducer?state.galleryreducer.portfolioImages:null));
