import * as fromMyreviews from '../../Actions/Couple/MyReviews.action';
import { APPSTATE } from '../../HomePageState';
import {createSelector } from '@ngrx/store';
export const MyReviewsInitialState={
    loading:false,
    reviews:[],
    createupdatereviewcomplete:null
}
export function MyReviewsReducer(state=MyReviewsInitialState,action:fromMyreviews.MyReviewsActions){
    switch(action.type){
        case fromMyreviews.GETMYREVIEWS:
            return{...state, loading:true}
        case fromMyreviews.GETMYREVIEWS_SUCCESS:
            return{...state, loading:false,reviews:action.payload}
        case fromMyreviews.GETMYREVIEWS_FAILED:
            return{...state, loading:false,}
        case fromMyreviews.CREATEUPDATEREVIEWS:
            return{...state, loading:true,createupdatereviewcomplete:null}
        case fromMyreviews.CREATEUPDATEREVIEWS_SUCCESS:
            return{...state, loading:false,createupdatereviewcomplete:action.payload}
        case fromMyreviews.CREATEUPDATEREVIEWS_FAILED:
            return{...state,loading:false,createupdatereviewcomplete:action.payload}
        default:
            return state;
        }
}
export const GetMyreviewsReducer=(state:APPSTATE)=>state;
export const SelectMyReviews=createSelector(GetMyreviewsReducer,(state:any)=>state.myreviewsreducer?state.myreviewsreducer.reviews:null);
export const SelectUpdateReviewComplete=createSelector(GetMyreviewsReducer,(state:any)=>state.myreviewsreducer?state.myreviewsreducer.createupdatereviewcomplete:null);
export const SelectReviewLoader=createSelector(GetMyreviewsReducer,(state:any)=>state.myreviewsreducer?state.myreviewsreducer.loading:null);
