import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Date } from 'core-js';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  token: string;
  userId: string;
  constructor(private router: Router, private http: HttpClient) {
  //  this.token = localStorage.getItem('userToken');
  }
  canActivate() {
  //  this.token = localStorage.getItem('userToken');
    if (this.token == null || this.token === undefined) {
      this.router.navigateByUrl('/home');
    } else {
      return true;
    }
  }
  canActivateChild() {
    if (this.token == null || this.token === undefined) {
      this.router.navigateByUrl('/home');
    } else {
      return true;
    }
  }
  userActive() {
    if (localStorage.getItem('_u') == null || localStorage.getItem('_u') === undefined) {
      return false;
    } else {
      return true;
    }
  }
  vendorActive() {
    if (localStorage.getItem('_v') == null || localStorage.getItem('_v') === undefined) {
      return false;
    } else {
      return true;
    }
  }
  getVendorProfile() {
    this.userId = localStorage.getItem('userId');
    if (this.userId) {
      this.http
        .get(
          'http://testapp-env.tyad3n63sa.ap-south-1.elasticbeanstalk.com/PerfectWedding/vendordetails' +
            '?id=' +
            this.userId +
            '&' +
            'loggedInUserId=' +
            this.userId,
        )
        .subscribe((res: any) => {
          
        });
    }
  }
}
