import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import {
  Cs2LibService,
  Cs2SocialService,
  Cs2SocketService,
  ISocketMessage,
} from "projects/cs2-lib/src/public-api";
import { MatDialogRef, MatSnackBar, MatDialog } from "@angular/material";
import { Observable, Subscription } from "rxjs";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import {
  FacebookLogin,
  Login,
} from "projects/home/home/Store/Actions/Login.action";
import { Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { APPSTATE } from "projects/home/home/Store/HomePageState";
import * as urls from "../../../../home/Store/Services/ServiceUrls";
import { SelectUserDetails } from "../../../../home/Store/Reducers/Login.reducer";
import { ForgetPasswordComponent } from "projects/home/home/forget-password/forget-password.component";
import { GetMyMessages } from "projects/home/home/Store/Actions/Couple/MyMessages.action";
import { SelectMyMessages } from "projects/home/home/Store/Reducers/Couple/MyMessages.reducer";
import { MyWeddingPersonalDetails } from "projects/home/home/Store/Actions/Couple/MyWeddingInsight.action";
import { Meta } from "@angular/platform-browser";
import { faRulerHorizontal } from "@fortawesome/free-solid-svg-icons";

declare var FB: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  login$: Observable<any>;
  userName: string;
  password: string;
  vendorLoginName: string;
  VenorPassword: string;
  Formlogin: FormGroup;
  FormVendorLogin: FormGroup;
  issubmited = false;
  currentForm = null;
  messages$: Observable<any>;
  objsubscription: Subscription;
  @ViewChild("gl", { static: true }) gl: ElementRef<HTMLElement>;
  constructor(
    public cs2: Cs2LibService,
    public cs2social: Cs2SocialService,
    public dialogref: MatDialogRef<LoginComponent>,
    public store: Store<APPSTATE>,
    private fb: FormBuilder,
    private _snacksbar: MatSnackBar,
    public route: Router,
    public dialog: MatDialog,
    public metaservice: Meta,
    public ngzone: NgZone,
    public renderer2: Renderer2
  ) {
    this.createLoginForm();
    this.createVendorLoginForm();
    this.metaservice.updateTag({
      name: "google-signin-client_id",
      content:
        "370750273516-6vokbu8631b7aedbiek28c5chl926ucq.apps.googleusercontent.com",
    });
  }
  ngOnDestroy(): void {
    this.objsubscription?.unsubscribe();
  }

  ngOnInit() {
    var script = this.renderer2.createElement("script");
    script.type = "text/JavaScript";
    script.src = "https://apis.google.com/js/platform.js";
    this.gl.nativeElement.appendChild(script);
    var btns = document.getElementById("connected4yqumtpybfvu");
  }
  onSignIn(googleUser) {
    googleUser.getBasicProfile();
  }
  createLoginForm() {
    this.Formlogin = this.fb.group({
      userName: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
    });
  }
  createVendorLoginForm() {
    this.FormVendorLogin = this.fb.group({
      userName: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
    });
  }
  afterGoogleSignUp(user) {}
  goto(url) {
    this.dialogref.close();
    this.cs2.goto(url);
  }
  loginUser(frm) {
    this.issubmited = true;
    this.currentForm = frm;
    if (frm == "u" && this.Formlogin.valid) {
      this.store.dispatch(
        new Login({
          userName: this.userName,
          password: this.password,
          UserRole: "Users",
        })
      );
      this.dialogref.close();
    } else if (frm == "v" && this.FormVendorLogin.valid) {
      this.store.dispatch(
        new Login({
          userName: this.vendorLoginName,
          password: this.VenorPassword,
        })
      );
    }
  }
  get fv() {
    return this.FormVendorLogin.controls;
  }
  get f() {
    return this.Formlogin.controls;
  }
  gotosignup(url) {
    this.route.navigateByUrl(url);
  }
  subscribeToLogin() {
    this.objsubscription = this.store
      .pipe(select(SelectUserDetails))
      .subscribe((res) => {
        if (res && res.role === "Users") {
          localStorage.setItem("authtoken", res.auth_token);
          this.dialogref.close();
        } else if (res && res.role === "Vendors") {
          this.dialogref.close();
          window.location.href =
            urls.UrlModel.Vendor.StaggingDashboard + "?id=" + res.auth_token;
        } else if (res && res.role === "SuperAdmin") {
          window.location.href = urls.UrlModel.admin.adminHome + res.auth_token;
        } else if (res != null) {
          if (
            this.currentForm == "v" ||
            (this.currentForm == "u" && res.error)
          ) {
            if (res.error.login_failure) {
              this._snacksbar
                .open(res.error.login_failure[0], "Ok", {
                  panelClass: "dangerBadge",
                  verticalPosition: "bottom",
                })
                ._dismissAfter(2000);
            }
          }
        }
      });
  }
  forgetPwd() {
    const dialogref = this.dialog.open(ForgetPasswordComponent);
  }
  loginWithFb() {
    FB.login((response) => {
      this.store.dispatch(
        new FacebookLogin({ AccessToken: response.authResponse.accessToken })
      );
    });
  }
}
