import { LoadCountries } from "./../../home/Store/Actions/HomepageMenu.Action";
import { SelectMainMenu_Home } from "./../../home/Store/Reducers/HomeMenu.reducer";
import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Inject,
  NgZone,
  OnDestroy,
} from "@angular/core";
// import { APPSTATE } from './Store/HomePageState';
import { APPSTATE } from "../../home/Store/HomePageState";
import { Store, select } from "@ngrx/store";
import * as hm from "../../home/Store/Actions/HomepageMenu.Action";
import { Observable, Subscription } from "rxjs";
import * as catAction from "../../home/Store/Actions/Category.action";
import { Router, ActivatedRoute } from "@angular/router";
import { APIService } from "../../home/Store/Services/api.service";
import { MatDialog, MatDrawer } from "@angular/material";
import { LoginComponent } from "./common/login/login.component";
import {
  ChatService,
  Cs2LibService,
  Cs2SocialService,
} from "projects/cs2-lib/src/public-api";
import { Logout } from "projects/home/home/Store/Actions/Login.action";
import { GetMyMessages } from "projects/home/home/Store/Actions/Couple/MyMessages.action";
import {
  SelectMyMessages,
  SelectmyMsgUnreadCount,
} from "projects/home/home/Store/Reducers/Couple/MyMessages.reducer";
import { Cs2MessageService } from "projects/cs2-lib/src/lib/cs2-message.service";
import {
  SetMetaTags,
  SearchListing,
  SetListingPageHeading,
  SetSearchListingPrams,
} from "projects/home/home/Store/Actions/Listing.action";
import * as io from "socket.io-client";
import { SelectCategories } from "projects/home/home/Store/Reducers/Category.reducer";
import { SelectUserDetails } from "projects/home/home/Store/Reducers/Login.reducer";
import { SearchListingVM } from "projects/home/home/Store/Models/SearchlistingVm";
import { SelectMyWedding_PersonalInfo } from "projects/home/home/Store/Reducers/Couple/MyWeddingInsight.reducer";
import { ChatboxComponent } from "projects/cs2-lib/src/lib/chatbox/chatbox.component";
import { SwPush, SwUpdate } from "@angular/service-worker";
import { Meta } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { json } from "express";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "home";
  showSubmenu = false;
  homepagemenu$: Observable<any>;
  categories$: Observable<any>;
  submenu$: Observable<any>;
  userdetails$: Observable<any>;
  unreadmessages$: Observable<any>;
  ObjsearchlistingVm: SearchListingVM;
  currentUser = null;
  addBtn: HTMLElement;
  @ViewChild("sidenav", { static: false }) sidenav: MatDrawer;
  @ViewChild("sidenavSubmenu", { static: false }) sidenavSubmenu: MatDrawer;
  @ViewChild("userNav", { static: false }) userNav: MatDrawer;
  subscribe: Subscription;
  deferredPrompt: any;
  showchatbutton = true;

  CurYear: any;
  constructor(
    public store: Store<APPSTATE>,
    public router: Router,
    public apiservice: APIService,
    public swpush: SwPush,
    public dialog: MatDialog,
    public cs2: Cs2LibService,
    public cs2social: Cs2SocialService,
    public activeroute: ActivatedRoute,
    public swupdate: SwUpdate,
    public metaservice: Meta,
    @Inject(DOCUMENT) private doc: Document,
    public ngZone: NgZone
  ) {
    this.CurYear = new Date().getFullYear();
    this.swupdate.available.subscribe((res) => {
      this.UpdateApp();
    });
    this.store.dispatch(new catAction.LoadCategory());
    this.categories$ = this.store.pipe(select(SelectCategories));
    this.homepagemenu$ = this.store.pipe(select(SelectMainMenu_Home));
    this.store.dispatch(new hm.LoadSubmenu([]));
    this.submenu$ = this.store.select("submenureducer");
    this.userdetails$ = this.store.pipe(select(SelectUserDetails));
    this.unreadmessages$ = this.store.pipe(select(SelectmyMsgUnreadCount));
    this.ObjsearchlistingVm = new SearchListingVM();
    this.ObjsearchlistingVm.servicesId = 0;
    this.cs2.detectBreakpoint();
  }
  ngOnInit(): void {
    //  this.cs2social.fbLoginInit(600480377540048);
    this.subscribe = this.userdetails$.subscribe((res) => {
      if (res !== null && res.role && res.role == "Users") {
        this.currentUser = res;
        localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
        localStorage.setItem(
          "authtoken",
          JSON.stringify(this.currentUser.auth_token)
        );
      } else if (localStorage.getItem("currentUser") != null) {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      }
    });
    if (localStorage.getItem("currentUser") != null) {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    }
    this.metaservice.addTags([
      {
        name: "google-signIn-client_Id",
        content:
          "370750273516-6vokbu8631b7aedbiek28c5chl926ucq.apps.googleusercontent.com",
      },
    ]);
  }

  ngOnDestroy() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }
  UpdateApp() {
    var confirm = window.confirm("Update Latest App ?");
    if (confirm === true) {
      this.swupdate.activateUpdate().then(() => {
        document.location.reload();
      });
    }
  }
  subscribetopush() {
    this.swpush
      .requestSubscription({
        serverPublicKey:
          "BGX4-HuKZPs3mEuPNlKo_ptDCS8GYVBB1bS_kFHIM7cZHAdtIX8HZ5q40ZKZGaVpI9ZDNAm-EmrMAIlQDL3U78U",
      })
      .then((res) => {
        console.log(res);
      });
  }
  mouseEnter(menuIndex) {
    menuIndex === 0 ? (this.showSubmenu = true) : false;
    menuIndex !== 0 && this.showSubmenu === true
      ? (this.showSubmenu = false)
      : true;
  }
  mouseLeave() {
    this.showSubmenu ? (this.showSubmenu = false) : true;
  }
  gotohome() {
    this.router.navigateByUrl("/home");
  }
  gotoPage(url) {
    this.toggleMainMenu();
    this.cs2.goto(url);
  }

  gotoListing(cat) {
    this.ObjsearchlistingVm.categoryId = 0;
    this.ObjsearchlistingVm.districts = 0;
    this.showSubmenu = false;

    if (cat.categoryId == 8) {
      this.toggleSubmenu();
      this.gotoHonymoonPage();
    } else {
      this.ObjsearchlistingVm.categoryId = cat.categoryId;
      this.store.dispatch(
        new SetListingPageHeading({
          categoryName:
            cat.categoryName == "AllCategories" ? "Vendors" : cat.categoryName,
          location: "Mauritius",
        })
      );
      this.router.navigate([
        "/home/weddingvendors/",
        cat.categoryName,
        "Mauritius",
        "0",
        "0",
        "All",
        "0",
      ]);
      this.toggleSubmenu();
    }
  }

  OpenLoginDialog() {
    this.sidenav.close();
    let dialog = this.dialog.open(LoginComponent);
    dialog.afterClosed().subscribe((res) => {});
  }
  toggleMainMenu() {
    this.sidenav.toggle();
    this.sidenavSubmenu.close();
    this.userNav.close();
  }

  toggleSubmenu() {
    this.sidenav.close();
    this.sidenavSubmenu.toggle();
  }
  userNavToggle(url) {
    this.dialog.closeAll();
    if (localStorage.getItem("authtoken")) {
      if (url !== "null") this.cs2.goto(url);
      this.sidenav.close();
      this.sidenavSubmenu.close();
      this.userNav.toggle();
    } else {
      this.cs2.goto("home/Login");
    }
  }
  logoutUser() {
    this.store.dispatch(new Logout({}));
    this.currentUser = null;
    this.userNav.toggle();
    this.cs2.goto("/home");
  }
  gotomessenger() {
    this.cs2.goto("/home/messenger");
  }
  showChatButton() {
    if (window.location.href.includes("messenger")) {
      this.showchatbutton = false;
    }
  }
  gotoHonymoonPage() {
    this.cs2.goto("/home/honeymoondestinations");
  }
}
