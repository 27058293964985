import { Action } from '@ngrx/store';
import { SearchListingVM } from '../Models/SearchlistingVm';
export const LOADLISTING = '[SearchListing] Search';
export const LISTINGFAILED = '[SearchListing] Search Failed';
export const LISTINGSUCCESS = '[SearchListing] Search Success';
export const LISTINGDETAILS = '[SearchListing] Listing Details';
export const LISTINGDETAILSUCCESS = '[SearchListing] Listing Details Success';
export const LOADSERVICES = '[SearchListing] Load Services';
export const SETMETATAGS = '[SearchListing] Set Meta Tags';
export const LISTINGHEADING = '[SearchListing] Set Listing Heading';
export const GETPRICES = '[SearchListing]Get Filter Prices';
export const GETPRICES_SUCCESS = '[SearchListing]Get Filter Prices Success';
export const GETPRICES_FAILED = '[SearchListing]Get Filter Prices Failed';
export const GETSIMILAR_VENDORS = '[SearchListing]Get Similar Vendors';
export const GETSIMILAR_VENDORS_SUCCESS = '[SearchListing]Get Similar Vendors Success';
export const GETSIMILAR_VENDORS_FAILED = '[SearchListing]Get Similar Vendors Failed';
export const CLEARSEARCHPARAMS='[SearchListing]Clear Search Params';
export const SETSEARCHLISTINGPARAMS='[SearchListing]Clear Search Params]';
export class SearchListing implements Action {
  public type = LOADLISTING;
  constructor(public payload: SearchListingVM) {
  }
}
export class SearchListingSuccess implements Action {
  public type = LISTINGSUCCESS;
  constructor(public payload: any) {
  }
}
export class SearchListingFailed implements Action {
  public type = LISTINGFAILED;
  constructor(public payload: any) {
  }
}
export class ListingDetails {
  public type = LISTINGDETAILS;
  constructor(public payload: any) { }
}
export class ListingDetailSuccess {
  public type = LISTINGDETAILSUCCESS;
  constructor(public payload: any) { }
}
export class LoadServices {
  public type = LOADSERVICES;
  constructor(public payload: any) { }
}
export class SetMetaTags {
  public readonly type = SETMETATAGS;
  constructor(public payload: any) {
  }
}
export class SetListingPageHeading {
  public readonly type = LISTINGHEADING;
  constructor(public payload: any) { }
}
export class GetFilterPrices {
  public readonly type = GETPRICES;
  constructor() { }
}
export class GetFilterPricesSuccess {
  public readonly type = GETPRICES_SUCCESS;
  constructor(public payload: any) { }
}
export class GetFilterPricesFailed {
  public readonly type = GETPRICES_FAILED;
  constructor(public payload: any) { }
}
export class GetSimilarVendors {
  public readonly type = GETSIMILAR_VENDORS;
  constructor(public payload: any) { }
}
export class GetSimilarVendorsSucccess {
  public readonly type = GETSIMILAR_VENDORS_SUCCESS;
  constructor(public payload: any) { }
}
export class GetSimilarVendorsFailed {
  public readonly type = GETSIMILAR_VENDORS_FAILED;
  constructor(public payload: any) { }
}
export class ClearSearchParams{
  public readonly type=CLEARSEARCHPARAMS;
  constructor(public payload:any){}
}
export class SetSearchListingPrams{
  public readonly type=SETSEARCHLISTINGPARAMS;
  constructor(public payload:any){}
}

export type SearchActions = SearchListing | SearchListingSuccess | SearchListingFailed | ListingDetails | ListingDetailSuccess
  | LoadServices | SetMetaTags | SetListingPageHeading | GetFilterPrices | GetFilterPricesSuccess |SetSearchListingPrams|
  GetFilterPricesFailed | GetSimilarVendors | GetSimilarVendorsSucccess | GetSimilarVendorsFailed|ClearSearchParams;
