import * as vendorsignupActions from '../Actions/Vendorsignup.actin';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
export const InitialVendorSignupActions = {
  loading: false,
  pricingPlans: [],
  msg: {},
  paymentSummary: {},
  step1: {},
  step2: {},
  step3: {},
  step4: {},
  paymentRedirectData:{},
  isEmailExist:{},
  voucherResponse:[],
 

};
export function VendorSignupReducer(state = InitialVendorSignupActions, action: vendorsignupActions.VendorSignupActions) {
  switch (action.type) {
    case vendorsignupActions.GETPRICINGPLAN:
      return { ...state, loading: true};
    case vendorsignupActions.PRICINGPLANS_SUCCESS:
    console.log(action.payload);  
    return { ...state, loading: false, pricingPlans: action.payload};
    case vendorsignupActions.PRICINGPLSN_FAILED:
      return state;
    case vendorsignupActions.SIGNUP:
      return { ...state, loading: true}
    case vendorsignupActions.SIGNUP_PAYMENTSUMMARY:
        
      return { ...state, loading: false,paymentSummary: action.payload}
    case vendorsignupActions.SIGNUP_SUCCESS:
      return { ...state, loading: false,msg: action.payload}
    case vendorsignupActions.SIGNUP_FAILED:
      return { ...state, loading: false,msg: action.payload}
    case vendorsignupActions.STEP1:
      return {...state, loading: false,step1: action.payload}
    case vendorsignupActions.STEP2:
      return {
        ...state, loading: false, step2: action.payload}
    case vendorsignupActions.STEP3:
      return {
        ...state, loading: false,step3: action.payload}
    case vendorsignupActions.STEP4:
      return {
        ...state, loading: false,step4: action.payload}
    case vendorsignupActions.VENDORSIGNUP_INITPAYMENT:
      return {...state, loading: false,step4: action.payload}
    case vendorsignupActions.VENDORSIGNUP_INITPAYMENT_SUCCESS:
      return {
        ...state, loading: false, step4: action.payload,paymentRedirectData:action.payload}
    case vendorsignupActions.VENDORSIGNUP_INITPAYMENT_FAILED:
      return {
        ...state, loading: false,step4: action.payload,paymentRedirectData:action.payload}
     case vendorsignupActions.CHECKEMAIL_EXIST:
      return {...state, loading: false}
    case vendorsignupActions.CHECKEMAIL_EXIST_SUCCESS:
    return {...state, loading: false, isEmailExist:action.payload}
    case vendorsignupActions.CHECKEMAIL_EXIST_FAILED:
      return {...state, loading: false}
    case vendorsignupActions.VALIDATEMEMBERSHIPVOUCHER:
      return{...state,voucherResponse:[]}
    case vendorsignupActions.VALIDATEMEMBERSHIPVOUCHER_SUCCESS:
        console.log(action.payload)
      return{...state,voucherResponse:action.payload}
      case vendorsignupActions.VALIDATEMEMBERSHIPVOUCHER_FAILED:
        return{...state,voucherResponse:action.payload}
    default:
      return state;

  }
}
export const GetVendorState = (state: APPSTATE) => state;
export const SelectVendorState = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.pricingPlans : null));
export const SelectVendorSignupProgress = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.loading : null));
export const SelectVendorSignupMsg = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.msg : null));
export const SelectSignupPaymentSummary = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.paymentSummary : null));
export const SelectStep1 = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.step1 : null));
export const SelectStep2 = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.step2 : null));
export const SelectStep3 = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.step3 : null));
export const SelectStep4 = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.step4 : null));
export const SelectpaymentRedirectData = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.paymentRedirectData : null));
export const SelectisEmailExist = createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.isEmailExist : null));
export const SelectValidateVoucherResponse= createSelector(GetVendorState, ((state: any) => state.vendorsignupreducer ? state.vendorsignupreducer.voucherResponse : null));