import * as FromvacenciesAction from '../Actions/Vacencies.action';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of, pipe } from 'rxjs';
import * as urls from '../../../home/Store/Services/ServiceUrls';
import { catchError, mergeMap, map } from 'rxjs/Operators';
@Injectable()
export class VacenciesEffect{
    constructor(public cs2:Cs2LibService,public action:Actions){
    }
    @Effect()
    getVacenciesEfffect$:Observable<any>=this.action.pipe(ofType(FromvacenciesAction.GETVACENCIES),
    mergeMap((action:any)=>this.cs2.getData(urls.UrlModel.Application.Vacencies).
    pipe(map(res=>new FromvacenciesAction.GetVacenciesSuccess(res),
    catchError(err=>of(new FromvacenciesAction.GetVacenciesFailed(err)))
    ))))
}