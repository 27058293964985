import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import * as urls from '../../../Store/Services/ServiceUrls';
import * as frombudgetActions from '../../Actions/Couple/MyBudget.action';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/Operators';
@Injectable()
export class MyBudgetEffect {
    constructor(public cs2: Cs2LibService, public action: Actions) {

    }
    @Effect()
    getMyExpenseTotal$: Observable<any> = this.action.pipe(ofType(frombudgetActions.GETMYEXPENSETOTAL),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.MyBudget.myexpensestotal)
            .pipe(map(res => new frombudgetActions.GetMyExpenseTotalSuccess(res),
                catchError(err => of(new frombudgetActions.BudgetItemFailed(err)))
            ))));
    @Effect()
    getMyExpenseByCategoryCount$: Observable<any> = this.action.pipe(ofType(frombudgetActions.GETMYEXPENSEBYCATEGORYCOUNT),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.MyBudget.myexpenseByCategoryCount + action.payload)
            .pipe(map(res => new frombudgetActions.ExpenseByCategoryCountSuccess(res),
                catchError(err => of(new frombudgetActions.BudgetItemFailed(err)))
            ))));

    @Effect()
    getMyBudgetItem$: Observable<any> = this.action.pipe(ofType(frombudgetActions.GETMYBUDGETITEM),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MyBudget.MyBudgetItems + action.payload.budgetCategoryId, null)
            .pipe(map(res => new frombudgetActions.GetMyBudgetItemSuccess(res),
                catchError(err => of(new frombudgetActions.BudgetItemFailed(err)))
            ))));
    @Effect()
    getMyBudgetItemByCategirt$: Observable<any> = this.action.pipe(ofType(frombudgetActions.GETMYBUDGETITEMBYCATEGORY),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MyBudget.mybudgetitemsbycategory, action.payload)
            .pipe(map(res => new frombudgetActions.GetMyBudgetItemByCatgorySuccess(res),
                catchError(err => of(new frombudgetActions.BudgetItemFailed(err)))
            ))));
    @Effect()
    createUpdateBudgetItem$: Observable<any> = this.action.pipe(ofType(frombudgetActions.CREATEUPDATEBUDGETITEM),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MyBudget.CreateUpdateBudgetItem, action.payload)
            .pipe(map(res => new frombudgetActions.GetMyBudgetItemByCatgorySuccess(res),
                catchError(err => of(new frombudgetActions.BudgetItemFailed(err)))
            ))));
    @Effect()
    DeleteMyBudgetItem$: Observable<any> = this.action.pipe(ofType(frombudgetActions.DELETEMYBUDGETITEM),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MyBudget.deleteMyBudgetItem, action.payload)
            .pipe(map(res => new frombudgetActions.DeleteMyBudgetItemSuccess(res),
                catchError(err => of(new frombudgetActions.DeleteMyBudgetItemFailed(err)))
            ))));
    @Effect()
    GetMyBudgetCategory$:Observable<any>=this.action.pipe(ofType(frombudgetActions.GET_MYBUDGETCATEGORY),
    mergeMap((action:any)=>this.cs2.PostData(urls.UrlModel.User.MyBudget.MyBudgetCategory,action.payload)
    .pipe(map((res:any)=>new frombudgetActions.GetMyBudgetCategorySuccess(res),
    catchError(err=>of(new frombudgetActions.GetMyBudgetCategoryFailed(err)))
    ))))
}