
export const MYWEDDINGINSIGHT = '[WedingInsight] My Wedding Insight';
export const MYWEDDINGINSIGHTSUCCESS = '[WedingInsight] My Wedding Insight Success';
export const MYWEDDINGINSIGHTFAILED = '[WedingInsight] My Wedding Insight Success';
export const MYWEDDINGUPLOADFILE = '[WedingInsight] My Wedding Upload File';
export const MYWEDDINGUPLOADFILESUCESS = '[WedingInsight] My Wedding Upload File Success';
export const MYWEDDINGUPLOADFILEFAILED = '[WedingInsight] My Wedding Upload File Success';
export const MYWEDDINGPERSONALDETAILS = '[WedingInsight] My Wedding Personal Details';
export const MYWEDDINGPERSONALDETAILS_SUCCESS = '[WedingInsight] My Wedding Personal Details Success';
export const MYWEDDINGPERSONALDETAILS_FAILED = '[WedingInsight] My Wedding Personal Details Failed';
export const GETBACKGROUNDPRESET='[WeddingInsight]Get Background Preset';
export const GETBACKGROUNDPRESET_SUCCESS='[WeddingInsight]Get Background Preset Success';
export const GETBACKGROUNDPRESET_FAILED='[WeddingInsight]Get Background Preset Failed';
export const SETBACKGROUNDIMAGE='[WeddingInsight]Set Background';
export const SETBACKGROUNDIMAGE_SUCCESS='[WeddingInsight]Set Background Success';
export const SETBACKGROUNDIMAGE_FAILED='[WeddingInsight]Set Background Failed';
export const GETWEDDINGDETAILS='[WeddingInsight]Get Wedding Details';
export const GETWEDDINGDETAILS_SUCCESS='[WeddingInsight]Get Wedding Details Success';
export const GETWEDDINGDETAILS_FAILED='[WeddingInsight]Get Wedding Details Failed';
export class MyweddingInsight {
    public readonly type = MYWEDDINGINSIGHT;
    constructor() { }
}
export class MyweddingInsightSuccess {
    public readonly type = MYWEDDINGINSIGHTSUCCESS;
    constructor(public payload: any) { }
}
export class MyweddingInsightFailed {
    public readonly type = MYWEDDINGINSIGHTFAILED;
    constructor(public payload: any) { }
}
export class UploadFile {
    public readonly type = MYWEDDINGUPLOADFILE;
    constructor(public payload: FormData) { }
}
export class UploadFileSuccess {
    public readonly type = MYWEDDINGUPLOADFILESUCESS;
    constructor(public payload: FormData) { }
}
export class UploadFileFailed {
    public readonly type = MYWEDDINGUPLOADFILEFAILED;
    constructor(public payload: FormData) { }
}
export class MyWeddingPersonalDetails {
    public readonly type = MYWEDDINGPERSONALDETAILS;
    constructor() { }
}
export class MyWeddingPersonalDetailsSuccess {
    public readonly type = MYWEDDINGPERSONALDETAILS_SUCCESS;
    constructor(public payload: any) { }
}
export class MyWeddingPersonalDetailsFailed {
    public readonly type = MYWEDDINGPERSONALDETAILS_FAILED;
    constructor(public payload: any) { }
}
export class GetBackgroundPreset{
    public readonly type=GETBACKGROUNDPRESET;
    constructor(public payload:any){
    }
}
export class GetBackgroundPresetSuccess{
    public readonly type=GETBACKGROUNDPRESET_SUCCESS;
    constructor(public payload:any){
    }
}
export class GetBackgroundPresetFailed{
    public readonly type=GETBACKGROUNDPRESET_FAILED;
    constructor(public payload:any){
    }
}
export class SetBackgroundImage{
    public readonly type=SETBACKGROUNDIMAGE;
    constructor(public payload:any){

    }
}
export class SetBackgroundImageSuccess{
    public readonly type=SETBACKGROUNDIMAGE_SUCCESS;
    constructor(public payload:any){

    }
}
export class SetBackgroundImageFailed{
    public readonly type=SETBACKGROUNDIMAGE_FAILED;
    constructor(public payload:any){

    }
}
export class GetWeddingDetails{
    public readonly type=GETWEDDINGDETAILS;
    constructor(){}
}
export class GetWeddingDetailsSuccess{
    public readonly type=GETWEDDINGDETAILS_SUCCESS;
    constructor(public payload:any){}
}
export class GetWeddingDetailsFailed{
    public readonly type=GETWEDDINGDETAILS_FAILED;
    constructor(public payload:any){}
}
export type MyWeddingActions = MyweddingInsight | MyweddingInsightSuccess | MyweddingInsightFailed |
    UploadFile | UploadFileSuccess | UploadFileFailed | MyWeddingPersonalDetails |
    MyWeddingPersonalDetailsSuccess | MyWeddingPersonalDetailsFailed|GetBackgroundPreset|
    GetBackgroundPresetSuccess|GetBackgroundPresetFailed|SetBackgroundImage|SetBackgroundImageSuccess|
    SetBackgroundImageFailed|GetWeddingDetails|GetWeddingDetailsSuccess|GetWeddingDetailsFailed;
