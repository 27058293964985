
import { HomepageMenuVm } from '../Models/HomepageMenuVm';
import { Action } from '@ngrx/store';

// =============Menu Actions ====================
/* #region Menu Actions*/
export const LOADMENU = '[HOMEPAGE] LOADMENU';
export const ADDMENU = '[HOMEPAGE] ADD';
export const LOADSUBMENU = '[HOMEPAGE] LOADSUBMENU';
export class LoadMenuAction implements Action {
  readonly type = LOADMENU;
  constructor(public payload: any) { }
}
export class AddMenuAction implements Action {
  readonly type = ADDMENU;
  constructor(public payload: HomepageMenuVm) { }
}
export class LoadSubmenu implements Action {
  readonly type = LOADSUBMENU;
  constructor(public payload: any) {
  }
}
/*#end region*/
// =============End Of Menu Actions ====================

// =============Dream Location Actions ====================
export const LOADDREAMLOCATION = '[HOMEPAGE] Load Dream Location';
export const LOADDREAMLOCATIONSUCCESS = '[HOMEPAGE] Load Dream Location Success';
export const LOADDREAMLOCATIONFAILED = '[HOMEPAGE] Load Dream Location Failed';
export class LoadDreamLocation {
  readonly type = LOADDREAMLOCATION;
  constructor(public payload: string) {
    console.log(payload);
  }
}
export class LoadDreamLocationSuccess {
  readonly type = LOADDREAMLOCATIONSUCCESS;
  constructor(public payload: any) {

  }
}
export class LoadDreamLocationFailed {
  readonly type = LOADDREAMLOCATIONFAILED;
  constructor(public payload: any) {

  }
}
// ==============End Of Dream Location Actions================

export const LOADBANNERS = '[HOMEPAGE] Load Banners';
export const LOADBANNERS_SUCCESS = '[HOMEPAGE] Load Banners Success';
export const LOADBANNERS_FAILED = '[HOMEPAGE] Load Banners Failed';
export const LOADBANNERPREVIEW= '[HOMEPAGE] Load Banners Preview';
export const LOADBANNERPREVIEW_SUCCESS= '[HOMEPAGE] Load Banners Preview Success';
export const LOADBANNERPREVIEW_FAILED= '[HOMEPAGE] Load Banners Preview Failed';

export class LoadBanners  {
  public readonly type = LOADBANNERS;
  constructor(public payload: any) {
  }
}
export class LoadBannersSuccess {
  public readonly type = LOADBANNERS_SUCCESS;
  constructor(public payload: any) {
  }
}
export class LoadBannersFailed  {
  public readonly type = LOADBANNERS_FAILED;
  constructor(public payload: any) {
  }
}
export class LoadBannersPreview  {
  public readonly type = LOADBANNERPREVIEW;
  constructor(public payload: any) {
  }
}
export class LoadBannersPreviewSuccess  {
  public readonly type = LOADBANNERPREVIEW_SUCCESS;
  constructor(public payload: any) {
  }
}
export class LoadBannersPreviewFailed  {
  public readonly type = LOADBANNERPREVIEW_FAILED;
  constructor(public payload: any) {
  }
}

export const LOADCOUNTRIES='[HOMEPAGE]LOADCOUNTRIES';
export const LOADCOUNTRY_FAILED='[HOMEPAGE]Load Country Failed';
export const LOADCOUNTRY_SUCCESS='[HOMEPAGE]Load Country Success';
export const LOADDISTRICTS='[HOMEPAGE]Load Districts';
export const LOADSUBURB='[HOMEPAGE]Load Suburb';
export class LoadCountries {
  public readonly type=LOADCOUNTRIES;
  constructor(public payload:any){
  }
}
export class LoadCountry_Success{
  public readonly type=LOADCOUNTRY_SUCCESS;
  constructor(public payload:any){
    console.log(payload);
  }
}
export class LoadCountry_Failed{
  public readonly type=LOADCOUNTRY_FAILED;
  constructor(public payload:any){
  }
}
export class LoadDistrict{
  public readonly type=LOADDISTRICTS;
  constructor(public countryId:number){}
}
export class LoadSuburb{
  public readonly type=LOADSUBURB;
  constructor(public DistrictId:number){}
}

export type HomepageMenuActions = LoadMenuAction | AddMenuAction | LoadSubmenu
  | LoadDreamLocation | LoadDreamLocationSuccess |
  LoadDreamLocationFailed |
  LoadBanners | LoadBannersSuccess | LoadBannersFailed|LoadCountries|
  LoadCountry_Success|LoadCountry_Failed|LoadDistrict|LoadSuburb|
  LoadBannersPreview|LoadBannersPreviewSuccess|LoadBannersPreviewFailed;

