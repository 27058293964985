import { APIService } from "./api.service";

const Api_server = "https://pwapi.internext.mu/api/";
//const Api_server = "http://localhost:51465/api/";

export const UrlModel = {
  admin: {
    adminHome: "https://admin.internext.mu/Taskboard?id=",
    // adminHome: "http://localhost:4203/Taskboard?id=",
    TaskBoard: {
      GetTaskboard: Api_server + "ManageTaskBoards/searchtasks",
      CreateUpdateTask: Api_server + "ManageTaskBoards/createupdatetasks",
      DeleteTask: Api_server + "ManageTaskBoards/deletetask?id=",
    },
    ManageAccount: {
      SendResetPasswordEmail:
        Api_server + "AdminManageAccounts/sendresetpasswordemail",
      UpdateAccountStatus:
        Api_server + "AdminManageAccounts/updateaccountstatus",
      SearchCouples: Api_server + "AdminManageAccounts/searchcouples",
      SearchVendor: Api_server + "AdminManageAccounts/searchvendors",
    },
    Tickets: {
      SearchTicket: Api_server + "ManageTickets/searchtickets",
      AddTicket: Api_server + "ManageTickets/addticket",
      GetTicketDetails: Api_server + "ManageTickets/ticketdetails?id=",
      UpdateTicket: Api_server + "ManageTickets/updateticket",
    },
    Events: {
      SearchEvent: Api_server + "ManageEvents/searchevents",
      UpdateEventStatus: Api_server + "ManageEvents/updateeventstatus",
    },
    Deals: {
      SearchDeals: Api_server + "ManageDeals/searchdeals",
      UpdateDealsStatus: Api_server + "ManageDeals/updatedealsstatus",
    },
    ManageVacencies: {
      SearchJob: Api_server + "ManageVacancy/searchjob",
      CreateUpdateJob: Api_server + "ManageVacancy/createupdatejob",
      DeleteVacency: Api_server + "ManageVacancy/DeleteVacency?id=",
    },
    BlogEditor: {
      SearchBlog: Api_server + "ManageBlogs/searchblogs",
      CreateUpdateBlog: Api_server + "ManageBlogs/createupdateblogs",
      UpdateStatus: Api_server + "ManageBlogs/updatestatus",
      GetBlogTopics: Api_server + "PerfectWedding/blogtopics",
    },
    ManageExpense: {
      SearchExpense: Api_server + "ManageExpenses/searchexpenses",
      ExpenseTypes: Api_server + "ManageExpenses/expensestypes",
      UpdateExpense: Api_server + "ManageExpenses/updateexpenses",
      AddExpense: Api_server + "ManageExpenses/addexpenses",
    },
    ManageCoupon: {
      LastFourdiscountcodes:
        Api_server + "ManageCoupon/last4discountcodes?type=",
      PercentageValues: Api_server + "ManageCoupon/percentagevalues",
      SearchVendors: Api_server + "ManageCoupon/searchvendors",
      CreateCoupon: Api_server + "ManageCoupon/createcoupon",
      CreateCampaign: Api_server + "ManageCoupon/createcampaign",
      SearchCode: Api_server + "ManageCoupon/searchcodes",
      GenerateCouponCode: Api_server + "ManageCoupon/generatecouponcode",
      GetUsesDetails: Api_server + "ManageCoupon/usesdetails?code=",
      DeleteCoupon: Api_server + "/api/ManageCoupon?id=",
    },
    ManageFeedback: {
      SearchFeedback: Api_server + "ManageFeedbacks/searchfeedback",
      GetFeedbackRating: Api_server + "ManageFeedbacks/feedbackratings?year=",
      GetFeedbackCountBytype:
        Api_server + "ManageFeedbacks/feedbackcountbytype",
      MarkFeedbackAsRead: Api_server + "ManageFeedbacks/markfeedbackasread",
      UpdateStatus: Api_server + "ManageFeedbacks/updatefeedbackstatus",
      RemoveFeedback: Api_server + "ManageFeedbacks/removefeedback?id=",
    },
    VenngdorSetting: {
      UpdateBusinessName: Api_server + "VendorSettings/updatebusinessname",
      UpdateBusinessCategory:
        Api_server + "VendorSettings/updatebusinesscategory",
      UpdateMembershipPlan: Api_server + "VendorSettings/updatemembershipplan",
      UpdateLocation: Api_server + "VendorSettings/updatelocations",
    },
    AdminAccessCenter: {
      GetAdminRole: Api_server + "ManageAdminUsers/adminroles",
      GetAccessModule: Api_server + "ManageAdminUsers/accessmodules",
      CreateUser: Api_server + "ManageAdminUsers/createuser",
      UpdateUser: Api_server + "ManageAdminUsers/updateuser",
      UpdateUserModules: Api_server + "ManageAdminUsers/updateusermodules",
      SearchUser: Api_server + "ManageAdminUsers/searchusers",
      AdminUserCounter: Api_server + "ManageAdminUsers/adminusercounter",
      RemoveAdminUser: Api_server + "ManageAdminUsers/removeadminuser?id=",
    },
    SalesInvoice: {
      CreateUpdateInvoice: Api_server + "ManageSalesInvoices/AddUpdateInvoice",
      GetPendingInvoices: Api_server + "ManageSalesInvoices/PendingInvoices",
      CancelInvoice: Api_server + "ManageSalesInvoices/CancelInvoice?id=",
      GetCancelledInvoices:
        Api_server + "ManageSalesInvoices/GetCancenlledInvoices",
      GetPaymentMethods: Api_server + "ManageSalesInvoices/paymentmethods",
      GetPaymentPurpose: Api_server + "ManageSalesInvoices/PaymentPurposes",
      SearchInvoice: Api_server + "ManageSalesInvoices/SearchInvoices",
      SearchCustomer: Api_server + "ManageSalesInvoices/SearchCustomers",
      EmailInvoice: Api_server + "ManageSalesInvoices/emailinvoice?UniqueId=",
      CancelInvoicePost: Api_server + "ManageSalesInvoices/CancelInvoice?id=",
      ViewPdfInvoice:
        Api_server + "ManageSalesInvoices/ViewPdfInvoice?UniqueId=",
      DeleteInvoiceFile:
        Api_server + "ManageSalesInvoices/DeleteInvoiceFile?url=",
      InvoiceDiscount: Api_server + "ManageSalesInvoices/InvoiceDiscounts",
    },
    ManageBanner: {
      CreateUpdateBanner: Api_server + "ManageBanners/createupdatebanner",
      GetAvailableSlot: Api_server + "ManageBanners/AvailableSlots",
      GetSlotsForBanner: Api_server + "ManageBanners/GetSlotsForBanner",
      GetSlotDetails: Api_server + "ManageBanners/SlotDetails",
      GetVendorByEmail: Api_server + "ManageBanners/VendorByEmail",
      GetBannerDetails: Api_server + "ManageBanners/bannerdetails?id=",
      SendLink: Api_server + "ManageBanners/sendlink",
      BannerByStatus: Api_server + "ManageBanners/bannerbystatus",
      GetBannerCountByStatus: Api_server + "ManageBanners/bannercountsbystatus",
      CreateSalesTicket: Api_server + "ManageBanners/createsalesticket",
      VerifyInvoiceWithBanner:
        Api_server + "ManageBanners/Verifyinvoicewithbanner",
      DeleteBanner: Api_server + "ManageBanners/deletebanner",
      GetPromoLog: Api_server + "ManageBanners/promolog?categoryId=",
    },
    DashSlides: {
      UploadImages: Api_server + "ManageDashslides/addupdateslide",
      UpdateStatus: Api_server + "ManageDashslides/updatestatus",
      ValidateSlides: Api_server + "ManageDashslides/validateslide",
      DashSlides: Api_server + "ManageDashslides/dashslides",
      RemoveSlide: Api_server + "ManageDashslides/deleteslide?id=",
    },
    PlatformSetting: {
      GetPlatformSetting: Api_server + "ManageHomePage/HomePageSettings",
      UpdateHomePage: Api_server + "ManageHomePage/updatehomepage",
      UpdateCategoryImage: Api_server + "ManageHomePage/updatecategoryimage",
      UpdateLocationImage: Api_server + "ManageHomePage/updatelocationimage",
      GetSLotPrices: Api_server + "ManagePromotionSlotPrices/SlotPrices",
      UpdateSlotPrices:
        Api_server + "ManagePromotionSlotPrices/updateslotprice",
      UpdateCategorySlotPrices:
        Api_server + "ManagePromotionSlotPrices/updatecategoryslotprice",
      UpdatePlanPrice: Api_server + "ManageMembershipPlan/updateprice",
      GetAdTypes: Api_server + "ManagePromotionSlotPrices/GetAdTypes",
      UpdateAdtypePrice:
        Api_server + "ManagePromotionSlotPrices/UpdateAdTypePrice",
      GetCategoryWiseSlots:
        Api_server + "ManagePromotionSlotPrices/GetSlotsByCategory",
      UpdateCategorySlots:
        Api_server + "ManagePromotionSlotPrices/UpdateCategorySlots",
    },
    ManageHonymoonLocation: {
      CreateUpdateLocation:
        Api_server + "ManageHoneymoonLocations/createupdatelocation",
      SearchHonymoonLocation:
        Api_server + "ManageHoneymoonLocations/searchlocation",
      RemoveHonymoonLocation: Api_server + "ManageHoneymoonLocations/",
    },
    SiteReports: {
      TotalActiveVendors: Api_server + "SiteReport/totalactivevendors",
      VendorCountsInAllCategory:
        Api_server + "SiteReport/VendorsCountInAllcategory",
      VendorCountsInCategory:
        Api_server + "SiteReport/VendorCountInCategory?id=",
      SearchVendorByCategory: Api_server + "SiteReport/searchvendorsbycategory",
      MembershipTrends: Api_server + "SiteReport/membershiptrends",
      MembershipPlanStast: Api_server + "SiteReport/membershipplansstats",
      LastFiveYearTicketStastic:
        Api_server + "SiteReport/ticketstatslastfiveyears",
      MembershipByCategory: Api_server + "SiteReport/membershipbycategory",
      MembershipList: Api_server + "SiteReport/membershiplist",
      TopListingDiscVsNormal:
        Api_server + "SiteReport/TopListingDiscountedNormal?year=",
      FeaturedListingDiscVsNormal:
        Api_server + "SiteReport/FeaturedListingDiscountedNormal?year=",
      DreamWeddingDiscVsNormal:
        Api_server + "SiteReport/DreamWeddingLocationDiscountedNormal?year=",
      MembershipDiscVsNormal:
        Api_server + "SiteReport/MembershipDiscountedNormal?year=",
      HomepageBannerDiscVsNormal:
        Api_server + "SiteReport/HomePageBannerDiscountedNormal?year=",
      TotalRevenue: Api_server + "SiteReport/TotalRevenue",
      TotalExpense: Api_server + "SiteReport/TotalExpenses",
      TotalDiscount: Api_server + "SiteReport/TotalDiscounts",
      TotalInterimGrossProfit:
        Api_server + "SiteReport/TotalInterimGrossProfit",
      ManualInvoiceDisvVsNormal:
        Api_server + "SiteReport/ManualInvoiceDiscountedNormal?year=",
    },
  },
  Vendor: {
    MyBusinessCategory: Api_server + "Supplier/mybusinessservices",
   StaggingDashboard: "https://vendor.internext.mu/Dashboard",
  // StaggingDashboard: "http://localhost:4201/Dashboard",
    ProductionDashboard: "https://vendor.internext.mu/Dashboard",
    AlbumOverview: Api_server + "Albums/albumoverview",
    Myprofile: Api_server + "Supplier/myprofile",
    MyDashboard: Api_server + "VendorDashboard/Home",
    VendorSlides: Api_server + "PerfectWedding/vendordashslides",
    VendorAllImages: Api_server + "Supplier/allimages",
    ChangeCoverImage: Api_server + "Albums/setasstorefrontimage",
    MyPersonalInfo: Api_server + "Supplier/mypersonalinfo",
    UpdatePersonalInfo: Api_server + "Supplier/updatepersonalinfo",
    MyMembershipDetails: Api_server + "Supplier/mymembership",
    ChangePassword: Api_server + "Accounts/changepassword",
    BillingAndInvoices: {
      Invoices: Api_server + "Supplier/myinvoices",
      InvoiceDetails: Api_server + "Supplier/InvoiceDetails?uniqueid=",
      DownloadInvoice: Api_server + "Supplier/downloadinvoice?UniqueId=",
    },
    BusinessInformation: {
      GetBusinessInfo: Api_server + "Supplier/businessinfo",
      UpdateBusinessLogo: Api_server + "Supplier/updatebusinesslogo",
      UpdateBusinessDescription:
        Api_server + "Supplier/updatebusinessdescription",
      UpdateFaceBookDetails: Api_server + "Supplier/updatefacebookdetails",
      UpdateInstaDetails: Api_server + "Supplier/updateinstadetails",
      UpdateTwitterDetails: Api_server + "Supplier/updatetwitterdetails",
      UpdateWebsiteDetails: Api_server + "Supplier/updatewebsitedetails",
    },
    BranchesAndTrading: {
      AddNewBranch: Api_server + "Supplier/addlocation",
      GetMyBranches: Api_server + "Supplier/mylocations",
      UpdateBranchStatus: Api_server + "Supplier/updatelocationstatus",
      SetAsPrimary: Api_server + "Supplier/setprimarylocation",
      DeleteBranchPhone: Api_server + "Supplier/deletelocationphone",
      AddUpdateBranchPhone: Api_server + "Supplier/addupdatephones",
      UpdateLocationMap: Api_server + "Supplier/updatelocationmap",
      UpdateLocationAddress: Api_server + "Supplier/updatelocationaddress",
      UpdateLocationTradingHrs:
        Api_server + "Supplier/updatelocationtradinghours",
      UpdateSubscriptionSetting:
        Api_server + "Supplier/updatesubscriptionsettings",
    },
    BusinessService: {
      SaveBusinessService: Api_server + "Supplier/savebusinessservices",
    },
    Messages: {
      MyMessages: Api_server + "messages/v2/mymessages",
      MarkAsRead: Api_server + "Messages/markasread?id=",
      MarkAsStarred: Api_server + "Messages/markstaread?id=",
      MessageDelete: Api_server + "Messages/deletemessages",
      SendMessage: Api_server + "Messages/Post",
      MessageHistory: Api_server + "Messages/messagehistory?id=",
    },
    Videos: {
      CreateUpdateVideos: Api_server + "Videos/createupdatevideos",
      // CreateUpdateVideos: Api_server + 'FilesUploader/uploadVideos',
      GetMyVideos: Api_server + "Videos/myvideos",
      VideoDetails: Api_server + "Videos/videodetails",
      RemoveVideo: Api_server + "Videos/removevideo?VideoId=",
    },
    MyPortfolio: Api_server + "Supplier/myportfolio",
    MyAlbums: Api_server + "Albums/myalbums?albumType=",
    MyAlbumDetails: Api_server + "Albums/albumimages?albumId=",
    AlbumCoverImage: Api_server + "Albums/setascoverimage?AlbumImageId=",
    SetBusinesCover: Api_server + "Albums/setasstorefrontimage?AlbumImageId=",
    // RemovePortfolio:Api_server+'Supplier/removeportfolio',
    PortfolioUpload: Api_server + "ImageUploader/PortfolioUploader",
    AlbumImageUpload: Api_server + "ImageUploader/AlbumImageUpload",
    CreateUpdateAlbum: Api_server + "Albums/createupdatealbum",
    DeleteAlbum: Api_server + "Albums/deletealbum",
    DeleteAlbumImage: Api_server + "Albums/removeimage?AlbumImageId=",
    Reviews: {
      MyReviews: Api_server + "Reviews/myreviews",
      ReviewsMarkAsRead: Api_server + "Reviews/ReviewReadStatus",
      ReviewMarkAsPinned: Api_server + "Reviews/markaspinned?ReviewId=",
      ReviewSendFeedback: Api_server + "Reviews/sendfeedback",
      AvgRatingDetails: Api_server + "Reviews/AvgRatingDetails",
    },
    Events: {
      GetMyEvnets: Api_server + "Events/myevents",
      CreateEvents: Api_server + "Events/createupdateevent",
      EventDetails: Api_server + "Events/eventdetails?id=",
      DelteEvent: Api_server + "Events/removeevent?id=",
    },
    Planner: {
      MyPlanner: Api_server + "VendorMyPlanner/myjobs",
      RemoveJob: Api_server + "VendorMyPlanner/removejob?VendorJobId=",
      CreateUpdateJob: Api_server + "VendorMyPlanner/createupdatejobs",
    },
    PromoteBusiness: {
      GetAddType: Api_server + "PromoteBusiness/adtypes",
      SaveAdLog: Api_server + "PromoteBusiness/saveadlog",
      PromotionSummary: Api_server + "PromoteBusiness/promotionsummary?days=",
      MyBusinessPromotion: Api_server + "PromoteBusiness/mypromotions",
      ValidateVoucher: Api_server + "Supplier/validatevoucher",
      CompleteTransaction: Api_server + "PromoteBusiness/saveadlog",
      PaypalResponse: Api_server + "ProcessPayment/completetransaction",
      GetDyanimcSlots: Api_server + "PromoteBusiness/GetSlots?adType=",
      UpdatePaymentStatus: Api_server + "PromoteBusiness/UpdatePaymentStatus",
      checkSlotBookingExist:
        Api_server + "PromoteBusiness/CheckSlotBookingExist",
      GetMyImages: Api_server + "PromoteBusiness/GetMyImages?slotId=",
      BookHomepageBanner: Api_server + "PromoteBusiness/BookHomepageBanner",
      ProceedToBannerPay:
        Api_server + "Supplier/ProceedToBannerPayment?slotId=",
    },
    DealsAndDiscount: {
      CreateUpdateDeal: Api_server + "Supplier/createupdatedeals",
      Mydeal: Api_server + "Supplier/mydeals",
      DeleteDeal: Api_server + "Supplier/deletedeal?dealId=",
      Updatated_Discount: Api_server + "Supplier/updatediscount",
      SupplierDiscount: Api_server + "Supplier/discount",
    },
    VendorStastics: {
      Mycllsearch: Api_server + "VendorStatistcs/CallsSearch",
      ContactSearch: Api_server + "VendorStatistcs/ContactsSearch",
      EnquirySearch: Api_server + "VendorStatistcs/EnquiriesSearch",
      EnquiriesAndLeads:
        Api_server + "VendorStatistcs/enquiriesandleads?frequency=",
      ActionOnProfile:
        Api_server + "VendorStatistcs/actiononmyprofile?frequency=",
      MyGallery: Api_server + "VendorStatistcs/mygalleryview?frequency=",
      MySearch: Api_server + "VendorStatistcs/myreach?frequency=",
      MyStoreFrontView:
        Api_server + "VendorStatistcs/mystorefrontview?frequency=",
      VendorStastics: Api_server + "VendorStatistcs/VendorsStatistics",
      BusinessInsight: Api_server + "VendorStatistcs/businessinsight",
    },
    Lead: {
      GetLeads: Api_server + "Supplier/SearchLeads",
      CreateOffer: Api_server + "Supplier/createleadoffer",
      SearchMyLeads: Api_server + "Supplier/SearchMyLeads",
      GetEnquiries: Api_server + "Supplier/MyEnquiries?isNew=",
      SendEnquiryResponse: Api_server + "Supplier/SendEnquiryResponse",
    },
    MyAccount: {
      UpdateMembershipPlan: Api_server + "Supplier/upgrademembership",
    },
  },
  User: {
    WeddingInsight: {
      MyweddingInsight: Api_server + "Couple/myweddinginsight",
      FileUploader: Api_server + "FilesUploader/FileUploader",
      MyWeddingPersonalInfo: Api_server + "Couple/personalinfo",
      GetMyWeddingDetails: Api_server + "Couple/weddingdetails",
    },
    MyInspiration: {
      MyInspirationPhotos:
        Api_server + "Couple/myinspirationsphotos?categoryId=",
      MyInspirationEvents: Api_server + "Couple/myinspirationevents?filter=",
      DeleteMyInspiration:
        Api_server + "Couple/DeleteFromInspirations?InspirationsId=",
      MyInspirationDeal: Api_server + "Couple/myinspirationdeals",
    },
    Lead: {
      PostLead: Api_server + "Couple/PostLead",
      GetMyLead: Api_server + "Couple/MyLeads",
      GetOffer: Api_server + "Couple/GetLeadOffers",
      PostEnquiries: Api_server + "Couple/PostEnquiry",
      MyEnquiries: Api_server + "Couple/MyEnquiry?isNew=",
      ReplyToOffer: Api_server + "Couple/ReplyToOffer",
    },
    Checklist: {
      MyToDoCreateUpdate: Api_server + "ToDo/createupdatetodo",
      MyToDo: Api_server + "ToDo/mychecklist",
      MyToDoByTimeCount: Api_server + "ToDo/mychecklistbytimecount",
      MyTodoByCategoryCount: Api_server + "ToDo/categorieswithcount",
      MyToDoRemove: Api_server + "ToDo/removetodo?id=",
      SearchMySuppliers: Api_server + "PerfectWedding/searchmysuppliers",
      GetMyChecklist: Api_server + "Todo/mychecklist",
    },
    GuestList: {
      CreateUpdateGrooup: Api_server + "Groups/createupdategroups",
      GetMyGroup: Api_server + "Groups/mygroups",
      MyGroupGuestCount: Api_server + "Groups/groupguestscount",
      RemoveGroup: Api_server + "Groups/removegroup",
      CreateUpdateGuest: Api_server + "Guests/createupdateguests",
      GetMyGuest: Api_server + "Guests/myGuests",
      MyGuestCount: Api_server + "Guests/myguestscounts",
      MyGuestSearch: Api_server + "Guests/myguestssearch",
      MyInvitationCount: Api_server + "Guests/myInvitesCount",
      UpdateGuestInvitation: Api_server + "Guests/updateguestsinitation",
      CraeteUpdateMenu: Api_server + "Menu/createupdatemenu",
      GetMyMenu: Api_server + "Menu/mymenu",
      MyGuestCountByMenu: Api_server + "Menu/menuguestscount",
      RemoveMyGuest: Api_server + "Guests/removeguests",
      RemoveMenu: Api_server + "Menu/removemenu?id=",
    },
    MyBudget: {
      CreateUpdateBudgetItem: Api_server + "BudgetItem/createupdatebudgetitem",
      MyBudgetItems: Api_server + "BudgetItem/mybudgetitems?BudgetCategoryId=",
      mybudgetitemsbycategory:
        Api_server + "BudgetItem/mybudgetitemsbycategory",
      myexpensestotal: Api_server + "BudgetItem/myexpensestotal",
      myexpenseByCategoryCount:
        Api_server + "BudgetCategory/expensesbycategorycount?costType=",
      deleteMyBudgetItem: Api_server + "BudgetItem/removebudgetitem",
      MyBudgetCategory: Api_server + "BudgetCategory/mybudgetcategory",
    },
    Myreviews: {
      GetMyReviews: Api_server + "Couple/myreviews",
      CreateUpdateReview: Api_server + "Couple/UpdateReview",
      PostReviews: Api_server + "Reviews/PostReview",
    },
    MyAccount: {
      getPreferanceOptions: Api_server + "LookupMaster/weddingprefernces",
      updateWeddingPreferance: Api_server + "Couple/updateweddingpreference",
      getPersonalInfo: Api_server + "Couple/personalinfo",
      myWeddingDetails: Api_server + "Couple/weddingdetails",
      updatePersonalDetails: Api_server + "Couple/UpdatePersonalInfo",
      updateWeddingDetails: Api_server + "Couple/UpdateWeddingDetails",
      UpdateAccountImage: Api_server + "Couple/updateaccountimages",
    },
    Feedback: {
      SendFeedback: Api_server + "PerfectWedding/sendfeedback",
    },
    MySuppliers: {
      MyVendorCount: Api_server + "Couple/myvendorscount",
      VendorCountByCategory: Api_server + "Couple/vendorsbycategorycount",
      SearchMySuppliers: Api_server + "Couple/searchmysuppliers",
      ChangeMyVendorStatus: Api_server + "Couple/changesupplierstatus",
      DeleteMyVendor: Api_server + "Couple/removesupplier",
      SendNoteToSupplier: Api_server + "Couple/notesforsupplier",
    },
  },
  Application: {
    FeaturedVendors:
      Api_server + "PerfectWedding/HomepagePromotedVendors?loggedInUserId=",
    SearchPhotos: Api_server + "PerfectWedding/searchphotos",
    SearchPhotosByAlbum: Api_server + "PerfectWedding/albumDetails",
    BlogTopics: Api_server + "PerfectWedding/blogtopics",
    HomepageBanners: Api_server + "PerfectWedding/banners?device=",
    HomepageBannerPreview:
      Api_server + "PerfectWedding/HomepageBannerPreview?SlId=",
    SearchBlog: Api_server + "PerfectWedding/searchblogs",
    BlogDetails: Api_server + "PerfectWedding/blogdetails?id=",
    SearchEvents: Api_server + "PerfectWedding/searchevents",
    SearchEventDetails: Api_server + "PerfectWedding/eventdetails?id=",
    HighlitedDeals: Api_server + "PerfectWedding/highlighteddeal",
    DiscountAndDeals: Api_server + "PerfectWedding/deals",
    Discount: Api_server + "PerfectWedding/discounts",
    DealsDetails: Api_server + "PerfectWedding/dealdetails?id=",
    HoneymoonDestinations: Api_server + "PerfectWedding/honeymoondestinations",
    VendorsInHonymoonTravel:
      Api_server + "PerfectWedding/vendorsinhoneymoonandtravel",
    HonymoonTravelServices:
      Api_server + "PerfectWedding/ServiceByCategory?categoryId=",
    // VendorsInHoneymoonTravel:Api_server+'PerfectWedding/vendorsinhoneymoonandtravel',
    SearchListing: Api_server + "PerfectWedding/v2/searchlisting",
    ListingDetails: Api_server + "PerfectWedding/vendordetails?id=",
    PricingPlans: Api_server + "LookupMaster/pricingplans",
    Faqs: Api_server + "PerfectWedding/sitefaqs",
    Vacencies: Api_server + "PerfectWedding/vacancies",
    VendorByCategory: Api_server + "Categories/categorieswithlistingcount",
    vendorSignup: Api_server + "Supplier/register",
    InitPayment: Api_server + "Supplier/initiatepayment",
    GetSimilarVendors: Api_server + "PerfectWedding/similarvendors",
    GetReviews: Api_server + "PerfectWedding/VendorReviews",
    GetImagesByAlbum: Api_server + "PerfectWedding/ViewAlbumImages?albumId=",
    GetPortfolioImages:
      Api_server + "PerfectWedding/GetPortfolioImages?VendorId=",
    GetVideosByVendor: Api_server + "PerfectWedding/VideosByVendor?vendorid=",
    GetAlbumByVendor: Api_server + "PerfectWedding/AlbumsByVendor",
    GetDealsByVendor: Api_server + "PerfectWedding/DealsByVendor?vendorid=",
    FacebookLogin: Api_server + "ExternalAuth/Facebook/facebook",
    GoogleLogin: Api_server + "ExternalAuth/Googlelogin/googlelogin",
    ApprovePromotion: Api_server + "PerfectWedding/ApprovePromotion?UniqueId=",
    AddVideoViewLog: Api_server + "PerfectWedding/LogVideoViewAction?videoId=",
  },
  Common: {
    SetBackgroundImage: Api_server + "Couple/SetBackgroundPreset",
    GetBackgroundPreset: Api_server + "Couple/BackgroundPreset?purpose=",
    GetCategory: Api_server + "Supplier/mybusinessservices",
    ALlLocations: Api_server + "LookupMaster/alllocation",
    AllCategories: Api_server + "Categories/allcategories",
    AllCategoryWithServices: Api_server + "Categories/categorieswithservices",
    Login: Api_server + "Auth/login",
    ForgetPassword: Api_server + "Accounts/forgotpassword",
    ResetPassword: Api_server + "Accounts/resetpassword",
    MarkAsBookmark: Api_server + "Couple/markasbookmark",
    Countries: Api_server + "LookupMaster/countries",
    PricingPlans: Api_server + "LookupMaster/pricingplans",
    ContactUs: Api_server + "Home/contactus",
    Discounts: Api_server + "LookupMaster/discounts",
    CheckEmailExist: Api_server + "PerfectWedding/isemailexists?email=",
    GetFilterPrices: Api_server + "LookupMaster/filterprices",
    UploadFile: Api_server + "FilesUploader/FileUploader",
    UserSignup: Api_server + "Accounts",
    ConvertMURTOAUD: Api_server + "LookupMaster/convertmurttoaud?amount=",
  },
  messenger: {
    AddMysetting: "User/AddUserSetting",
    GetMySetting: "User/GetMySetting",
    GetMessageInfo: "Chat/GetMessageInfo",
    DeleteMessage: "Chat/DeleteteMsg",
    GetPairedMessage: "Chat/PairedMsg",
    GetMyContacts: "MyContact/GetMyContact",
    BlockUser: "MyContact/BlockUser",
    Uploadfile: "Chat/Upload",
    MyGallery: "Chat/GetMyGallery",
    ReportUser: "User/ReportUser",
  },
};
//
