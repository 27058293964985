import { Action } from '@ngrx/store';

export const LOADALLLOCATIONS = '[LOCATION] Load All Location';
export const LOADALLLOCATIONSSUCCESS = '[LOCATION] Load All Location Success';
export const LOADALLLOCATIONSFAILED = '[LOCATION] Load All Location Failed';
export class LoadAllLocations implements Action {
  public type = LOADALLLOCATIONS;
  constructor(public payload: any) { }
}
export class LoadAllLocationsSuccess implements Action {
  public type = LOADALLLOCATIONSSUCCESS;
  constructor(public payload: any) {
  }
}
export class LoadAllLocationsFailed implements Action {
  public type = LOADALLLOCATIONSFAILED;
  constructor(public payload: any) { }
}

export type LocationActions = LoadAllLocations | LoadAllLocationsFailed | LoadAllLocationsSuccess;
