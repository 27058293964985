import { Action } from '@ngrx/store';
import { SearchBlogVm } from '../../blogs/blogs.component';

export const LOADBLOGS = '[Blogs] Load';
export const LOADBLOGS_SUCCESS = '[Blogs] Load Blog Success';
export const LOADBLOGS_FAILED = '[Blogs] Load Blog Failed';
export const LOADBLOGS_TOPICS = '[Blogs] Load Blog Topics';
export const LOADBLOGS_TOPICS_SUCCESS = '[Blogs] Load Blog Topics Success';
export const LOADBLOGS_TOPICS_FAILED = '[Blogs] Load Blog Topics Failed';
export const LOADBLOGDETAILS='[Blogs]Load Blog Details';
export const LOADBLOGDETAILS_SUCCESS='[Blogs]Load Blog Details Success';
export class LoadBlogs implements Action {
  readonly type = LOADBLOGS;
  constructor(public payload: SearchBlogVm) { }
}
export class LoadBlogsSuccess implements Action {
  readonly type = LOADBLOGS_SUCCESS;
  constructor(public payload: any) { }
}
export class LoadBlogsFail implements Action {
  readonly type = LOADBLOGS_FAILED;
  constructor(public payload: any) { }
}
export class LoadBlogsTopics implements Action {
  readonly type = LOADBLOGS_TOPICS;
  constructor() { }
}
export class LoadBlogsTopicSuccess implements Action {
  readonly type = LOADBLOGS_TOPICS_SUCCESS;
  constructor(public payload: any) { }
}
export class LoadBlogsTopicFail implements Action {
  readonly type = LOADBLOGS_TOPICS_FAILED;
  constructor() { }
}
export class LoadBlogDetails{
  public readonly type=LOADBLOGDETAILS;
  constructor(public payload:number){

  }
}
export class LoadBlogDetailsSuccess{
  public readonly type=LOADBLOGDETAILS_SUCCESS;
  constructor(public payload:any){

  }
}
export type BlogActions = LoadBlogs | LoadBlogsSuccess | LoadBlogsFail | LoadBlogsTopics | LoadBlogsTopicSuccess | LoadBlogsTopicFail|LoadBlogDetails|LoadBlogDetailsSuccess;
