import { createSelector } from "@ngrx/store";
import * as UserSignupActions  from "../Actions/UserSignup.action";
import { APPSTATE } from "../HomePageState";

export const UsersignupInit={
    loading:false,
    signupResponse:null,
}
export function UserSignupReducer(state=UsersignupInit,action:UserSignupActions.UserSignupActions){
    switch(action.type){
        case UserSignupActions.USERSIGNUP:
            return{...state,loading:true}
        case UserSignupActions.USERSIGNUPSUCCESS:
            console.log(action.payload);
            return{...state,loading:false,signupResponse:action.payload}
        case UserSignupActions.USERSIGNUPFAILED:
            return{...state,loading:false}
        default:
            return state;
    }
}
export const Usersignupreducer=(state:APPSTATE)=>state.usersignupreducer;
export const SelectUserSignupLoader=createSelector(Usersignupreducer,(state:any)=>state.loading?state.loading:null);
export const SelectUserSignupResponser=createSelector(Usersignupreducer,(state:any)=>state.signupResponse?state.signupResponse:null);