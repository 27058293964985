import * as  FromLeadActions  from '../../Actions/Couple/LeadsAction';
import { APPSTATE } from '../../HomePageState';
import { createSelector } from '@ngrx/store';

export const LeadInitialState = {
    loading: false,
    leads:{},
    offers:{},
    postLeadMsg:{},
    replyToLeadMsg:{}
    
}
export function LeadReducer(state=LeadInitialState,action:FromLeadActions.LeadActions){
    switch(action.type){
        case FromLeadActions.GETOFFERSBYLEAD:
            return{...state,loading:true}
        case FromLeadActions.GETOFFERSBYLEAD_SUCCESS:
            return{...state,loading:false,offers:action.payload}
        case FromLeadActions.GETOFFERSBYLEAD_FAILED:
            return{...state,loading:false}
            case FromLeadActions.POSTLEAD:
            return{...state,loading:true}
        case FromLeadActions.POSTLEAD_SUCCESS:
            return{...state,leads:action.payload.items,loading:false,LeadPostSuccessResponse:action.payload}
        case FromLeadActions.GETMYLEADS:
            return{...state,loading:true}
        case FromLeadActions.GETMYLEADS_SUCCESS:
            return{...state,leads:action.payload,loading:false}
        case FromLeadActions.REPLYTOOFFER:
            return{...state,loading:true}
        case FromLeadActions.REPLYTOOFFER_SUCCESS:
            return{...state,loading:false,replyToLeadMsg:action.payload}
        case FromLeadActions.REPLYTOOFFER_FAILED:
            return{...state,loading:false}
    }
}
export const leadreducer = (state: APPSTATE) => state;
export const SelectLeads = createSelector(leadreducer, (state: any) => state.leadreducer ? state.leadreducer.leads : null);
export const SelectOffers = createSelector(leadreducer, (state: any) => state.leadreducer ? state.leadreducer.offers : null);
export const SelectLeadPostSuccessResponse = createSelector(leadreducer, (state: any) => state.leadreducer ? state.leadreducer.LeadPostSuccessResponse : null);
export const SelectReplyToLeadMsg = createSelector(leadreducer, (state: any) => state.leadreducer ? state.leadreducer.replyToLeadMsg : null);