import * as fromMyweddingInsight from '../../Actions/Couple/MyWeddingInsight.action';
import { APPSTATE } from '../../HomePageState';
import { createSelector } from '@ngrx/store';
export const MyWeddingInsightState = {
    loading: false,
    myweddinginsightdata: {},
    personalInfo: {},
    msg: {},
    presets:[],
    weddingDetails:{}
}
export function MyWeddingReducer(state = MyWeddingInsightState, action: fromMyweddingInsight.MyWeddingActions) {
    switch (action.type) {
        case fromMyweddingInsight.MYWEDDINGINSIGHT:
            return { ...state, loading: true, myweddinginsightdata: state.myweddinginsightdata }
        case fromMyweddingInsight.MYWEDDINGINSIGHTSUCCESS:
            return { ...state, loading: false, myweddinginsightdata: action.payload }
        case fromMyweddingInsight.MYWEDDINGINSIGHTFAILED:
            return { ...state, loading: false, myweddinginsightdata: state.myweddinginsightdata }
        case fromMyweddingInsight.MYWEDDINGPERSONALDETAILS:
            return { ...state, loading: false }
        case fromMyweddingInsight.MYWEDDINGPERSONALDETAILS_SUCCESS:
            return { ...state, personalInfo: action.payload, loading: false }
        case fromMyweddingInsight.MYWEDDINGPERSONALDETAILS_FAILED:
            return { ...state, loading: false, msg: action.payload }
        case fromMyweddingInsight.GETBACKGROUNDPRESET:
            return{ ...state, loading: true, msg: ''}
        case fromMyweddingInsight.GETBACKGROUNDPRESET_SUCCESS:
            return{...state,loading:false,presets: action.payload}
        case fromMyweddingInsight.GETBACKGROUNDPRESET_FAILED:
            return{...state,msg:''}
        case fromMyweddingInsight.SETBACKGROUNDIMAGE:
            return{...state,loading:true}
        case fromMyweddingInsight.SETBACKGROUNDIMAGE_SUCCESS:
            return{...state,loading:false}
        case fromMyweddingInsight.SETBACKGROUNDIMAGE_FAILED:
            return{...state,loading:false}
        case fromMyweddingInsight.GETWEDDINGDETAILS:
            return{...state,loading:true}
        case fromMyweddingInsight.GETWEDDINGDETAILS_SUCCESS:
            return{...state,loading:false,weddingDetails:action.payload}
        case fromMyweddingInsight.GETWEDDINGDETAILS_FAILED:
            return{...state,loading:false}        
        default:
            return state;
    }
}
export const getMyWeddingReducer = (state: APPSTATE) => state;
export const SelectMyWeddingInsight = createSelector(getMyWeddingReducer, (state: any) => state.myweddingreducer ? state.myweddingreducer.myweddinginsightdata : null);
export const SelectMyWedding_PersonalInfo = createSelector(getMyWeddingReducer, (state: any) => state.myweddingreducer ? state.myweddingreducer.personalInfo : null);
export const SelectMyWedding_Presets= createSelector(getMyWeddingReducer, (state: any) => state.myweddingreducer ? state.myweddingreducer.presets : null);
export const SelectMyWeddingDetails=createSelector(getMyWeddingReducer, (state: any) => state.myweddingreducer ? state.myweddingreducer.weddingDetails : null);
