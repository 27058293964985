import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { APIService } from '../Services/api.service';
import { Observable, of } from 'rxjs';
import * as honymoonActions from '../Actions/Honeymoon.action';
import { mergeMap, catchError, map } from 'rxjs/Operators';
@Injectable()
export class HonymoonEffect {
  constructor(public action: Actions, public api: APIService) {

  }
  @Effect()
  getHonymoonDestinations$: Observable<any> =
    this.action.pipe(ofType(honymoonActions.LOADHONYMOONDESTINATION),
      mergeMap((action) => this.api.getData(this.api.objUrls.Application.HoneymoonDestinations)
        .pipe(map(res => new honymoonActions.LoadHonymoonSuccess(res),
          catchError(error => of(new honymoonActions.LoadHonymoonFailed({}))
          )))));
  @Effect()
  getHonymoonDestinationsDetails$: Observable<any> =
    this.action.pipe(ofType(honymoonActions.LOADHONYMOONDESTINATIONDETAILS),
      mergeMap((action: any) => this.api.PostData(this.api.objUrls.Application.VendorsInHonymoonTravel, action.payload)
        .pipe(map(res => new honymoonActions.LoadHonymoonDestinationDetails_Success(res),
          catchError(error => of(new honymoonActions.LoadHonymoonDestinationDetails_Failed({}))
          )))));
  @Effect()
    getHonymoonTravelServices$: Observable<any> =
    this.action.pipe(ofType(honymoonActions.LOADHONEYMOONTRAVELSERVICES),
    mergeMap((action: any) => this.api.getData(this.api.objUrls.Application.HonymoonTravelServices+action.payload)
    .pipe(map(res => new honymoonActions.LoadHonymoonTravelServices_Success(res),
    catchError(error => of(new honymoonActions.LoadHonymoonTravelServices_Failed({}))
    )))));
    @Effect()
    getHonymoonTravelVendors$: Observable<any> =
    this.action.pipe(ofType(honymoonActions.VENDORSINHONEYMOONTRAVEL),
    mergeMap((action: any) => this.api.PostData(this.api.objUrls.Application.VendorsInHonymoonTravel,action.payload)
    .pipe(map(res => new honymoonActions.VendorsInHoneymoonTravelSuccess(res),
    catchError(error => of(new honymoonActions.VendorsInHoneymoonTravelFailed(error))
    )))));


}
