import { createSelector } from '@ngrx/store';
import { stat } from 'fs';
import * as FromMySupplierActions from '../../Actions/Couple/MySupplier.action';
import { APPSTATE } from '../../HomePageState';
export const MySupplierIntiState = {
    loading: false,
    myVendorCount: [],
    myvendorcountbycategory: [],
    myvendorsByCategoryAndStatus: [],
    msg: {}
}
export function MySupplierReducer(state = MySupplierIntiState, action: FromMySupplierActions.MySuppliersActions) {
    switch (action.type) {
        case FromMySupplierActions.GETMYVENDORCOUNT:
            return { ...state, loading: true }
        case FromMySupplierActions.GETMYVENDORCOUNT_SUCCESS:
            return { ...state, myVendorCount: action.payload, loading: false }
        case FromMySupplierActions.GETMYVENDORCOUNT_FAILED:
            return { ...state, msg: action.payload }
        case FromMySupplierActions.GETMYVENDORCOUNTBYCATEGORY:
            return { ...state, loading: true }
        case FromMySupplierActions.GETMYVENDORCOUNTBYCATEGORY_SUCCESS:
            return { ...state, myvendorcountbycategory: action.payload, loading: false }
        case FromMySupplierActions.GETMYVENDORCOUNTBYCATEGORY_FAILED:
            return { ...state, msg: action.payload, loading: false }
        case FromMySupplierActions.SEARCHMYSUPPLIERS:
            return { ...state, loading: true }
        case FromMySupplierActions.SEARCHMYSUPPLIERS_SUCCESS:
            return { ...state, myvendorsByCategoryAndStatus: action.payload, loading: false }
        case FromMySupplierActions.SEARCHMYSUPPLIERS_FAILED:
            return { ...state, msg: action.payload, loading: false }
        case FromMySupplierActions.CHANGEMYSUPPLIERSTATUS:
            return { ...state, loading: true }
        case FromMySupplierActions.CHANGEMYSUPPLIERSTATUS_SUCCESS:
            return { ...state, loading: false }
        case FromMySupplierActions.CHANGEMYSUPPLIERSTATUS_FAILED:
            return { ...state, loading: false, msg: action.payload }
        case FromMySupplierActions.DELETEMYVENDOR:
            return { ...state, loading: true }
        case FromMySupplierActions.DELETEMYVENDOR_SUCCESS:
            return { ...state, loading: false }
        case FromMySupplierActions.DELETEMYVENDOR_FAILED:
            return { ...state, loading: false, msg: action.payload }
        case FromMySupplierActions.SENDNOTETOSUPPLIER:
            return{...state,loading:true}
        case FromMySupplierActions.SENDNOTETOSUPPLIER_SUCCESS:
            return{...state,loading:false,msg:action.payload}
        case FromMySupplierActions.SENDNOTETOSUPPLIER_FAILED:
            return{...state,loading:false,msg:action.payload}
        default:
            return state;

    }
}
export const GetMySupplierReducer = (state: APPSTATE) => state.mysupplierreducer;
export const SelectMyVendorCount = createSelector(GetMySupplierReducer, (state: any) => state.myVendorCount ? state.myVendorCount : null);
export const SelectMySuppliersLoadingMsg = createSelector(GetMySupplierReducer, (state: any) => state.loading ? state.loading : null);
export const SelectMyVendorCountByCategory = createSelector(GetMySupplierReducer, (state: any) => state.myvendorcountbycategory ? state.myvendorcountbycategory : null);
export const SelectMyVendorByCategoryAndStatus = createSelector(GetMySupplierReducer, (state: any) => state.myvendorsByCategoryAndStatus ? state.myvendorsByCategoryAndStatus : null);