import * as allLOcationActions from '../Actions/Location.action';
import { LocationActions } from '../Actions/Location.action';
import * as homepageaction from '../Actions/HomepageMenu.Action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
export const initialStateAllDistricts={
  loading:false,
  districts:[]
}
export function AllLocationReducer(state = initialStateAllDistricts, action: LocationActions) {
  switch (action.type) {
    case allLOcationActions.LOADALLLOCATIONS:
      return {...state,loading:true,districts:state.districts};
    case allLOcationActions.LOADALLLOCATIONSSUCCESS:
      return {...state,loading:false,districts:action.payload};
    case allLOcationActions.LOADALLLOCATIONSFAILED:
      return {state};
    default:
      return state;
  }
}
export const getAllLocationReducer=(state:APPSTATE)=>state;
export const getAllDistricts=createSelector(getAllLocationReducer,(state:any)=>state.alllocationreducer?state.alllocationreducer.districts:null);
export const initialStateCountry = {
  countries: [],
  districts: [],
  suburb: [],
  loading:false
};
export function CountriesReducer(state = initialStateCountry, action: homepageaction.HomepageMenuActions) {
  switch (action.type) {
    case homepageaction.LOADCOUNTRIES:
    return {...state,loading:true };
    case homepageaction.LOADCOUNTRY_SUCCESS:
    return {...state, countries: action.payload,loading:false };
    case homepageaction.LOADCOUNTRY_FAILED:
      return {...state,loading:false} ;
    case homepageaction.LOADDISTRICTS:
      return {...state,districts:state.countries.find(c=>c.countryId===action.countryId)?state.countries.find(c=>c.countryId===action.countryId).districts:[]}
    case homepageaction.LOADSUBURB:
      return {...state,suburb:state.districts.find(d=>d.districtId===action.DistrictId)?state.districts.find(d=>d.districtId===action.DistrictId).suburb:[]}
      default:
      return state;
  }
}
export const GetCountryState = (state: APPSTATE) => state;
export const SelectCountries = createSelector(GetCountryState, (state: any) => state.countriesreducer ? state.countriesreducer.countries :null);
export const Selectdistricts = createSelector(GetCountryState, (state: any) => state.countriesreducer ? state.countriesreducer.districts : null);
export const Selectsuburb = createSelector(GetCountryState, (state: any) => state.countriesreducer ? state.countriesreducer.suburb : null);
