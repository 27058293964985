
export const MYINSPIRATIONPHOTOS = '[Myinspiration]Myinspiration Photos';
export const MYINSPIRATIONPHOTOSSUCCESS = '[Myinspiration]Myinspiration Photos Success';
export const MYINSPIRATIONPHOTOSFAILED = '[Myinspiration]Myinspiration Photos Failed';
export const MYINSPIRATIONEVENTS = '[Myinspiration]Myinspiration Events';
export const MYINSPIRATIONEVENTSSUCCESS = '[Myinspiration]Myinspiration Events Success';
export const MYINSPIRATIONEVENTSFAILED = '[Myinspiration]Myinspiration Events Failed';
export const DELETEMYINSPIRATION = '[Myinspiration] DeleteMyInspiration';
export const DELETEMYINSPIRATION_SUCCESS = '[Myinspiration] DeleteMyInspiration Success';
export const DELETEMYINSPIRATION_FAILED = '[Myinspiration] DeleteMyInspiration Failed';
export const MYINSPIRATIONDEAL='[Myinspiration]My Inspiration Deal';
export const MYINSPIRATIONDEAL_SUCCESS='[Myinspiration]My Inspiration Deal Success';
export const MYINSPIRATIONDEAL_FAILED='[Myinspiration]My Inspiration Deal Failed';

export class MyinspirationPhotos {
    public readonly type = MYINSPIRATIONPHOTOS;
    constructor(public payload: any) {

    }
}
export class MyinspirationPhotosSuccess {
    public readonly type = MYINSPIRATIONPHOTOSSUCCESS;
    constructor(public payload: any) { }
}
export class MyinspirationPhotosFailed {
    public readonly type = MYINSPIRATIONPHOTOSFAILED;
    constructor() { }
}
export class MyinspirationEvents {
    public readonly type = MYINSPIRATIONEVENTS;
    constructor(public filter: number) { }
}
export class MyinspirationEventsSuccess {
    public readonly type = MYINSPIRATIONEVENTSSUCCESS;
    constructor(public payload: any) { }
}
export class MyinspirationEventsFailed {
    public readonly type = MYINSPIRATIONEVENTSFAILED;
    constructor() { }
}
export class DeleteMyInspiration {
    public readonly type = DELETEMYINSPIRATION;
    constructor(public payload: any) { }
}
export class DeleteMyInspirationSuccess {
    public readonly type = DELETEMYINSPIRATION_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteMyInspirationFailed {
    public readonly type = DELETEMYINSPIRATION_FAILED;
    constructor(public payload: any) { }
}
export class MyInspirationDeal {
    public readonly type = MYINSPIRATIONDEAL;
    constructor() { }
}
export class MyInspirationDealSuccess {
    public readonly type = MYINSPIRATIONDEAL_SUCCESS;
    constructor(public payload:any) { }
}
export class MyInspirationDealFailed {
    public readonly type = MYINSPIRATIONDEAL_FAILED;
    constructor(public payload:any) { }
}



export type MyinspirationActions = MyinspirationPhotos | MyinspirationPhotosSuccess | MyinspirationPhotosFailed |
    MyinspirationEvents | MyinspirationEventsSuccess | MyinspirationEventsFailed |
    DeleteMyInspiration | DeleteMyInspirationSuccess | DeleteMyInspirationFailed|MyInspirationDeal|
    MyInspirationDealSuccess|MyInspirationDealFailed;   