import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { YoutubePlayerService } from '../youtube-player.service';

@Component({
  selector: 'cs2-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.css']
})
export class YoutubePlayerComponent implements OnInit {
    @Input()videoUrl:SafeResourceUrl;
    @Input()videoLink:string;
    constructor(private youtubeService:YoutubePlayerService) {
      
     }

  ngOnInit() {
    this.videoUrl=this.youtubeService.sanitizeUrl(this.videoLink);
  }

}
