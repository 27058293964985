import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from 'projects/home/angular-material.module';
import { FormsModule, ReactiveFormsModule, FormGroupDirective } from '@angular/forms';
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { } from '@ngrx/entity'
import { NgxCaptchaModule, InvisibleReCaptchaComponent } from 'ngx-captcha';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { App_effects } from 'projects/home/home/Store/Effects/Effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { reducers } from 'projects/home/home/Store/Reducers';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { APIService } from 'projects/home/home/Store/Services/api.service';
import { PageheaderComponent } from './common/pageheader/pageheader.component';
import { LoginComponent } from './common/login/login.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { Cs2LibModule } from 'projects/cs2-lib/src/public-api';
import { ConfirmAlertComponent } from 'projects/admin/src/app/common/confirm-alert/confirm-alert.component';
import { environment } from '../environments/environment';

// const globalSettings: RecaptchaSettings = {siteKey: '6LfwZ8kZAAAAAHQqX5iquX46wxQ02oYgX28d3LnN'};
@NgModule({
  declarations: [
    AppComponent,
    PageheaderComponent,
    LoginComponent,
    ConfirmAlertComponent,

    // InvisibleReCaptchaComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    //    .withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    HammerModule,

    AngularMaterialModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot(App_effects),
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    Cs2LibModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('./browser/ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: "registerImmediately"
    }
    ),
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAZ1gsa9BUjNuL-WmCOLhelB2-jQ2jWlxo',
      libraries: ['places'],
    }),

  ],
  entryComponents: [LoginComponent, ConfirmAlertComponent],
  providers: [APIService, GoogleMapsAPIWrapper, FormGroupDirective, SwUpdate],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    
  }
}
