import { Action } from '@ngrx/store';

export const LOADEVENTS = '[Events]Load';
export const LOADEVENT_SUCCESS = '[Events]Load Event Success';
export const LOADEVENT_FAILED = '[Events] Load Event Failed';
export const LOADEVENTDETAILS = '[Events] Load Event Details';
export const LOADEVENTDETAILS_SUCCESS = '[Events] Load Event Details Success';
export class LoadEvents implements Action {
  readonly type = LOADEVENTS;
  constructor(public payload: any) {
  }
}
export class LoadEventsSuccess implements Action {
  readonly type = LOADEVENT_SUCCESS;
  constructor(public payload: any) {
  }
}
export class LoadEventsFailed implements Action {
  readonly type = LOADEVENT_FAILED;
  constructor(public payload: any) {
  }
}
export class LoadEventsDetails implements Action {
  readonly type = LOADEVENTDETAILS;
  constructor(public payload: any) {
  }
}
export class LoadEventsDetailsSuccess implements Action {
  readonly type = LOADEVENTDETAILS_SUCCESS;
  constructor(public payload: any) {
  }
}
export type EVENTACTIONS = LoadEvents | LoadEventsFailed | LoadEventsSuccess|LoadEventsDetails|LoadEventsDetailsSuccess;
