import { Action } from '@ngrx/store';
import { SearchPhotoVM } from '../Models/SearchPhotoVm';

export const LOADPHOTOS = '[PHOTOS] Load';
export const LOADPHOTOSSUCCESS = '[PHOTOS] Load Success';
export const LOADPHOTOFAILED = '[PHOTOS] Load Failed';
export const LOADPHOTOSBYALBUMID='[PHOTOS] Load Photos By AlbumId';
export const LOADPHOTOBYALBUMID_SUCCESS='[PHOTOS] Load Photos By AlbumId Success';
export const COMPLETE_PROCESS='[PHOTOS] Complete Loader';
export class LoadPhotos implements Action {
  readonly type = LOADPHOTOS;
  constructor(public payload: SearchPhotoVM) { }
}
export class LoadPhotosSuccess implements Action {
  readonly type = LOADPHOTOSSUCCESS;
  constructor(public payload: any) { }
}
export class LoadPhotosFailed implements Action {
  readonly type = LOADPHOTOFAILED;
  constructor() { }
}
export class LoadPhotosByAlbum{
  readonly type=LOADPHOTOSBYALBUMID;
  constructor(public payload:any){
  }
}
export class LoadPhotoByAlbumSuccess{
  readonly type=LOADPHOTOBYALBUMID_SUCCESS;
  constructor(public payload:number){}
}
export class CompleteProcess{
  readonly type=COMPLETE_PROCESS;
  constructor(){

  }
}
export type PhotoActions = LoadPhotos | LoadPhotosSuccess|LoadPhotosByAlbum|LoadPhotoByAlbumSuccess | LoadPhotosFailed|CompleteProcess;
