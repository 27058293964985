
export const MYTODOADDUPDATE='[Checklist]Add Update Todo';
export const MYTODOADDUPDATESUCCESS='[Checklist]Add Todo Success';
export const MYTODOADDUPDATEFAILED='[Checklist]Add Todo Failed';
export const MYTODOGET='[Checklist]Add Todo Get';
export const MYTODOGETSUCCESS='[Checklist]Add Todo Get Success';
export const MYTODOGETFAILED='[Checklist]Add Todo Get Failed';
export const MYTODOBYTIME='[Checklist]Checklist By Time';
export const MYTODOBYTIMESUCCESS='[Checklist]Checklist By Time Success';
export const MYTODOBYTIMEFAILED='[Checklist]Checklist By Time Failed';
export const MYTODOBYCATEGORY='[Checklist]Checklist By CATEGORY';
export const MYTODOBYCATEGORYSUCCESS='[Checklist]Checklist By CATEGORY Success';
export const MYTODOBYCATEGORYFAILED='[Checklist]Checklist By CATEGORY Failed';
export const REMOVETODO='[Checklist]Checklist Remove';
export const REMOVETODOSUCCESS='[Checklist]Checklist Remove Success';
export const REMOVETODOFAILED='[Checklist]Checklist Remove Failed';
export class CreateUpdateToDo{
    public readonly type=MYTODOADDUPDATE;
    constructor(public payload:any){
    }
}
export class CreateUpdateToDoSuccess{
    public readonly type=MYTODOADDUPDATESUCCESS;
    constructor(public payload:any){

    }
}
export class CreateUpdateToDoFailed{
    public readonly type=MYTODOADDUPDATEFAILED;
    constructor(){
    }
}
export class GetToDo{
    public readonly type=MYTODOGET;
    constructor(public payload:any){
    }
}
export class GetToDoSuccess{
    public readonly type=MYTODOGETSUCCESS;
    constructor(public payload:any){
    }
}
export class GetToDoFailed{
    public readonly type=MYTODOGETFAILED;
    constructor(){
    }
}
export class GetToDoByTime{
    public readonly type=MYTODOBYTIME;
    constructor(){
    }
}
export class GetToDoByTimeSuccess{
    public readonly type=MYTODOBYTIMESUCCESS;
    constructor(public payload:any){
    }
}
export class GetToDoByTimeFailed{
    public readonly type=MYTODOBYTIMEFAILED;
    constructor(){
    }
}
export class GetToDoByCategory{
    public readonly type=MYTODOBYCATEGORY;
}
export class GetToDoByCategorySuccess{
    public readonly type=MYTODOBYCATEGORYSUCCESS;
    constructor(public payload:any){}
}
export class GetToDoByCategoryFailed{
    public readonly type=MYTODOBYCATEGORYFAILED;
    constructor(public payload:any){}
}
export class RemoveToDo{
    public readonly type=REMOVETODO;
    constructor(public id:number){
    }
}
export class RemoveToDoSuccess{
    public readonly type=REMOVETODOSUCCESS;
    constructor(public id:number){
    }
}
export class RemoveToDoFailed{
    public readonly type=REMOVETODOFAILED;
    constructor(public id:number){
    }
}
export type MyChecklistActions=CreateUpdateToDo|CreateUpdateToDoSuccess|CreateUpdateToDoFailed|
                                GetToDo|GetToDoSuccess|GetToDoFailed|
                                GetToDoByTime|GetToDoByTimeSuccess|GetToDoByTimeFailed|
                                GetToDoByCategory|GetToDoByCategorySuccess|GetToDoByCategoryFailed|
                                RemoveToDo|RemoveToDoSuccess|RemoveToDoFailed