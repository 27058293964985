import { CategoryActions } from '../Actions/Category.action';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
import * as fromCatAction from '../Actions/Category.action';
export const InitialCategoryState = {
  loading: false,
  categories: [],
  vendorByCategory: [],
  popularCategories:[]
};

export function CategoryReducer(state = InitialCategoryState, action: CategoryActions) {
  switch (action.type) {
    case fromCatAction.LOADCATEGORY:
      return {
        ...state, loading: false};
    case fromCatAction.LOADCATEGORYFAIL:
      return state;
    case fromCatAction.LOADCATEGORYSUCCESS:
      return {...state, loading: false,categories: action.payload,
        popularCategories:action.payload.filter(c=>c.ispopular=='true')
    };
    case fromCatAction.LOADCATEGORIES_WITHSERVICES:
        return{...state,loading:true}
    case fromCatAction.LOADCATEGORIES_WITHSERVICES_SUCCESS:
        
        return{...state,loading:false,categories:action.payload}
    case fromCatAction.LOADCATEGORIES_WITHSERVICES_FAILED:
        return{...state,loading:false}
    case fromCatAction.LOADVENDORBYCATEGORY:
      return { ...state, loading: false};
    case fromCatAction.LOADVENDORBYCATEGORYSUCCESS:
      return { ...state, loading: false, vendorByCategory: action.payload };
    default:
      return state ;
  }
}
export const getCategorReducer = (state: APPSTATE) => state;
export const SelectCategories = createSelector(getCategorReducer, (state: any) => state.categoryreducer ? state.categoryreducer.categories : null);
export const SelectPopularCategories = createSelector(getCategorReducer, (state: any) => state.categoryreducer ? state.categoryreducer.popularCategories : null);


