import { Injectable, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, interval, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Cs2LibDataService, bannerHeights, ViewPorts, DBActions, BannerSize, FileType } from './cs2-lib-dataservice';
import { Location } from '@angular/common';
import { ValidationErrors, AbstractControl, FormGroup } from '@angular/forms';
import { catchError } from 'rxjs/Operators';
import { Meta, Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Cs2SocialService } from './cs2-social.service';

@Injectable({
  providedIn: 'root'
})
export class Cs2LibService {
  mobileView = '(max-width: 599px)';
  httpOptions: {};
  selectedFile: File;
  selectedFiles:Array<File>;
  selectedFileBase64: any;
  selectedFilesBase64=[];
  CurrentViewPort: ViewPorts;
  SelectedFileHeight = 0;
  SelectedFileWidth = 0;
  enumDBActions = DBActions;
  bannerType=0;
  shareUrl = 'http://13.127.210.245:81/home/weddingvendor/';
  public blankImg = 'https://s3.us-east-2.amazonaws.com/prefect-image/wed.jpeg';
  public noImage = 'https://s3.us-east-2.amazonaws.com/prefect-image/store_noimg.jpg';
  public noImg = './assets/img/noImg.png';
  public noProfileImg = './assets/img/NoProfileImg.jpg';
  public rootBannerImg='https://perfect-images.s3.ap-south-1.amazonaws.com/PerfectWeddings.MuRootImg.jpg';
  contentCoppied = false;
  constructor(public breakpoint: BreakpointObserver, public client: HttpClient, public router: Router,public cs2social:Cs2SocialService,
    public dataservice: Cs2LibDataService, public locationserice: Location, public meta: Meta, public title: Title) {
      this.selectedFiles=new Array<File>();
      this.detectBreakpoint();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //  Authorization: 'Bearer ' + localStorage.getItem('authtoken'),
      }),

    };
  }
  responsiveFlex(): string {
    const response = this.breakpoint.isMatched(this.mobileView);
    return response ? 'column' : 'row';
  }
  setNavDrawerMode() {
    this.breakpoint.isMatched(this.mobileView) ? 'over' : 'side';
    if (this.breakpoint.isMatched(this.mobileView))
      return { Drawermode: 'over', DrawerOpened: false, Layout: 'column' }
    return { Drawermode: 'side', DrawerOpened: true, Layout: 'row' }

  }
  setInnerContentSize(size) {
    const response = this.breakpoint.isMatched(this.mobileView);
    return response ? 100 + '%' : size + '%'
  }
  setInnerContentSize2(size, size2) {
    const response = this.breakpoint.isMatched(this.mobileView);
    return response ? size + '%' : size2 + '%'
  }
  setCss() {
    const response = this.breakpoint.isMatched(this.mobileView);
    return response ? 'mobileView' : 'desktopView';
  }
  setSideNavCss() {
    const response = this.breakpoint.isMatched(this.mobileView);
    return response ? 'mobileSideNav' : 'desktopSideNav';
  }
  setClass(MobileCssClass, DesktopCssClass) {
    const response = this.breakpoint.isMatched(this.mobileView);
    return response ? MobileCssClass : DesktopCssClass;
  }
  getData(url): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json', Authorization: 'Bearer ' + localStorage.getItem('authtoken'),
      }),
    };
    var obser = this.client.get(url, this.httpOptions);
    return this.client.get(url, this.httpOptions).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status == 401) {
        localStorage.clear();
        switch(window.location.host){
          case 'localhost:4201':
            if(this.bannerType==1){
              window.location.href='https://pw.internext.mu';
              }
            break;
          case 'vendor.internext.mu':
            if(this.bannerType==1){
            window.location.href='https://pw.internext.mu';
            }
            break;
          case 'localhost:4200':
            this.goto('/home');
            break;
          case 'pw.internext.mu':
            this.goto('/home');
          break;
          case 'admin.internext.mu':
            window.location.href='https://pw.internext.mu';
            break;
            case 'localhost:4203':
              window.location.href='http://localhost:4200';
              break;
        }
        return err.error;
      }
      return obser;
    }));
  }
  PostData(url, body): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json', Authorization: 'Bearer ' + localStorage.getItem('authtoken'),
      }),
    };
    return this.client.post(url,
      body, this.httpOptions).pipe(catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          // localStorage.clear();
          this.goto("/home");
        }
        return err.error;
      }));
  }
  postImageData(url, reqObj): Observable<any> {
    const authToken = localStorage.getItem('authtoken');
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + authToken,
      }),
    };
    return this.client.post(url, reqObj, httpOptions);
  }
  DeleteData(url, obj): Observable<any> {
    const authToken = localStorage.getItem('authtoken');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: 'Bearer ' + authToken,
      }),
    };
    return this.client.delete(url, httpOptions);
  }
  deleteAction2(url, data) {
    const authToken = localStorage.getItem('authtoken');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      }),
      body: data,
    };
    return this.client.delete(url, options);
  }
  PutData(url, body): Observable<any> {
    return this.client.put(url, body);
  }
  returnJsonDate(DashedDate) {
    const date = {
      // tslint:disable-next-line: radix
      year: parseInt(DashedDate.split('-')[0]),
      // tslint:disable-next-line: radix
      month: parseInt(DashedDate.split('-')[1]),
      // tslint:disable-next-line: radix
      day: parseInt(DashedDate.split('-')[2]),
    };
    return date;
  }
  dateTFormatToJson(TformatedDate) {
    const date = {
      // tslint:disable-next-line: radix
      year: parseInt(TformatedDate.split('-')[0]),
      // tslint:disable-next-line: radix
      month: parseInt(TformatedDate.split('-')[1]),
      // tslint:disable-next-line: radix
      day: parseInt(TformatedDate.split('-')[2]),
    };
    return date;
  }
  dateJsonTodashed(format) {
    const date = format.year + '-' + format.month + '-' + format.day;
    return date;
  }
  am_pm_to_hours(time) {
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === 'pm' && hours < 12) {
      hours = hours + 12;
    }
    if (AMPM === 'am' && hours === 12) {
      hours = hours - 12;
    }
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) {
      sHours = '0' + sHours;
    }
    if (minutes < 10) {
      sMinutes = '0' + sMinutes;
    }
    return sHours + ':' + sMinutes;
  }
  removeWhiteSpace(value): number {
    if (value === 'undefined' || value === null || value === undefined) {
      return 0;
    }
    return value.replace(/\s/g, '');
  }
  isValidNumber(value) {
    const regex = /^[0-9]+$/;
    return value.match(regex);
  }
  isValidDate(dt){
    var reGoodDate = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
    return reGoodDate.test(dt);
}
// isValidURL(str) {
//   var a  = document.createElement('a');
//   a.href = str;
//   return (a.host && a.host != window.location.host);
// }
  clearSession() {
    sessionStorage.clear();
    localStorage.clear();
    // this.ObjRouter.navigateByUrl('/home');
  }
  countLinesInString(str) {
    return str.split(/\r\n|\r|\n/).length;
  }
  public matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null : { isMatching: false };
    };
  }
  getCsvOptions(columnsArray: Array<string>, title: string): any {
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title,
      useBom: true,
      noDownload: false,
      headers: columnsArray,
    };
    return csvOptions;
  }
  getLast_N_Years(Value): Array<any> {
    const yearsList = [];
    const curYear = new Date().getFullYear() - Value;
    for (let i = curYear + 1; i <= curYear + Value; i++) {
      yearsList.push(i);
    }
    return yearsList;
  }
  noDataHTML(data): string {
    if (data === undefined || data.length === 0) {
      return '<span class="noData"><i class="material-icons">close</i>No Data Found</span>';
    }
    return '';
  }
  isValidUrl(StringValue) {
    const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    return matcher.test(StringValue);
  }
  goback() {
    this.locationserice.back();
  }
  goto(url) {
    this.router.navigateByUrl(url);
  }
  gotoWithParam(url, param) {

    this.router.navigate([url, param]);
  }
  getBase64(event): any {
    let me = this;
    this.selectedFileBase64
    this.selectedFile = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (e) => {
      this.selectedFileBase64 = reader.result;
      this.selectedFilesBase64.push(this.selectedFileBase64);
      this.selectedFiles.push(this.selectedFile);
      var img = new Image();
      img.onload = (ev: Event) => {
        this.SelectedFileHeight = img.height;
        this.SelectedFileWidth = img.width;
      }

    };
    reader.onerror = function (error) {

    };
    return this.selectedFileBase64;
  }
  clearBase64() {
    this.selectedFileBase64 = null;
  }
  detectBreakpoint() {
    let deviceType = '';
    this.breakpoint.observe([Breakpoints.HandsetPortrait]).subscribe(result => {
      if (result.matches) {
        deviceType = 'HandsetPortrait';
        this.CurrentViewPort = ViewPorts.Mobile;
        this.bannerType=2;
      }
    });
    this.breakpoint.observe(Breakpoints.HandsetLandscape).subscribe(result => {
      if (result.matches) {
        deviceType = 'HandsetLandscape';
        this.CurrentViewPort = ViewPorts.MobileLandScape;
        this.bannerType=2;
      }
    });
    // this.breakpoint.observe(Breakpoints.Tablet).subscribe(result => {
    //   if (result.matches) {
    //     deviceType='Tablet';

    //   }
    // });
    this.breakpoint.observe(Breakpoints.TabletLandscape).subscribe(result => {
      if (result.matches) {
        deviceType = 'Tablet Landscape';
        this.CurrentViewPort = ViewPorts.Desktop;
        this.bannerType=3;
      }
    });
    this.breakpoint.observe(Breakpoints.TabletPortrait).subscribe(result => {
      if (result.matches) {
        deviceType = 'Tablet Portrait';
        this.CurrentViewPort = ViewPorts.Mobile;
        this.bannerType=3;
      }
    });
    this.breakpoint.observe(Breakpoints.Web).subscribe(result => {
      if (result.matches) {
        deviceType = 'web';
        this.bannerType=1;
      }
    });
    this.breakpoint.observe(Breakpoints.WebLandscape).subscribe(result => {
      if (result.matches) {
        deviceType = 'web Landscape';
        this.CurrentViewPort = ViewPorts.Desktop;
        this.bannerType=1;
      }
    });
    this.breakpoint.observe(Breakpoints.WebPortrait).subscribe(result => {
      if (result.matches) {
        deviceType = 'web Portrait';
        this.CurrentViewPort = ViewPorts.Desktop;
        this.bannerType=1;
      }
    });
    this.breakpoint.observe(Breakpoints.XLarge).subscribe(result => {
      if (result.matches) {
        this.CurrentViewPort = ViewPorts.XlScreen;
        deviceType = 'XLarge';
      }
    });
    // this.breakpoint.observe(Breakpoints.XSmall).subscribe(result => {
    //   if (result.matches) {
    //     deviceType='XSmall';

    //   }
    // });
    // this.breakpoint.observe(Breakpoints.Large).subscribe(result => {
    //   if (result.matches) {
    //     deviceType='Large';

    //   }
    // });
    // this.breakpoint.observe(Breakpoints.Medium).subscribe(result => {
    //   if (result.matches) {
    //     deviceType='Medium';

    //   }
    // });
    // this.breakpoint.observe(Breakpoints.Small).subscribe(result => {
    //   if (result.matches) {
    //     deviceType='Small';

    //   }
    // });
   
  }
  lazyloadimgs(imgClassName) {
    var images = document.getElementsByClassName(imgClassName);

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((element: any) => {
        if (element.isIntersecting) {
          const image = element.target;
          element.target.src = element.target.dataset.path;
          observer.unobserve(image);
        }
      });
    }, { rootMargin: '50px 20px 75px 30px', threshold: [0, 0.25, 0.75, 1] });
    setTimeout(() => {
      for (var i = 0; i < images.length; i++) {
        observer.observe(images[i]);
      }
    }, 3000);
  }

  AddPageBreak(page: number) {
    page == 0 ? page = 1 : page += 1;

    return "<div class='pageSeperator'> Page " + `${page}` + " </div>";
  }
  
  InsertPageBreakElement(item: any, pageNumber) {
    this.setCss() === "mobileView" ? item.push({ id: 0, pageNumber: pageNumber }) : null;
  }

  selectRating(index) {
    return index + 1
  }
  countdownTimer() {
    const curDate = new Date();
    const tenMinLater = curDate.setMinutes(curDate.getMinutes() + 1);
    let counter = interval(1000).subscribe(x => {
      const diff = new Date(new Date(tenMinLater).getTime() - new Date().getTime()).toISOString();
      let countdownTime = diff.split('T')[1].split('.')[0];
      if (countdownTime === '00:00:00') {
        counter.unsubscribe();
        // this.ticketPopup = false;
      }
    });
  }
  CopyToClipBoard(value) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = value;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    this.contentCoppied = true;
    setTimeout(() => {
      this.contentCoppied = false;
    }, 1000);
  }
  IsScrollAtBottom(){
    let pos =Math.ceil((document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight);
    let max = document.documentElement.scrollHeight;
    return pos== max?true:false 
  }
  matchConfirmPassword(strCtrl1: string, strCtrl2: string) {
    return (formgroup: FormGroup) => {
      let ctrl1 = formgroup.controls[strCtrl1];
      let ctrl2 = formgroup.controls[strCtrl2];
      if (ctrl1.value !== ctrl2.value) {
        ctrl2.setErrors({ notMatch: true });
      } else {
        ctrl2.setErrors(null);
      }
    }
  }
  matchValueIsLessThan(controlName:string,matchingControlName:string){
    return(formGroup:FormGroup)=>{
      const control1=formGroup.controls[controlName];
      const control2=formGroup.controls[matchingControlName];
      if(control2.errors && !control2.errors.shouldless){
        return;
      }
      if(parseInt(control2.value)>parseInt(control1.value)){
        control2.setErrors({shouldless:true});
      }else{
        control2.setErrors(null);
      }
    }
  }

  validateToken(err) {
    if (err.status == 401) {
      window.location.port == '81' ? this.goto("/home") : window.location.href = 'http://13.127.210.245:81/';
      return err.error;
    }
  }
  setLoginTimer(minutes: number): number {
    let timer = new Date().setMinutes(minutes);
    return timer;
  }
  // @HostListener('window:scroll', ['$event']) // for window scroll events
  stickynav(elementId) {
    var header = document.getElementById(elementId);

    if (header !== null) {
      var sticky = header.offsetTop;
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    }
  }
  
  async updateMetaTags(imagepath, title, description,url?) {
  
    this.title.setTitle(title);
   await this.meta.updateTag({ name: "twitter:description", content: description });
   await this.meta.updateTag({ name: "twitter:title", content: title });
   await this.meta.updateTag({ name: "twitter:image", content: imagepath });
   await this.meta.updateTag({name:'twitter:url',content:url});

   await this.meta.updateTag({ name: "description", content: description });
   await this.meta.updateTag({ name: "title", content: title });
   await    this.meta.updateTag({ name: "image", content: imagepath });
   await this.meta.updateTag({name:'url',content:url})
    
   await    this.meta.updateTag({ name: "og:title",property:'og:title', content: title });
   await this.meta.updateTag({ name: "og:description",property:'og:description', content: description });
   await this.meta.updateTag({ name: "og:image",property:'og:image', content: imagepath });
   await this.meta.updateTag({name:'og:url',property:'og:url',content:url});
   console.log(title,description,imagepath,url);
  }
  expandCollaspLines(ev: any) {
    ev.target.className == 'module line-clamp' ? ev.target.className = '' :
      ev.target.className = 'module line-clamp';
  }
  setBannerHeight(): string {
    return bannerHeights.find(x => x.viewport == this.CurrentViewPort)?bannerHeights.find(x => x.viewport == this.CurrentViewPort).height:'350px';
  }
  discountList():Array<any>{
    var discounts=[];
    var id=0
    for (let index = 5; index <= 100; index+=5) {
      id+=1;
      discounts.push({id:id,value:index});
    }
    return discounts;
  }
  NoTabPagination() {
    document.getElementsByClassName('mat-tab-header-pagination-before')[0].remove();
    document.getElementsByClassName('mat-tab-header-pagination-after')[0].remove();
  }
  fullScreenDialog() {
    (document.querySelector('.mat-dialog-container') as HTMLElement).style.padding = '0px';
  }
  ConvertToFileTypeEnum(fileType) {
    switch (fileType) {
      case 'image':
        return FileType.Image
      case 'audio':
        return FileType.Audio;
      case 'video':
        return FileType.Video
    }
  }
 
  playVideo(video,container:string,autoplay:boolean){
    if(document.getElementById(container).children[0]){
    document.getElementById(container).children[0].remove();
    }
    var videoElament = document.createElement('video');
    videoElament.src = video.link;
    videoElament.autoplay = autoplay;
    videoElament.controls=true;
    videoElament.setAttribute('controlsList','nodownload');
    videoElament.poster=video.thumbnail;
    document.getElementById(container).appendChild(videoElament);
  }
  removeBase64File(index:number){
    this.selectedFilesBase64.splice(index,1);
    this.selectedFiles.splice(index,1);
  }
}
export enum UserType {
  Vendor = 1,
  User = 2,
}
