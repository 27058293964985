
export const ADDUPDATEGROUP = '[Guestlist] Add Update Grooup';
export const ADDUPDATEGROUPSUCCESS = '[Guestlist] Add Update Grooup Success';
export const ADDUPDATEGROUPFAILED = '[Guestlist] Add Update Grooup Failed';
export const GETGROUP = '[Guestlist] Get Grooup';
export const GETGROUPSUCCESS = '[Guestlist] Get Grooup Success';
export const GETGROUPFAILED = '[Guestlist] Get Grooup Failed';
export const ADDUPDATEMENU = '[Guestlist] Add Update Menu';
export const ADDUPDATEMENUSUCCESS = '[Guestlist] Add Update Menu Success';
export const ADDUPDATEMENUFAILED = '[Guestlist] Add Update Menu Failed';
export const GETMENU = '[Guestlist] Get Menu';
export const GETMENUSUCCESS = '[Guestlist] Get Menu Success';
export const GETMENUFAILED = '[Guestlist] Get Menu Failed';
export const ADDUPDATEGUEST = '[Guestlist] Add Update Guest';
export const ADDUPDATEGUESTSUCCESS = '[Guestlist] Add Update Guest Success';
export const ADDUPDATEGUESTFAILED = '[Guestlist] Add Update Guest Failed';
export const GETGUESTLIST = '[Guestlist] Get Guest List';
export const GETGUESTLISTSUCCESS = '[Guestlist] Get Guest List Success';
export const GETGUESTLISTFAILED = '[Guestlist] Get Guest List Failed';
export const GETGUESTCOUNTBYMENU = '[Guestlist] Get Guest Count By Menu';
export const GETGUESTCOUNTBYMENUSUCCESS = '[Guestlist] Get Guest Count By Menu Success';
export const GETGUESTCOUNTBYMENUFAILED = '[Guestlist] Get Guest Count By Menu Failed';
export const GETGUESTCOUNTBYGROUP = '[Guestlist] Get Guest Count By Group';
export const GETGUESTCOUNTBYGROUPSUCCESS = '[Guestlist] Get Guest Count By Group Success';
export const GETGUESTCOUNTBYGROUPFAILED = '[Guestlist] Get Guest Count By Group Failed';
export const GETGUESTCOUNTBYAGEGROUP = '[Guestlist] Get Guest Count By AgeGroup';
export const GETGUESTCOUNTBYAGEGROUPSUCCESS = '[Guestlist] Get Guest Count By AgeGroup Success';
export const GETGUESTCOUNTBYAGEGROUPFAILED = '[Guestlist] Get Guest Count By AgeGroup Failed';
export const SEARCHMYGUEST = '[Guestlist] Search My Guest';
export const SEARCHMYGUESTSUCCESS = '[Guestlist] Search My Guest Success';
export const SEARCHMYGUESTFAILED = '[Guestlist] Search My Guest Failed';
export const UPDATEINVITATION = '[Guestlist] Update Guest Invitation';
export const UPDATEINVITATIONSUCCESS = '[Guestlist] Update Guest Invitation Success';
export const UPDATEINVITATIONFAILED = '[Guestlist] Update Guest Invitation Failed';
export const MYINVITATIONCOUNT = '[Guestlist] MyInvitation Count';
export const MYINVITATIONCOUNTSUCCESS = '[Guestlist] MyInvitation Count Success';
export const MYINVITATIONCOUNTFAILED = '[Guestlist] MyInvitation Count Failed';
export const REMOVEGUEST = '[Guestlist] Remove Guest';
export const REMOVEGUEST_SUCCESS = '[Guestlist] Remove Guest Success';
export const REMOVEGUEST_FAILED = '[Guestlist] Remove Guest Failed';
export const REMOVE_GROUP='[Guestlist]Remove Group';
export const REMOVE_GROUP_SUCCESS='[Guestlist]Remove Group Success';
export const REMOVE_GROUP_FAILED='[Guestlist]Remove Group Failed';
export const REMOVE_MENU='[Guestlist]Remove Menu';
export const REMOVE_MENU_SUCCESS='[Guestlist]Remove Menu Success';
export const REMOVE_MENU_FAILED='[Guestlist]Remove Menu Failed'
export class AddUpdateGrooup {
    public readonly type = ADDUPDATEGROUP;
    constructor(public payload: any) {
    }
}
export class AddUpdateGrooupSuccess {
    public readonly type = ADDUPDATEGROUPSUCCESS;
    constructor(public payload: any) {
    }
}
export class AddUpdateGrooupFailed {
    public readonly type = ADDUPDATEGROUPFAILED;
    constructor(public payload: any) {
    }
}
export class GetGroup {
    public readonly type = GETGROUP;
    constructor(public payload: any) { }
}
export class GetGroupSuccess {
    public readonly type = GETGROUPSUCCESS;
    constructor(public payload: any) { }
}
export class GetGroupFailed {
    public readonly type = GETGROUPFAILED
    constructor(public payload: any) { }
}
export class AddUpdateMenu {
    public readonly type = ADDUPDATEMENU;
    constructor(public payload: any) {
    }
}

export class AddUpdateMenuSuccess {
    public readonly type = ADDUPDATEMENUSUCCESS;
    constructor(public payload: any) {
    }
}
export class AddUpdateMenuFailed {
    public readonly type = ADDUPDATEMENUFAILED;
    constructor(public payload: any) {
    }
}
export class GetMenu {
    public readonly type = GETMENU;
    constructor(public payload: any) { }
}
export class GetMenuSuccess {
    public readonly type = GETMENUSUCCESS;
    constructor(public payload: any) { }
}
export class GetMenuFailed {
    public readonly type = GETMENUFAILED;
    constructor(public payload: any) { }
}
export class GetGuestList {
    public readonly type = GETGUESTLIST;
    constructor(public payload: any) { }
}
export class GetGuestListSuccess {
    public readonly type = GETGUESTLISTSUCCESS;
    constructor(public payload: any) { }
}
export class GetGuestListFailed {
    public readonly type = GETGUESTLISTFAILED;
    constructor(public payload: any) { }
}
export class AddUpdateGuest {
    public readonly type = ADDUPDATEGUEST;
    constructor(public payload: any) { }
}
export class AddUpdateGuestSuccess {
    public readonly type = ADDUPDATEGUESTSUCCESS;
    constructor(public payload: any) { }
}
export class AddUpdateGuestFailed {
    public readonly type = ADDUPDATEGUESTFAILED;
    constructor(public payload: any) { }
}
export class GetGuestCountByMenu {
    public readonly type = GETGUESTCOUNTBYMENU;
    constructor(public payload: any) { }
}
export class GetGuestCountByMenuSuccess {
    public readonly type = GETGUESTCOUNTBYMENUSUCCESS;
    constructor(public payload: any) { }
}
export class GetGuestCountByMenuFailed {
    public readonly type = GETGUESTCOUNTBYMENUFAILED;
    constructor(public payload: any) { }
}
export class GetGuestCountByGroup {
    public readonly type = GETGUESTCOUNTBYGROUP;
    constructor(public payload: any) { }
}
export class GetGuestCountByGroupSuccess {
    public readonly type = GETGUESTCOUNTBYGROUPSUCCESS;
    constructor(public payload: any) { }
}
export class GetGuestCountByGroupFailed {
    public readonly type = GETGUESTCOUNTBYGROUPFAILED;
    constructor(public payload: any) { }
}
export class GetGuestCountByAgeGroup {
    public type = GETGUESTCOUNTBYAGEGROUP;
    constructor(public payload: any) { }
}
export class GetGuestCountByAgeGroupSuccess {
    public type = GETGUESTCOUNTBYAGEGROUPSUCCESS;
    constructor(public payload: any) { }
}
export class GetGuestCountByAgeGroupFailed {
    public type = GETGUESTCOUNTBYAGEGROUPFAILED;
    constructor(public payload: any) { }
}
export class SearchMyGuest {
    public readonly type = SEARCHMYGUEST;
    constructor(public payload: any) {
    }
}
export class SearchMyGuestSuccess {
    public readonly type = SEARCHMYGUESTSUCCESS;
    constructor(public payload: any) { }
}
export class SearchMyGuestFailed {
    public readonly type = SEARCHMYGUESTFAILED;
    constructor(public payload: any) { }
}
export class UpdateGuestInvitation {
    public readonly type = UPDATEINVITATION;
    constructor(public payload: any) { }
}
export class UpdateGuestInvitationSuccess {
    public readonly type = UPDATEINVITATIONSUCCESS;
    constructor(public payload: any) { }
}
export class UpdateGuestInvitationFailed {
    public readonly type = UPDATEINVITATIONFAILED;
    constructor(public payload: any) { }
}
export class MyInvitationCount {
    public readonly type = MYINVITATIONCOUNT;
    constructor(public payload: any) { }
}
export class MyInvitationCountSuccess {
    public readonly type = MYINVITATIONCOUNTSUCCESS;
    constructor(public payload: any) { }
}
export class MyInvitationCountFailed {
    public readonly type = MYINVITATIONCOUNTFAILED;
    constructor(public payload: any) { }
}
export class RemoveGuest {
    public readonly type = REMOVEGUEST;
    constructor(public payload: any) { }
}
export class RemoveGuestSuccess {
    public readonly type = REMOVEGUEST_SUCCESS;
    constructor(public payload: any) { }
}
export class RemoveGuestFailed {
    public readonly type = REMOVEGUEST_FAILED;
    constructor(public payload: any) { }
}
export class RemoveGroup{
    public readonly type=REMOVE_GROUP;
    constructor(public payload:any){}
}
export class RemoveGroupSuccess{
    public readonly type=REMOVE_GROUP_SUCCESS;
    constructor(public payload:any){}
}
export class RemoveGroupFailed{
    public readonly type=REMOVE_GROUP_FAILED;
    constructor(public payload:any){}
}
export class RemoveMenu{
    public readonly type=REMOVE_MENU;
    constructor(public menuId:number){}
}
export class RemoveMenuSuccess{
    public readonly type=REMOVE_MENU_SUCCESS;
    constructor(public payload:any){}
}
export class RemoveMenuFailed{
    public readonly type=REMOVE_MENU_FAILED;
    constructor(public payload:any){}
}
export type GuestActions = AddUpdateGrooup | AddUpdateGrooupSuccess |
    AddUpdateGrooupFailed | GetGroup | GetGroupSuccess | GetGroupFailed |
    AddUpdateMenu | AddUpdateMenuSuccess | AddUpdateMenuFailed | GetMenu |
    GetMenuSuccess | GetMenuFailed | GetGuestList | GetGuestListSuccess |
    GetGuestListFailed | AddUpdateGuest | AddUpdateGuestSuccess | AddUpdateGuestFailed |
    GetGuestCountByMenu | GetGuestCountByMenuSuccess | GetGuestCountByMenuFailed |
    GetGuestCountByGroupSuccess | GetGuestCountByGroup | GetGuestCountByGroupFailed |
    GetGuestCountByAgeGroup | GetGuestCountByAgeGroupFailed | GetGuestCountByAgeGroupSuccess |
    SearchMyGuest | SearchMyGuestSuccess | SearchMyGuestFailed | UpdateGuestInvitation |
    UpdateGuestInvitationFailed | UpdateGuestInvitationSuccess | MyInvitationCount |
    MyInvitationCountFailed | MyInvitationCountSuccess | RemoveGuest | RemoveGuestSuccess |
    RemoveGuestFailed|RemoveGroup|RemoveGroupSuccess|RemoveGroupFailed|RemoveMenu|
    RemoveMenuSuccess|RemoveMenuFailed;