import { Injectable } from '@angular/core';
import * as fromMyweddingActions from '../../Actions/Couple/MyWeddingInsight.action';
import * as urls from '../../../../home/Store/Services/ServiceUrls';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, map } from 'rxjs/Operators';
@Injectable()
export class MyWeddingEffect {
    constructor(public cs2: Cs2LibService, public action: Actions) {

    }
    @Effect()
    getMyWeddingInsight$: Observable<any> = this.action.pipe(ofType(fromMyweddingActions.MYWEDDINGINSIGHT),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.WeddingInsight.MyweddingInsight)
            .pipe(map((res) => new fromMyweddingActions.MyweddingInsightSuccess(res),
                catchError(err => of(new fromMyweddingActions.MyweddingInsightFailed(err)))
            ))));
    @Effect()
    uploadFile$: Observable<any> = this.action.pipe(ofType(fromMyweddingActions.MYWEDDINGUPLOADFILE),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.WeddingInsight.FileUploader, action.payload)
            .pipe(map((res) => new fromMyweddingActions.UploadFileSuccess(res),
                catchError(err => of(new fromMyweddingActions.UploadFileFailed(err)))
            ))));
    @Effect()
    getMywedding_PersonalDetails$: Observable<any> = this.action.pipe(ofType(fromMyweddingActions.MYWEDDINGPERSONALDETAILS),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.WeddingInsight.MyWeddingPersonalInfo)
            .pipe(map((res) => new fromMyweddingActions.MyWeddingPersonalDetailsSuccess(res),
                catchError(err => of(new fromMyweddingActions.MyWeddingPersonalDetailsFailed(err)))
            ))));
    /// 
    @Effect()
    getMywedding_BackgroundPreset$: Observable<any> = this.action.pipe(ofType(fromMyweddingActions.GETBACKGROUNDPRESET),
    mergeMap((action: any) => this.cs2.getData(urls.UrlModel.Common.GetBackgroundPreset+action.payload)
        .pipe(map((res) => new fromMyweddingActions.GetBackgroundPresetSuccess(res),
            catchError(err => of(new fromMyweddingActions.GetBackgroundPresetFailed(err)))
        ))));
        @Effect()
        Set_BackgroundPreset$: Observable<any> = this.action.pipe(ofType(fromMyweddingActions.SETBACKGROUNDIMAGE),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.Common.SetBackgroundImage,action.payload)
            .pipe(map((res) => new fromMyweddingActions.SetBackgroundImageSuccess(res),
                catchError(err => of(new fromMyweddingActions.SetBackgroundImageFailed(err)))
        ))));
        @Effect()
        Get_WeddingDetails$: Observable<any> = this.action.pipe(ofType(fromMyweddingActions.GETWEDDINGDETAILS),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.WeddingInsight.GetMyWeddingDetails)
            .pipe(map((res) => new fromMyweddingActions.GetWeddingDetailsSuccess(res),
                catchError(err => of(new fromMyweddingActions.GetWeddingDetailsFailed(err)))
        ))));

}