import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

import {
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatBadgeModule,
  MatTabsModule,
  MatSidenavModule,
  MatListModule,
  MatGridListModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSliderModule,
  MatStepperModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  //  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatAccordion,
  MatExpansionModule,
  MatMenuModule,
  MatLineModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatDialogModule,

  MatExpansionPanel,
  MatCheckboxModule,
  MatSlideToggleModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  MatBottomSheetModule,
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export const MY_FORMATS = {
  parse: { dateInput: 'LL', },
  //display: { dateInput: 'YYYY-MM-DD', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY',},
  display: { dateInput: 'DD-MM-YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY', },
};
const matModules = [
  MatBottomSheetModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatIconModule, MatProgressBarModule, MatExpansionModule, MatAutocompleteModule,
  MatButtonModule, MatToolbarModule, MatIconModule, MatSidenavModule, MatBadgeModule, MatTabsModule,
  MatCardModule, MatListModule, MatGridListModule, MatDialogModule, MatFormFieldModule, MatButtonToggleModule,
  MatTooltipModule, MatSelectModule, MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatChipsModule,
  MatTableModule, MatExpansionModule, MatPaginatorModule, MatMenuModule, MatProgressBarModule,
  MatLineModule, MatSnackBarModule,MatSliderModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSlideToggleModule, MatStepperModule]
@NgModule({
  declarations: [],
  imports: [
    ...matModules,
    CommonModule, CommonModule,

  ], exports: [
    ...matModules,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { verticalPosition: 'bottom', duration: 2000, horizontalPosition: 'right' } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AngularMaterialModule { }
