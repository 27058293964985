import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { APIService } from '../Services/api.service';
import { Observable, pipe, of } from 'rxjs';
import * as faqactions from '../Actions/Faqs.action';
import { mergeMap, map, catchError } from 'rxjs/Operators';
import * as urls from '../Services/ServiceUrls';
@Injectable()
export class FaqEffect{
    constructor(private action:Actions,private api:APIService){
        
    }
    @Effect()
    getAllFaqs$:Observable<any>=
    this.action.pipe(ofType(faqactions.LOADFAQS),
    mergeMap((action:any)=>this.api.getData(urls.UrlModel.Application.Faqs).
    pipe(map((res:any)=>
    new faqactions.LoadfaqsSuccess(res)),
    catchError(error=>of(new faqactions.LoadfaqsFailed(error))
    ))));
}