import { Action } from '@ngrx/store';
export const LOGIN = '[USER]Login';
export const LOGIN_SUCCESS = '[USER]Login Success';
export const LOGIN_FAILED = '[USER]Login Failed';
export const LOGOUT = '[USER]Logout';
export const FORGETPASSWORD = '[USER]Forget password';
export const FORGETPASSWORD_SUCCESS = '[USER]Forget password Success';
export const RESETPASSWORD = '[USER]Reset password';
export const RESETPASSWORD_SUCCESS = '[USER]Reset password Success';
export const FACEBOOKLOGIN='[USER]Facebook Login';
export const FACEBOOKLOGIN_SUCCESS='[USER]Facebook Login Success';
export const FACEBOOKLOGIN_FAILED='[USER]Facebook Login Failed';
export const GOOGLELOGIN='[USER]Google Login';
export const GOOGLELOGIN_SUCCESS='[USER]Google Login Success';
export const GOOGLELOGIN_FAILED='[USER]Google Login Failed';
export class Login {
  public readonly type = LOGIN;
  constructor(public payload: any) {
  }
}
export class LoginSuccess {
  public readonly type = LOGIN_SUCCESS;
  constructor(public payload: any) {
    
  }
}
export class LoginFailed {
  public readonly type = LOGIN_FAILED;
  constructor(public payload: any) {
  }
}
export class Logout {
  public readonly type = LOGOUT;
  constructor(public payload: any) {
    localStorage.clear();
  }
}
export class ForgetPassword {
  public readonly type = FORGETPASSWORD;
  constructor(public payload: any) { }
}
export class ForgetPasswordSuccess {
  public readonly type = FORGETPASSWORD_SUCCESS;
  constructor(public payload: any) { }
}
export class ResetPassword {
  public readonly type = RESETPASSWORD;
  constructor(public payload: any) { }
}
export class ResetPasswordSuccess {
  public readonly type = RESETPASSWORD_SUCCESS;
  constructor(public payload: any) { }
}
export class FacebookLogin {
  public readonly type=FACEBOOKLOGIN;
  constructor(public accessToken:any){
  }
}
export class FacebookLoginSuccess {
  public readonly type=FACEBOOKLOGIN_SUCCESS;
  constructor(public payload:any){
  }
}
export class FacebookLoginFailed {
  public readonly type=FACEBOOKLOGIN_FAILED;
  constructor(public payload:any){
  }
}
export class GoogleLogin{
  public readonly type=GOOGLELOGIN;
  constructor(public accessToken:any){}
}
export class GoogleLoginSuccess{
  public readonly type=GOOGLELOGIN_SUCCESS;
  constructor(public payload:any){}
}
export class GoogleLoginFailed{
  public readonly type=GOOGLELOGIN_FAILED;
  constructor(public payload:any){}
}
export type LoginActions = Login | LoginSuccess | LoginFailed | Logout | ForgetPassword |
                           ForgetPasswordSuccess | ResetPassword | ResetPasswordSuccess|
                           FacebookLogin|FacebookLoginSuccess|FacebookLoginFailed|
                           GoogleLogin|GoogleLoginSuccess|GoogleLoginFailed;
