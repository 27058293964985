import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as vendorsignupaction from './../Actions/Vendorsignup.actin';
import * as hm from '../Actions/HomepageMenu.Action';
import { mergeMap, catchError, map, switchMap } from 'rxjs/Operators';
import { Observable, of } from 'rxjs';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import * as urls from '../Services/ServiceUrls';
import { HttpError } from '@aspnet/signalr';
import { APPSTATE } from '../HomePageState';
@Injectable()
export class VendorSignupEffect {
  constructor(public action: Actions, public api: Cs2LibService,private _store:Store<APPSTATE>) { }
  @Effect()
  loadPricingPlans$ = this.action.pipe(ofType(vendorsignupaction.GETPRICINGPLAN),
    mergeMap((action) => this.api.getData(urls.UrlModel.Application.PricingPlans)
      .pipe(map((res: any) => new vendorsignupaction.GetPricingPlanSuccess(res)),
        catchError(error => of(new vendorsignupaction.GetPricingPlanFailed(error))
        ))));
        
  @Effect()
  vendorSignup$ = this.action.pipe(ofType(vendorsignupaction.SIGNUP),
    mergeMap((action: any) => this.api.PostData(urls.UrlModel.Application.vendorSignup, action.payload)
      .pipe(map((res: any) => new vendorsignupaction.VendorSignupSuccess(res)),
        catchError(error => of(new vendorsignupaction.VendorSignupFailed(error))
        ))));
  @Effect()
  vendorSignupInitPayment$ = this.action.pipe(ofType(vendorsignupaction.VENDORSIGNUP_INITPAYMENT),
    mergeMap((action: any) => this.api.PostData(urls.UrlModel.Application.InitPayment, action.payload)
      .pipe(map((res: any) => new vendorsignupaction.VendorSignupInitPaymentSuccess(res)),
        catchError(error => of(new vendorsignupaction.VendorSignupInitPaymentSuccessFailed(error))
        ))));
  @Effect({dispatch:true})
  vendorSignupCheckEmailExist$ = this.action.pipe(ofType(vendorsignupaction.CHECKEMAIL_EXIST),
    mergeMap((action: any) => this.api.getData(urls.UrlModel.Common.CheckEmailExist + action.payload)
      .pipe(
      map((res: any,) => new vendorsignupaction.VendorSignupCheckEmailExist_Success(res)),
      catchError((err:HttpErrorResponse) =>
        of(new vendorsignupaction.VendorSignupCheckEmailExist_Failed(err))
      ))));
  @Effect()
  vendorSignupValidateVoucher$ = this.action.pipe(ofType(vendorsignupaction.VENDORSIGNUP_VALIDATE_VOUCHER),
    mergeMap((action: any) => this.api.PostData(urls.UrlModel.Vendor.PromoteBusiness.ValidateVoucher , action.payload)
      .pipe(map((res: any) => new vendorsignupaction.ValidateMembershipVoucherSuccess(res)),
        catchError((error:HttpErrorResponse) => 
          of(new vendorsignupaction.ValidateMembershipVoucherFailed(error))
    ))));

}
