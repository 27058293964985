
export const POSTLEAD='[Leads] Post Lead';
export const POSTLEAD_SUCCESS='[Leads] Post Lead Success';
export const POSTLEAD_FAILED='[Leads] Post Lead Failed';
export const GETMYLEADS='[Leads]Get My Lead';
export const GETMYLEADS_SUCCESS='[Leads]Get My Lead Success';
export const GETMYLEADS_FAILED='[Leads]Get My Lead Failed';
export const GETOFFERSBYLEAD='[Leads]Get Offers By Lead';
export const GETOFFERSBYLEAD_SUCCESS='[Leads]Get Offers By Lead Success';
export const GETOFFERSBYLEAD_FAILED='[Leads]Get Offers By Lead Failed';
export const REPLYTOOFFER='[Leads]Reply To Offer';
export const REPLYTOOFFER_SUCCESS='[Leads]Reply To Offer Success';
export const REPLYTOOFFER_FAILED='[Leads]Reply To Offer Failed';
export class PostLead{
    public readonly type=POSTLEAD;
    constructor(public payload:any){}
}
export class PostLeadSuccess{
    public readonly type=POSTLEAD_SUCCESS;
    constructor(public payload:any){}
}
export class PostLeadFailed{
    public readonly type=POSTLEAD_FAILED;
    constructor(public payload:any){}
}
export class GetMyLeads{
    public readonly type=GETMYLEADS;
    constructor(public payload:any){}
}
export class GetMyLeadsSuccess{
    public readonly type=GETMYLEADS_SUCCESS;
    constructor(public payload:any){}
}
export class GetMyLeadsFailed{
    public readonly type=GETMYLEADS_FAILED;
    constructor(public payload:any){}
}
export class GetOffersByLead{
    public readonly type=GETOFFERSBYLEAD;
    constructor(public payload:any){}
}
export class GetOffersByLeadSuccess{
    public readonly type=GETOFFERSBYLEAD_SUCCESS;
    constructor(public payload:any){}
}
export class GetOffersByLeadFailed{
    public readonly type=GETOFFERSBYLEAD_FAILED;
    constructor(public payload:any){}
}
export class ReplyToOffer{
    public readonly type=REPLYTOOFFER;
    constructor(public payload:any){}
}
export class ReplyToOfferSuccess{
    public readonly type=REPLYTOOFFER_SUCCESS;
    constructor(public payload:any){}
}
export class ReplyToOfferFailed{
    public readonly type=REPLYTOOFFER_FAILED;
    constructor(public payload:any){}
}

export type LeadActions=PostLead|PostLeadSuccess|PostLeadFailed|GetMyLeads|
                      GetMyLeadsSuccess|GetMyLeadsFailed|GetOffersByLead|
                      GetOffersByLeadSuccess|GetOffersByLeadFailed|ReplyToOffer|
                      ReplyToOfferSuccess|ReplyToOfferFailed;
