import { Injectable } from '@angular/core';
import * as urls from '../../../Store/Services/ServiceUrls';
import * as fromMychecklist from '../../Actions/Couple/MyChecklist.action';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, map } from 'rxjs/Operators';
@Injectable()
export class MyChecklistEffect {
    constructor(public cs2: Cs2LibService, public action: Actions) {

    }
    @Effect()
    getMyToDos$: Observable<any> = this.action.pipe(ofType(fromMychecklist.MYTODOGET),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.Checklist.GetMyChecklist, action.payload)
            .pipe(map((res) => new fromMychecklist.GetToDoSuccess(res),
                catchError(err => of(new fromMychecklist.GetToDoFailed()))
            ))));
    @Effect()
    getMyToDosByTimeCount$: Observable<any> = this.action.pipe(ofType(fromMychecklist.MYTODOBYTIME),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.Checklist.MyToDoByTimeCount)
            .pipe(map((res) => new fromMychecklist.GetToDoByTimeSuccess(res),
                catchError(err => of(new fromMychecklist.GetToDoByTimeFailed()))
            ))));
    @Effect()
    getMyToDosByCategoryCount$: Observable<any> = this.action.pipe(ofType(fromMychecklist.MYTODOBYCATEGORY),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.Checklist.MyTodoByCategoryCount)
            .pipe(map((res) => new fromMychecklist.GetToDoByCategorySuccess(res),
                catchError(err => of(new fromMychecklist.GetToDoByCategoryFailed(err)))
            ))));
    @Effect()
    MyToDosCreateUpdate$: Observable<any> = this.action.pipe(ofType(fromMychecklist.MYTODOADDUPDATE),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.Checklist.MyToDoCreateUpdate, action.payload)
            .pipe(map((res) => new fromMychecklist.CreateUpdateToDoSuccess(res),
                catchError(err => of(new fromMychecklist.CreateUpdateToDoFailed()))
            ))));
    @Effect()
    RemoveToDo$: Observable<any> = this.action.pipe(ofType(fromMychecklist.REMOVETODO),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.Checklist.MyToDoRemove + action.id)
            .pipe(map((res) => new fromMychecklist.RemoveToDoSuccess(res),
                catchError(err => of(new fromMychecklist.RemoveToDoFailed(err)))
            ))));
}