import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Cs2LibService } from "projects/cs2-lib/src/public-api";
import { APPSTATE } from "../HomePageState";
import { APIService } from "../Services/api.service";
import * as reviewActions from '../Actions/Reviews.action';
import * as galleryAction from '../../Store/Actions/Gallery.action';
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import * as urls from '../../../home/Store/Services/ServiceUrls';
import { of } from "rxjs";
@Injectable()
export class ReviewsEffect{
    constructor(public action:Actions,public api:Cs2LibService,public store:Store<APPSTATE>){

    }
    @Effect()
    getReviews$=this.action.pipe(ofType(reviewActions.GETREVIEWS),
    mergeMap((action:any)=>this.api.PostData(urls.UrlModel.Application.GetReviews,action.payload)
    .pipe(map((res:any)=>new reviewActions.GetReviewsSuccess(res),
    catchError(err=>of(new reviewActions.GetReviewsFailed(err)))
    ))))
}
@Injectable()
export class GalleryEffect{
    constructor(public action:Actions,public api:Cs2LibService,public store:Store<APPSTATE>){}
    @Effect()
    getVideos$=this.action.pipe(ofType(galleryAction.GETVIDEOS_BYVENDOR),
    mergeMap((action:any)=>this.api.getData(urls.UrlModel.Application.GetVideosByVendor+action.vendorid)
    .pipe(map((res:any)=>new galleryAction.GetVideosByVendorSuccess(res),
    catchError(err=>of(new galleryAction.GetVideosByVendorFailed(err)))
    ))));
    @Effect()
    getAlbumByVendor$=this.action.pipe(ofType(galleryAction.GETALBUMS_BYVENDOR),
    mergeMap((action:any)=>this.api.PostData(urls.UrlModel.Application.GetAlbumByVendor,action.payload)
    .pipe(map((res:any)=>new galleryAction.GetAlbumsByVendorSuccess(res),
    catchError(err=>of(new galleryAction.GetAlbumsByVendorFailed(err)))
    ))));
    @Effect()
    getImagesByAlbum$=this.action.pipe(ofType(galleryAction.GETALBUMSIMAGES_BYALBUM),
    mergeMap((action:any)=>this.api.getData(urls.UrlModel.Application.GetImagesByAlbum+action.albumid)
    .pipe(map((res:any)=>new galleryAction.GetAlbumsImagesByAlbumSuccess(res),
    catchError(err=>of(new galleryAction.GetAlbumsImagesByAlbumFailed(err)))
    ))));
    @Effect()
    getDealByVendor$=this.action.pipe(ofType(galleryAction.GETDEALS_BYVENDOR),
    mergeMap((action:any)=>this.api.PostData(urls.UrlModel.Application.GetDealsByVendor,action.payload)
    .pipe(map((res:any)=>new galleryAction.GetDealByVendorSuccess(res),
    catchError(err=>of(new galleryAction.GetDealByVendorFailed(err)))
    ))));
    @Effect()
    logVideoViewAction$=this.action.pipe(ofType(galleryAction.VIDEOVIEWLOG),
    mergeMap((action:any)=>this.api.getData(urls.UrlModel.Application.AddVideoViewLog+action.videoId)
    .pipe(map((res:any)=>new galleryAction.AddVideoViewLogSuccess(res),
    catchError(err=>of(new galleryAction.AddVideoViewLogFailed(err)))
    ))));
}