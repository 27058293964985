import * as FromVacencies from '../Actions/Vacencies.action';
import { APPSTATE } from '../HomePageState';
import { createSelector } from '@ngrx/store';
export const VaceniesInitState={
    loading:false,
    Vacencies:[],
    msg:{}
}
export function VacenciesReducer(state=VaceniesInitState,action:FromVacencies.VacenciesActions){
    switch(action.type){
        case FromVacencies.GETVACENCIES:
            return {...state,loading:true,Vacencies:state.Vacencies,msg:state.msg}
        case FromVacencies.GETVACENCIES_SUCCESS:
            return {...state,loading:false,Vacencies:action.payload,msg:state.msg}
        case FromVacencies.VACENCIES_FAILED:
            return state;
        default:
            return state;
    }
}
export const GetVacenciesReducer=(state:APPSTATE)=>state;
export const SelectVacenciesLoader=createSelector(GetVacenciesReducer,(state:any)=>state.vacenciesreducer?state.vacenciesreducer.loading:null);
export const SelectVacencies=createSelector(GetVacenciesReducer,(state:any)=>state.vacenciesreducer?state.vacenciesreducer.Vacencies:null);