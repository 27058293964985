import { Action, createAction } from '@ngrx/store';

export const LOADCATEGORY = '[Category] LoadCategory';
export const LOADCATEGORYFAIL = '[Category] LoadCategoryFailed';
export const LOADCATEGORYSUCCESS = '[Category] LoadCategorySuccess';
export const LOADVENDORBYCATEGORY='[Category] Load Vendor By Category';
export const LOADVENDORBYCATEGORYSUCCESS='[Category] Load Vendor By Category Success';

export const LOADCATEGORIES_WITHSERVICES='[Categories]Load Categories With Services';
export const LOADCATEGORIES_WITHSERVICES_SUCCESS='[Categories]Load Categories With Services Success';
export const LOADCATEGORIES_WITHSERVICES_FAILED='[Categories]Load Categories With Services Failed';

export class LoadCategory implements Action {
 public readonly type = LOADCATEGORY;
  constructor() {
   }
}
export class LoadCategoryFailed implements Action {
  public readonly type = LOADCATEGORYFAIL;
  constructor() { }
}
export class LoadCategorySuccess implements Action {
  public readonly type = LOADCATEGORYSUCCESS;
  constructor(public payload: any) {
  }
}
export class LoadVendorByCategory{
  public readonly type=LOADVENDORBYCATEGORY;
  constructor(){}
}
export class LoadVendorByCategorySuccess{
  public readonly type=LOADVENDORBYCATEGORYSUCCESS;
  constructor(public payload: any){}
}
export class LoadCategoryWithServices{
    public readonly type=LOADCATEGORIES_WITHSERVICES;
    constructor(){}
}
export class LoadCategoryWithServicesSuccess{
    public readonly type=LOADCATEGORIES_WITHSERVICES_SUCCESS;
    constructor(public payload:any){}
}
export class LoadCategoryWithServicesFailed{
    public readonly type=LOADCATEGORIES_WITHSERVICES_FAILED;
    constructor(){}
}
export type CategoryActions = LoadCategory | LoadCategoryFailed | LoadCategorySuccess|LoadVendorByCategory|
                              LoadVendorByCategorySuccess|LoadCategoryWithServices|LoadCategoryWithServicesSuccess|
                              LoadCategoryWithServicesFailed;
