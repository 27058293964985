import { Action, Store } from '@ngrx/store';
import { APIService } from '../Services/api.service';
import * as URLS from '../Services/ServiceUrls';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as catActions from '../Actions/Category.action';
import * as hm from '../Actions/HomepageMenu.Action';
import * as locationaction from '../Actions/Location.action';
import { map, catchError, mergeMap, switchMap } from 'rxjs/Operators';
import { of, Observable } from 'rxjs';
import { APPSTATE } from '../HomePageState';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
@Injectable()
export class LoadCategoriesEffect {
  constructor(private action: Actions, private cs2: Cs2LibService, private store: Store<APPSTATE>) {

  }
  @Effect()
  getAllCategories$:Observable<any>=this.action.pipe(ofType(catActions.LOADCATEGORY),
  mergeMap((action:any)=>this.cs2.getData(URLS.UrlModel.Common.AllCategories)
  .pipe(map(res=>new catActions.LoadCategorySuccess(res)),
  catchError(err=>of(new catActions.LoadCategoryFailed()))
  )));
  @Effect()
  getVendorByCategories$:Observable<any>=this.action.pipe(ofType(catActions.LOADVENDORBYCATEGORY),
  mergeMap((action:any)=>this.cs2.getData(URLS.UrlModel.Application.VendorByCategory)
  .pipe(map(res=>new catActions.LoadVendorByCategorySuccess(res)),
  catchError(err=>of(new catActions.LoadCategoryFailed()))
  )));
  

  @Effect()
  getCategoriesWithServices$: Observable<any> =this.action.pipe(ofType(catActions.LOADCATEGORIES_WITHSERVICES),
      mergeMap((action) =>this.cs2.getData(URLS.UrlModel.Common.AllCategoryWithServices).pipe(map((res: any) =>
          new catActions.LoadCategoryWithServicesSuccess(res),
          catchError(error => of(new catActions.LoadCategoryWithServicesFailed()))
        )),
      ));

  @Effect()
  getDreamLocations$: Observable<any> =
    this.action.pipe(ofType(hm.LOADDREAMLOCATION),
      switchMap((action:any) => this.cs2.getData(URLS.UrlModel.Application.FeaturedVendors+action.payload)
        .pipe(map((res: any) =>
          new hm.LoadDreamLocationSuccess(res)),
          catchError(error => of(new hm.LoadDreamLocationFailed({}))
          ))));
  @Effect()
  getAllLocations$: Observable<any> =
    this.action.pipe(ofType(locationaction.LOADALLLOCATIONS),
      mergeMap((action) => this.cs2.getData(URLS.UrlModel.Common.ALlLocations)
      .pipe(map((res: any) =>
        new locationaction.LoadAllLocationsSuccess(res)),
        catchError(error => of(new locationaction.LoadAllLocationsFailed({}))
      ))));
  @Effect()
  getHomepageBanners$: Observable<any> =
    this.action.pipe(ofType(hm.LOADBANNERS),
      mergeMap((action:any) => this.cs2.getData(URLS.UrlModel.Application.HomepageBanners+action.payload)
      .pipe(map(res =>new hm.LoadBannersSuccess(res)),
        catchError(error => of(new hm.LoadBannersFailed(error)))
    )));
    @Effect()
    getCountries$:Observable<any>=
    this.action.pipe(ofType(hm.LOADCOUNTRIES),
    mergeMap((action:any)=>this.cs2.getData(URLS.UrlModel.Common.Countries)
    .pipe(map((res:any)=>new hm.LoadCountry_Success(res)),
    catchError(error=>of (new hm.LoadCountry_Failed(error)))
    )));
    @Effect()
    getHomepageBannerPreview$:Observable<any>=
    this.action.pipe(ofType(hm.LOADBANNERPREVIEW),
    mergeMap((action:any)=>this.cs2.getData(URLS.UrlModel.Application.HomepageBannerPreview+action.payload.SlId+'&vid='+action.payload.vid+'&device='+action.payload.device)
    .pipe(map((res:any)=>new hm.LoadBannersPreviewSuccess(res)),
    catchError(error=>of (new hm.LoadBannersPreviewFailed(error)))
    )));
}
