import * as fromMyinspiration from '../../Actions/Couple/MyInspiration.action';
import { APPSTATE } from '../../HomePageState';
import { createSelector } from '@ngrx/store';

export const MyInspirationState = {
    loading: false,
    loaded: false,
    photos: [],
    events: [],
    deals:[],
    deleteInspiration:{},
    leads:[],
    LeadPostSuccessResponse:{},
    OffersByLead:{}
}
export function MyInspirationReducer(state = MyInspirationState, action: fromMyinspiration.MyinspirationActions) {
    switch (action.type) {
        case fromMyinspiration.MYINSPIRATIONPHOTOS:
            return { ...state, loading: true, loaded: false, photos: state.photos, events: state.events }
        case fromMyinspiration.MYINSPIRATIONPHOTOSSUCCESS:
            return { ...state, loading: false, loaded: true, photos: action.payload, events: state.events }
        case fromMyinspiration.MYINSPIRATIONPHOTOSFAILED:
            return { ...state, loading: false, loaded: true, photos: state.photos, events: state.events }
        case fromMyinspiration.MYINSPIRATIONEVENTS:
            return { ...state, loading: false, loaded: true, photos: state.photos, events: state.events }
        case fromMyinspiration.MYINSPIRATIONEVENTSSUCCESS:
            return { ...state, loading: false, loaded: true, photos: state.photos, events: action.payload }
        case fromMyinspiration.MYINSPIRATIONEVENTSFAILED:
            return { ...state, loading: false }
        case fromMyinspiration.DELETEMYINSPIRATION:
            return { ...state, loading: true }
        case fromMyinspiration.DELETEMYINSPIRATION_SUCCESS:
            return { ...state, loading: false,deleteInspiration:action.payload }
        case fromMyinspiration.DELETEMYINSPIRATION_FAILED:
            return { ...state, loading: false }
        case fromMyinspiration.MYINSPIRATIONDEAL:
            return{...state,loading:true}
        case fromMyinspiration.MYINSPIRATIONDEAL_SUCCESS:
            return{...state,deals:action.payload,loading:false}
        case fromMyinspiration.MYINSPIRATIONDEAL_FAILED:
            return{...state,loading:false}
        
        default:
            return state
    }
}
export const getMyInspirationReducer = (state: APPSTATE) => state;
export const SelectMyInspirationPhotos = createSelector(getMyInspirationReducer, (state: any) => state.myinspirationreducer ? state.myinspirationreducer.photos : null);
export const SelectMyInspirationEvents = createSelector(getMyInspirationReducer, (state: any) => state.myinspirationreducer ? state.myinspirationreducer.events : null);
export const SelectDeleteInspiration = createSelector(getMyInspirationReducer, (state: any) => state.myinspirationreducer ? state.myinspirationreducer.deleteInspiration : null);
export const SelectMyInspirationDeals = createSelector(getMyInspirationReducer, (state: any) => state.myinspirationreducer ? state.myinspirationreducer.deals : null);
