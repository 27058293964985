import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { APIService } from '../Services/api.service';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as dealsAction from '../Actions/DealsAndDiscount.action';
import { mergeMap, map, catchError } from 'rxjs/Operators';
import * as urls from '../../Store/Services/ServiceUrls';

@Injectable()
export class DealsAndDiscountEffect {
  constructor(public action: Actions, public api: APIService) {

  }
  @Effect()
  $getHighlitedDeals: Observable<any> =
    this.action.pipe(ofType(dealsAction.LOAD_HIGHLITED_DEALS),
      mergeMap((action) => this.api.getData(urls.UrlModel.Application.HighlitedDeals).pipe(map((res) =>
        new dealsAction.LoadHighlitedDealsSuccess(res),
        catchError(error => of(new dealsAction.LoadHighlitedDealsFailed({})))
      ))
      ));
  @Effect()
  $getAllDeals: Observable<any> =
    this.action.pipe(ofType(dealsAction.LOADDEALS),
      mergeMap((action:any) => this.api.PostData(urls.UrlModel.Application.DiscountAndDeals,action.payload).pipe(map((res) =>
        new dealsAction.LoadDealsSuccess(res),
        catchError(error => of(new dealsAction.LoadDealsFailed({})))
      ))));
      @Effect()
      $getAllDiscounts: Observable<any> =
        this.action.pipe(ofType(dealsAction.LOADDISCOUNT),
          mergeMap((action:any) => this.api.PostData(urls.UrlModel.Application.Discount,action.payload).pipe(map((res) =>
            new dealsAction.LoadDiscountsSuccess(res),
            catchError(error => of(new dealsAction.LoadDealsFailed({})))
          ))));
          @Effect()
          $getDealsDetails: Observable<any> =
            this.action.pipe(ofType(dealsAction.LOADDEALSDETAILS),
              mergeMap((action:any) => this.api.getData(urls.UrlModel.Application.DealsDetails+action.payload).pipe(map((res) =>
                new dealsAction.LoadDealsDetailSuccess(res),
                catchError(error => of(new dealsAction.LoadDealsFailed({})))
              ))));
}
