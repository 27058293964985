import { Action, createSelector } from '@ngrx/store';
import * as ev_actions from '../../Store/Actions/Event.action';
import { APPSTATE } from '../HomePageState';
export const EventsInitialState={
  loading:false,
  events:{},
  eventDetails:{}
}
export function EventReducer(state = EventsInitialState, action: ev_actions.EVENTACTIONS) {
  switch (action.type) {
    case ev_actions.LOADEVENTS:
      return {...state,loading:true,events:state.events,eventDetails:state.eventDetails};
    case ev_actions.LOADEVENT_SUCCESS:
      state = action.payload;
      return {...state, loading:false,events:action.payload,eventDetails:state.eventDetails};
    case ev_actions.LOADEVENT_FAILED:
      return {...state,loading:false,events:state.events,eventDetails:state.eventDetails};
      case ev_actions.LOADEVENTDETAILS:
      return {...state,loading:true,events:state.events,eventDetails:state.eventDetails};
      case ev_actions.LOADEVENTDETAILS_SUCCESS:
      return {...state,loading:true,events:state.events,eventDetails:action.payload};
    default:
      return state;
  }
}
export const GetEventReducer=(state:APPSTATE)=>state;
export const SelectEvents=createSelector(GetEventReducer,(state:any)=>state.eventreducer?state.eventreducer.events:null);
export const SelectEventsDetails=createSelector(GetEventReducer,(state:any)=>state.eventreducer?state.eventreducer.eventDetails:null);
