
export const GETMYEXPENSETOTAL = '[Budget]Get My Expense Total';
export const GETMYEXPENSETOTAL_SUCCESS = '[Budget]Get My Expense Success';

export const GETMYBUDGETITEM = '[Budget]Get My Budget Items';
export const GETMYBUDGETITEM_SUCCESS = '[Budget]Get My Budget Items Success';

export const GETMYBUDGETITEMBYCATEGORY = '[Budget]Get My Budget Items By Cagegory';
export const GETMYBUDGETITEMBYCATEGORY_SUCCESS = '[Budget]Get My Budget Items By Cagegoryc Success';

export const CREATEUPDATEBUDGETITEM = '[Budget]Create Update Budget Item';
export const CREATEUPDATEBUDGETITEM_SUCCESS = '[Budget]Create Update Budget Item Success';
export const BUDGETITEM_FAILED = '[Budget] Budget Item Failed';

export const GETMYEXPENSEBYCATEGORYCOUNT = '[Budget]Expense By Category Count';
export const GETMYEXPENSEBYCATEGORYCOUNT_SUCCESS = '[Budget]Expense By Category Count Success';
export const DELETEMYBUDGETITEM = '[Budget]Delete My Budget Item';
export const DELETEMYBUDGETITEM_SUCCESS = '[Budget]Delete My Budget Item Success';
export const DELETEMYBUDGETITEM_FAILED = '[Budget]Delete My Budget Item Failed';
export const GET_MYBUDGETCATEGORY='[Budget]Get Budget Category';
export const GET_MYBUDGETCATEGORY_SUCCESS='[Budget]Get Budget Category Success';
export const GET_MYBUDGETCATEGORY_FAILED='[Budget]Get Budget Category Failed';

export class GetMyExpenseTotal {
    public readonly type = GETMYEXPENSETOTAL;
    constructor() { }
}
export class GetMyExpenseTotalSuccess {
    public readonly type = GETMYEXPENSETOTAL_SUCCESS;
    constructor(public payload: any) { }
}
export class GetMyBudgetItem {
    public readonly type = GETMYBUDGETITEM;
    constructor(public payload: any) { }
}
export class GetMyBudgetItemSuccess {
    public readonly type = GETMYBUDGETITEM_SUCCESS;
    constructor(public payload: any) { }
}
export class GetMyBudgetItemByCatgory {
    public readonly type = GETMYBUDGETITEMBYCATEGORY;
    constructor(public payload: any) { }
}
export class GetMyBudgetItemByCatgorySuccess {
    public readonly type = GETMYBUDGETITEMBYCATEGORY_SUCCESS;
    constructor(public payload: any) { }
}
export class CreateUpdateBudgetItem {
    public readonly type = CREATEUPDATEBUDGETITEM;
    constructor(public payload: any) { }
}
export class CreateUpdateBudgetItemSuccess {
    public readonly type = CREATEUPDATEBUDGETITEM_SUCCESS;
    constructor(public payload: any) { }
}
export class BudgetItemFailed {
    public readonly type = BUDGETITEM_FAILED;
    constructor(public payload: any) { }
}
export class ExpenseByCategoryCount {
    public readonly type = GETMYEXPENSEBYCATEGORYCOUNT;
    constructor(public payload: any) {
    }
}
export class ExpenseByCategoryCountSuccess {
    public readonly type = GETMYEXPENSEBYCATEGORYCOUNT_SUCCESS;
    constructor(public payload: any) {
    }
}
export class DeleteMyBudgetItem {
    public readonly type = DELETEMYBUDGETITEM;
    constructor(public payload: any) { }
}
export class DeleteMyBudgetItemSuccess {
    public readonly type = DELETEMYBUDGETITEM_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteMyBudgetItemFailed {
    public readonly type = DELETEMYBUDGETITEM_FAILED;
    constructor(public payload: any) { }
}
export class GetMyBudgetCategory{
    public readonly type=GET_MYBUDGETCATEGORY;
    constructor(public payload:any){}
}
export class GetMyBudgetCategorySuccess{
    public readonly type=GET_MYBUDGETCATEGORY_SUCCESS;
    constructor(public payload:any){}
}
export class GetMyBudgetCategoryFailed{
    public readonly type=GET_MYBUDGETCATEGORY;
    constructor(public payload:any){}
}
export type BudgetActions = GetMyExpenseTotal | GetMyExpenseTotalSuccess | GetMyBudgetItem |
    GetMyBudgetItemSuccess | GetMyBudgetItemByCatgory | GetMyBudgetItemByCatgorySuccess |
    CreateUpdateBudgetItem | CreateUpdateBudgetItemSuccess | BudgetItemFailed |
    ExpenseByCategoryCount | ExpenseByCategoryCountSuccess | DeleteMyBudgetItem |
    DeleteMyBudgetItemSuccess | DeleteMyBudgetItemFailed|GetMyBudgetCategory|
    GetMyBudgetCategorySuccess|GetMyBudgetCategoryFailed;