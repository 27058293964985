import * as fromMyAccount from '../../Actions/Couple/MyAccount.action';
import { Action, createSelector } from '@ngrx/store';
import { APPSTATE } from '../../HomePageState';
export const MyAccountInitialState = {
    loading: false,
    preferancesOPtions: {},
    personalInfo: {},
    weddingDetails: {},
    couplePageTitle: '',
    fileinfo:{},
    updateImginfo:{},
    updateAccountImgSuccess:{},
    updatePreferencesComplete:null,
}
export function MyAccountReducer(state = MyAccountInitialState, action: fromMyAccount.MyAccountActions) {
    switch (action.type) {
        case fromMyAccount.GETPREFERANCESOPTIONS:
            return {...state, loading: true}
        case fromMyAccount.GETPREFERANCESOPTIONS_SUCCESS:
            return {...state, loading: true, preferancesOPtions: action.payload}
        case fromMyAccount.GETPREFERANCESOPTIONS_FAILED:
            return state;
        case fromMyAccount.GETPERSONALINFO:
            return {...state, loading: true}
        case fromMyAccount.GETPERSONALINFO_SUCCESS:
            return {...state, loading: true,personalInfo: action.payload}
        case fromMyAccount.MYWEDDINGDETAILS:
            return {...state, loading: true}
        case fromMyAccount.MYWEDDINGDETAILS_SUCCESS:
            return {...state, loading: true, weddingDetails: action.payload}
        case fromMyAccount.UPDATEPREFERANCE:
            return state;
        case fromMyAccount.UPDATEPREFERANCE_SUCCESS:
            return{...state,loading:false,updatePreferencesComplete:action.payload}
        case fromMyAccount.UPDATEPERSONALDETAILS:
            return {...state, loading: true}
        case fromMyAccount.UPDATEPERSONALDETAILS_SUCCESS:
            return {...state, loading: true}
        case fromMyAccount.UPDATEWEDDINGDETAILS:
            return {...state, loading: true}
        case fromMyAccount.UPDATEWEDDINGDETAILS_SUCCESS:
            return {...state, loading: false}
        case fromMyAccount.GETPREFERANCESOPTIONS_FAILED:
            return state;
        case fromMyAccount.SETCOUPLEPAGE_TITLE:
            return { ...state, couplePageTitle: action.payload }
        case fromMyAccount.UPLOADFILE:
            return{...state,loading:true}
        case fromMyAccount.UPLOADFILE_SUCCESS:
            return{...state,loading:false,fileinfo:action.payload}
        case fromMyAccount.UPLOADFILE_FAILED:
            return{...state,loading:false}
        case fromMyAccount.UPDATEACCOUNTIMAGE:
            return{...state,loading:true}
        case fromMyAccount.UPDATEACCOUNTIMAGE_SUCCESS:
            return{...state,loading:false,updateImginfo:action.payload}
        case fromMyAccount.UPDATEACCOUNTIMAGE_FAILED:
            return{...state,loading:false}
        default: return state;
    }
}
export const GetMyAccountReducer = (state: APPSTATE) => state;
export const SelectPreferanceOptions = createSelector(GetMyAccountReducer, (state: any) => state.myaccountreducer ? state.myaccountreducer.preferancesOPtions : null);
export const SelectPersonalInfo = createSelector(GetMyAccountReducer, (state: any) => state.myaccountreducer ? state.myaccountreducer.personalInfo : null);
export const SelectMyWeddingDetails = createSelector(GetMyAccountReducer, (state: any) => state.myaccountreducer ? state.myaccountreducer.weddingDetails : null);
export const SelectCouplePageTitle = createSelector(GetMyAccountReducer, (state: any) => state.myaccountreducer ? state.myaccountreducer.couplePageTitle : null);
export const SelectMyAccountFileInfo = createSelector(GetMyAccountReducer, (state: any) => state.myaccountreducer ? state.myaccountreducer.fileinfo : null);
export const SelectMyAccountUpdateImageInfo = createSelector(GetMyAccountReducer, (state: any) => state.myaccountreducer ? state.myaccountreducer.updateImginfo : null);
export const SelectUpdatePreferencesComplete = createSelector(GetMyAccountReducer, (state: any) => state.myaccountreducer ? state.myaccountreducer.updatePreferencesComplete : null);
