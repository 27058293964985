import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { APIService } from '../Services/api.service';
import { APPSTATE } from '../HomePageState';
import * as ev_actions from '../../Store/Actions/Event.action';
import { mergeMap, map, catchError } from 'rxjs/Operators';
import * as urls from '../../Store/Services/ServiceUrls';



@Injectable()
export class EventEffect {
  constructor(private action: Actions, private api: APIService, private store: Store<APPSTATE>) {
  }
  @Effect()
  getAllEvents$: Observable<any> =
    this.action.pipe(ofType(ev_actions.LOADEVENTS),
      mergeMap((action: any) => this.api.PostData(urls.UrlModel.Application.SearchEvents, action.payload)
        .pipe(map((res: any) =>
          new ev_actions.LoadEventsSuccess(res),
          catchError(error => of(new ev_actions.LoadEventsFailed(error))
          )))));
  @Effect()
  getEventsDetails$: Observable<any> =
    this.action.pipe(ofType(ev_actions.LOADEVENTDETAILS),
      mergeMap((action: any) => this.api.getData(urls.UrlModel.Application.SearchEventDetails+ action.payload.id+'&userId='+action.payload.userId)
        .pipe(map((res: any) =>
          new ev_actions.LoadEventsDetailsSuccess(res),
          catchError(error => of(new ev_actions.LoadEventsFailed(error))
          )))));
}
