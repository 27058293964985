import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as EnquiriesActions from '../../Actions/Couple/Enquiries.Action';
import * as urls from '../../../Store/Services/ServiceUrls';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { map, mergeMap, catchError } from 'rxjs/Operators';
import { of } from 'rxjs';
@Injectable()
export class MyEnquiryEffect{
    constructor(public cs2:Cs2LibService,public action:Actions){

    }
    @Effect()
    postEnquiry$=this.action.pipe(ofType(EnquiriesActions.POSTENQUIRIES),
    mergeMap((action:any)=>this.cs2.PostData(urls.UrlModel.User.Lead.PostEnquiries,action.payload)
    .pipe(map(res=>new EnquiriesActions.PostEnquiriesSuccess(res),
    catchError(err=>of(new EnquiriesActions.PostEnquiriesFailed(err))
    )))));
    @Effect()
    myEnquiry$=this.action.pipe(ofType(EnquiriesActions.MYENQUIRIES),
    mergeMap((action:any)=>this.cs2.getData(urls.UrlModel.User.Lead.MyEnquiries+action.payload)
    .pipe(map(res=>new EnquiriesActions.MyEnquiriesSuccess(res),
    catchError(err=>of(new EnquiriesActions.MyEnquiriesFailed(err))
    )))));
}