import { Action } from '@ngrx/store';
import * as hm from '../Actions/HomepageMenu.Action';
const CategoryIconPath = '../../assets/img/Categories/CategoryIcons/';
const CategoryImgPath = '../../assets/img/Categories/CategoryImg/';
export const SubmenuInitialState = [
//   {
//     categoryId: 0,
//     categoryName: 'All Categories',
//     isDeleted: false,
//     isFeatured: false,
//     imagePath: '',
//     iconImageURL: CategoryIconPath + 'bullet-list.svg',
//     services: [],
//     isSelect: false,
//     vendorHoneymoonLocations: null,
//   },
  {
    categoryId: 1,
    categoryName: 'Cakes',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'cake.webp',
    iconImageURL: CategoryIconPath + 'Wedding_cake.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 2,
    categoryName: 'Decorators & Wedding Stylist',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'Wedding_Decorators2.svg',
    iconImageURL: CategoryIconPath + 'Wedding_Decorators2.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 3,
    categoryName: 'Drinks and Beverages',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'drink_beverages.webp',
    iconImageURL: CategoryIconPath + 'drinks_beverages.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 4,
    categoryName: 'Fashion & Clothing',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'fashion_clothing.webp',
    iconImageURL: CategoryIconPath + 'Fashion&Clothing.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 5,
    categoryName: 'Florists, Bouquets and Centerpiece',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'fower.webp',
    iconImageURL: CategoryIconPath + 'florist.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 6,
    categoryName: 'Food & Catering',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'catering.webp',
    iconImageURL: CategoryIconPath + 'food_Catering.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 7,
    categoryName: 'Footwear',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'footwear.webp',
    iconImageURL: CategoryIconPath + 'Footwear.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 8,
    categoryName: 'Honeymoon & Travel',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'honeymoon_mauritius.webp',
    iconImageURL: CategoryIconPath + 'honeymoon.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 9,
    categoryName: 'Invitation Cards & Wedding Stationary',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'invitation_card.webp',
    iconImageURL: CategoryIconPath + 'stationary.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 10,
    categoryName: 'Jewellery & Wedding Rings',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'jewellery.webp',
    iconImageURL: CategoryIconPath + 'Jewellery.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 11,
    categoryName: 'Make Up, Hair & Mehendi',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'wedding_makeup.webp',
    iconImageURL: CategoryIconPath + 'make_up.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 12,
    categoryName: 'Marriage Celebrant',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'Marriage_Celebrant.svg',
    iconImageURL: CategoryIconPath + 'MarriageCelebrantv2.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 13,
    categoryName: 'Music, DJs & Entertainment',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'dj.webp',
    iconImageURL: CategoryIconPath + 'music_dj.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 14,
    categoryName: 'Other wedding Supplies & Accessories',
    isDeleted: false,
    isFeatured: false,
    imagePath: 'https://s3.us-east-2.amazonaws.com/prefect-image/cake.jpg',
    iconImageURL: CategoryIconPath + 'Wedding_cake.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 15,
    categoryName: 'Photography, Videography & Cinematography',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'photography.webp',
    iconImageURL: CategoryIconPath + 'Photography.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 16,
    categoryName: 'Venues',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath + 'venue.webp',
    iconImageURL: CategoryIconPath + 'Venues.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 17,
    categoryName: 'Wedding Cars & Transport',
    isDeleted: false,
    isFeatured: false,
    imagePath: 'https://s3.us-east-2.amazonaws.com/prefect-image/cake.webp',
    iconImageURL: CategoryIconPath + 'wedding_car.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 18,
    categoryName: 'Wedding Planner & Event Management',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath +'Wedding_Planner2.svg',
    iconImageURL: CategoryIconPath + 'Wedding_Planner2.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 19,
    categoryName: 'Shape & Fitness',
    isDeleted: false,
    isFeatured: false,
    imagePath: 'https://s3.us-east-2.amazonaws.com/prefect-image/cake.webp',
    iconImageURL: CategoryIconPath + 'Shape&Fitness.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
  {
    categoryId: 20,
    categoryName: 'Pamper Packages',
    isDeleted: false,
    isFeatured: false,
    imagePath: CategoryImgPath+'Pamper_Packages.svg',
    iconImageURL: CategoryIconPath + 'PamperPackagesv2.svg',
    services: [],
    isSelect: false,
    vendorHoneymoonLocations: null,
  },
];
export function SubmenuReducer(state = SubmenuInitialState, action: Action) {
  return state;

}
