import { Injectable, TemplateRef, ViewChild } from '@angular/core';
import * as momentNs from 'moment';
// import { io } from 'socket.io-client';
import { io } from 'socket.io-client/build/index';
const moment = momentNs;
// from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
export class Cs2SocketService {
  socket: any;
  //  msg: any;
  //   userName: string;
  typingname: string;
  onlineUsers = [];
  RoomId = 0;
  windowbtn = 'keyboard_arrow_down';
  minmax = 'maxwindow';
   socketUrl = 'https://chat.internext.mu/';
 // socketUrl = 'http://localhost:5000/';
  public msgAlertSound = './assets/Message-Notification-Alert.mp3';
  objMsg: ISocketMessage = {
    id: 0, msg: '',
    receiver: '',
    sender: '',
    userName: '',
    receiverName: '',
    notifyTyping: '',
    email: '',
    socketId: '',
    isDelivered: 0,
    isRead: true,
    repliedToMsgId: 0,
    replyToMsg: false,
    senderProfile: '',
    msgType: 0,
    attachmentUrl: '',
    previewUrl: ''
  };
  constructor() {
   // this.socket = io(this.socketUrl);
  }
  connectToSocket() {
  }
  sendMsg() {
    if (this.objMsg.msg !== null && this.objMsg.msg !== undefined && this.objMsg.msg != '') {
      this.objMsg.sender = this.objMsg.email;
      const msg = this.objMsg.msg;
      this.socket.emit('send_msg', this.objMsg);
    }
  }
  onKeyPress() {
    this.objMsg.notifyTyping = 'typing';
    this.socket.emit('typing', this.objMsg);
  }
  onKeyUp() {
    this.objMsg.notifyTyping = '';
    this.socket.emit('typing', this.objMsg);
  }
  switchRoom(RoomId) {
    this.RoomId = RoomId;
  }
  joinRoom(User) {
    this.objMsg.receiverName = User.FirstName + ' ' + User.LastName;
    this.RoomId = User.ContactEmail;
    this.objMsg.receiver = User.ContactEmail;
    this.socket.emit('msgHistory', this.objMsg);
  }
  disconnect() {
    this.objMsg.sender = this.objMsg.email;
    this.socket.emit('exit', this.objMsg);
  }
  minmaxwindow() {
    this.windowbtn == "keyboard_arrow_down" ? this.windowbtn = "keyboard_arrow_up" : this.windowbtn = "keyboard_arrow_down";
    this.minmax == 'maxwindow' ? this.minmax = 'minwindow' : this.minmax = 'maxwindow';
  }
  beep() {
    var snd = new Audio(this.msgAlertSound);
    snd.play();
  }
  sendAttachmentimg() {
    console.log(this.objMsg);
    this.socket.emit('sendImg', this.objMsg);
  }
  formatLastLoginTime(logintime):any{
    return moment(logintime).fromNow(true);
  }
}
export interface ISocketMessage {
  id: number;
  sender: string,
  senderProfile: string,
  receiver: string,
  msg: string,
  attachmentUrl: string,
  previewUrl: string,
  msgType: number,
  isDelivered: number,
  isRead: boolean,
  replyToMsg: boolean,
  repliedToMsgId: number,
  notifyTyping: string,
  receiverName: string,
  socketId: string,
  userName: string,
  email: string,
}
