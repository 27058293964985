import * as fromMyfeedback from '../../Actions/Couple/MyFeedback.action';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { APPSTATE } from '../../HomePageState';
import { createSelector } from '@ngrx/store';
export const MyFeedbackInitialState={
    loading:false,
    loaded:false,
    response:{}
}
export function MyFeedbackReducer(state=MyFeedbackInitialState,action:fromMyfeedback.MyFeedbackActions){
    switch(action.type){
        case fromMyfeedback.SENDFEEDBACK:
            return{...state,loading:true,response:''}
        case fromMyfeedback.SENDFEEDBACK_SUCCESS:
            return{...state,loading:false,response:action.payload}
        case fromMyfeedback.SENDFEEDBACK_FAILED:
            return state;
         default:
             return state;
    }
}
export const getMyFeedbackReducer=(state:APPSTATE)=>state;
export const SelectFeedbackLoading=createSelector(getMyFeedbackReducer,(state:any)=>state.myfeedbackreducer?state.myfeedbackreducer.loading:null);
export const SelectFeedbackResponse=createSelector(getMyFeedbackReducer,(state:any)=>state.myfeedbackreducer?state.myfeedbackreducer.response:null);