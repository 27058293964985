
export const SENDFEEDBACK='[Feedback] Send Feedback';
export const SENDFEEDBACK_SUCCESS='[Feedback] Send Feedback Success';
export const SENDFEEDBACK_FAILED='[Feedback] Send Feedback Success';

export class SendFeedback{
    public readonly type=SENDFEEDBACK;
    constructor(public payload:any){}
}
export class SendFeedbackSuccess{
    public readonly type=SENDFEEDBACK_SUCCESS;
    constructor(public payload:any){}
}
export class SendFeedbackFailed{
    public readonly type=SENDFEEDBACK_FAILED;
    constructor(public payload:any){}
}
export type MyFeedbackActions=SendFeedback|SendFeedbackSuccess|SendFeedbackFailed;