import { LoginActions } from '../Actions/Login.action';
import * as loginActions from '../Actions/Login.action';
import { Router } from '@angular/router';
import { createSelector } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
export const LoginState = {
  processing: false,
  loginsuccess: false,
  loginFail: null,
  isLoggedIn: false,
  userDeatils: {},
  apiMsg: {},
};
export function LoginReducer(state = LoginState, action: LoginActions) {
  switch (action.type) {
    case loginActions.LOGIN:
      return {
        ...state, processing: true,loginFail: false, apiMsg: state.apiMsg
      }
    case loginActions.LOGIN_SUCCESS:
    return {
        ...state, processing: false, loginsuccess: true, loginFail: false, isLoggedIn: true,
        userDeatils:action.payload, apiMsg: state.apiMsg
      };
    case loginActions.LOGIN_FAILED:
      return {
        ...state, processing: false, loginsuccess: false, loginFail: true, isLoggedIn: false,
        userDeatils: action.payload, apiMsg: state.apiMsg
      };
    case loginActions.LOGOUT:
      return {
        ...state, processing: false, loginsuccess: false, loginFail: false, isLoggedIn: false,
        userDeatils: null, apiMsg: state.apiMsg
      };
    case loginActions.FORGETPASSWORD:
      return {
        ...state, processing: true, loginsuccess: false, loginFail: false, isLoggedIn: false,
        userDeatils: state.userDeatils, apiMsg: state.apiMsg
      };
    case loginActions.FORGETPASSWORD_SUCCESS:
        
      return {
        ...state, processing: false, loginsuccess: false, loginFail: false, isLoggedIn: false,
        userDeatils: state.userDeatils, apiMsg: action.payload
      };
    case loginActions.RESETPASSWORD:
      return {
        ...state, processing: true, loginsuccess: false, loginFail: false, isLoggedIn: false,
        userDeatils: state.userDeatils, apiMsg: {}
      };
    case loginActions.RESETPASSWORD_SUCCESS:
      return {
        ...state, processing: false, loginsuccess: false, loginFail: false, isLoggedIn: false,
        userDeatils: state.userDeatils, apiMsg: action.payload
      };
    case loginActions.FACEBOOKLOGIN:
      return{...state,loginsuccess:false}
    case loginActions.FACEBOOKLOGIN_SUCCESS:
      return{...state,loginsuccess:true}
    case loginActions.FACEBOOKLOGIN_FAILED:
      return{...state,loginsuccess:false}
    case loginActions.GOOGLELOGIN:
      return{...state,loginsuccess:false}
    case loginActions.GOOGLELOGIN_SUCCESS:
      return{...state,loginsuccess:true}
    case loginActions.GOOGLELOGIN_FAILED:
      return{...state,loginsuccess:false}
    default:
      return state;
  }
}

export const getLoginState = (state: APPSTATE) => state.loginreducer;
export const SelectUserDetails = createSelector(getLoginState, (state: any) => state.userDeatils ? state.userDeatils : null);
export const SelectForgetPwdMsg = createSelector(getLoginState, (state: any) => state.apiMsg ? state.apiMsg : null);
export const SelectLoginTimer = createSelector(getLoginState, (state: any) => state.loginTime ? state.loginTime : null);