import * as urls from '../../../Store/Services/ServiceUrls';
import * as fromMyReviews from '../../Actions/Couple/MyReviews.action';
import { Injectable } from '@angular/core';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/Operators';
import { of, Observable } from 'rxjs';
@Injectable()
export class MyReviewsEffect{
    constructor(public cs2:Cs2LibService,public action:Actions){

    }
    @Effect()
    getMyReviews$:Observable<any>=this.action.pipe(ofType(fromMyReviews.GETMYREVIEWS),
    switchMap((action:any)=>this.cs2.PostData(urls.UrlModel.User.Myreviews.GetMyReviews,action.payload)
    .pipe(
    map((res)=>new fromMyReviews.GetMyReviewsSuccess(res),
    catchError(err=>of(new fromMyReviews.GetMyReviewsFailed(err)))
    ))));
    @Effect()
    createUpdateReviews$:Observable<any>=this.action.pipe(ofType(fromMyReviews.CREATEUPDATEREVIEWS),
    switchMap((action:any)=>this.cs2.PostData(urls.UrlModel.User.Myreviews.PostReviews,action.payload)
    .pipe(map((res)=>new fromMyReviews.CreateUpdateReviewsSuccess(res),
    catchError(err=>of(new fromMyReviews.CreateUpdateReviewsFailed(err)))
    ))));
}