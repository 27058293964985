import { Action } from '@ngrx/store';

export const LOADSUBJECT='[Home]Contact Us';
export const SENDENQUIRY='[Home]Send Enquiry';
export const SENDENQUIRY_SUCCESS='[Home]Send Enquiry Success';
export const SENDENQUIRY_FAILED='[Home]Send Enquiry Failed';
export class LoadSubject implements Action{
public readonly type=LOADSUBJECT;
constructor(public payload:any){

}
}
export class SendEnquiry implements Action{
    public readonly type=SENDENQUIRY;
    constructor(public payload:any){
    }
}
export class SendEnquirySuccess implements Action {
    public readonly type=SENDENQUIRY_SUCCESS;
    constructor(public payload:any){
    }
}
export class SendEnquiryFailed implements Action {
    public readonly type=SENDENQUIRY_FAILED;
    constructor(public payload:any){
    }
}

export type ContactUsActions=LoadSubject|SendEnquiry|SendEnquirySuccess|SendEnquiryFailed;