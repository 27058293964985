import { Action } from '@ngrx/store';

export const LOADHONYMOONDESTINATION = '[Honymoon] Load Destination';
export const LOADHONYMOONDESTINATION_SUCCESS = '[Honymoon] Load Destination Success';
export const LOADHONYMOONDESTINATION_FAILED = '[Honymoon] Load Destination Failed';
export const LOADHONYMOONDESTINATIONDETAILS = '[Honymoon] Load Destination Details';
export const LOADHONYMOONDESTINATIONDETAILS_SUCCESS = '[Honymoon] Load Destination Details Success';
export const LOADHONYMOONDESTINATIONDETAILS_FAILED = '[Honymoon] Load Destination Details Failed';
export const LOADHONEYMOONTRAVELSERVICES='[Honymoon]Load HonymoonTravel Services';
export const LOADHONEYMOONTRAVELSERVICES_SUCCESS='[Honymoon]Load HonymoonTravel Services Success';
export const LOADHONEYMOONTRAVELSERVICES_FAILED='[Honymoon]Load HonymoonTravel Services Failed';
export const VENDORSINHONEYMOONTRAVEL='[Honeymoon]Vendors in Honeymoon Travels';
export const VENDORSINHONEYMOONTRAVEL_SUCCESS='[Honeymoon]Vendors in Honeymoon Travels Success';
export const VENDORSINHONEYMOONTRAVEL_FAILED='[Honeymoon]Vendors in Honeymoon Travels Failed';
export class LoadHonymoon implements Action {
  public type = LOADHONYMOONDESTINATION;
  constructor(public payload: any) { }
}
export class LoadHonymoonSuccess implements Action {
  public type = LOADHONYMOONDESTINATION_SUCCESS;
  constructor(public payload: any) { }
}
export class LoadHonymoonFailed implements Action {
  public type = LOADHONYMOONDESTINATION_FAILED;
  constructor(public payload: any) { }
}
export class LoadHonymoonDestinationDetails {
  public type = LOADHONYMOONDESTINATIONDETAILS;
  constructor(public payload: any) { }
}
export class LoadHonymoonDestinationDetails_Success {
  public type = LOADHONYMOONDESTINATIONDETAILS_SUCCESS;
  constructor(public payload: any) {

  }
}
export class LoadHonymoonDestinationDetails_Failed {
  public type = LOADHONYMOONDESTINATIONDETAILS_FAILED;
  constructor(public payload: any) {
  }
}
export class LoadHonymoonTravelServices {
    public type = LOADHONEYMOONTRAVELSERVICES;
    constructor(public payload:string) {
    }
  }
  export class LoadHonymoonTravelServices_Success {
    public type = LOADHONEYMOONTRAVELSERVICES_SUCCESS;
    constructor(public payload: any) {
    }
  }
  export class LoadHonymoonTravelServices_Failed {
    public type = LOADHONEYMOONTRAVELSERVICES_FAILED;
    constructor(public payload: any) {
    }
  }
  export class VendorsInHoneymoonTravel{
    public type=VENDORSINHONEYMOONTRAVEL;
    constructor(public payload:any){}
  }
  export class VendorsInHoneymoonTravelSuccess{
    public type=VENDORSINHONEYMOONTRAVEL_SUCCESS;
    constructor(public payload:any){}
  }
  export class VendorsInHoneymoonTravelFailed{
    public type=VENDORSINHONEYMOONTRAVEL_FAILED;
    constructor(public payload:any){}
  }

export type HonymoonActions = LoadHonymoon | LoadHonymoonSuccess | LoadHonymoonFailed |
  LoadHonymoonDestinationDetails | LoadHonymoonDestinationDetails_Success |
  LoadHonymoonDestinationDetails_Failed|VendorsInHoneymoonTravel|VendorsInHoneymoonTravelSuccess|
  VendorsInHoneymoonTravelFailed;
