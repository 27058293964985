import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Cs2LibService } from "projects/cs2-lib/src/public-api";
import { Observable, of, pipe } from "rxjs";
import { catchError, map, switchMap } from "rxjs/Operators";
import * as FromMySupplierActions from '../../Actions/Couple/MySupplier.action';
import * as urls from '../../Services/ServiceUrls';
@Injectable()
export class MySupplierEffecct {
    constructor(public cs2: Cs2LibService, public action: Actions) {

    }
    @Effect()
    GetMySupplierEffect: Observable<any> = this.action.pipe(ofType(FromMySupplierActions.GETMYVENDORCOUNT),
        switchMap((action: any) => this.cs2.getData(urls.UrlModel.User.MySuppliers.MyVendorCount).
            pipe(map((res: any) => new FromMySupplierActions.GetMyVendorCountSuccess(res),
                catchError((err) => of(new FromMySupplierActions.GetMyVendorCountFailed(err)))
            ))))
    @Effect()
    GetMyVendorByCatgoryEffect: Observable<any> = this.action.pipe(ofType(FromMySupplierActions.GETMYVENDORCOUNTBYCATEGORY),
        switchMap((action: any) => this.cs2.getData(urls.UrlModel.User.MySuppliers.VendorCountByCategory).
            pipe(map((res: any) => new FromMySupplierActions.GetMyVendorByCategorySuccess(res),
                catchError((err) => of(new FromMySupplierActions.GetMyVendorByCategoryFailed(err)))
            ))))
    @Effect()
    SearchMyVendorByCatgoryAndStatus: Observable<any> = this.action.pipe(ofType(FromMySupplierActions.SEARCHMYSUPPLIERS),
        switchMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MySuppliers.SearchMySuppliers, action.payload).
            pipe(map((res: any) => new FromMySupplierActions.SearchMySuppliersByCategoryAndStatusSuccess(res),
                catchError((err) => of(new FromMySupplierActions.SearchMySuppliersByCategoryAndStatusFailed(err)))
            ))))
    @Effect()
    ChangeMySupplierStatus: Observable<any> = this.action.pipe(ofType(FromMySupplierActions.CHANGEMYSUPPLIERSTATUS),
        switchMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MySuppliers.ChangeMyVendorStatus, action.payload).
            pipe(map((res: any) => new FromMySupplierActions.ChangeMySupplierStatusSuccess(res),
                catchError((err) => of(new FromMySupplierActions.ChangeMySupplierStatusFailed(err)))
            ))))
    @Effect()
    DeleteMyVendor: Observable<any> = this.action.pipe(ofType(FromMySupplierActions.DELETEMYVENDOR),
        switchMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MySuppliers.DeleteMyVendor, action.payload).
            pipe(map((res: any) => new FromMySupplierActions.DeleteMyVendorSuccess(res),
                catchError((err) => of(new FromMySupplierActions.DeleteMyVendorFailed(err)))
        ))))
        @Effect()
    SendNoteToVendor: Observable<any> = this.action.pipe(ofType(FromMySupplierActions.SENDNOTETOSUPPLIER),
            switchMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MySuppliers.SendNoteToSupplier, action.payload).
                pipe(map((res: any) => new FromMySupplierActions.SendNoteToSupplierSuccess(res),
                    catchError((err) => of(new FromMySupplierActions.SendNoteToSupplierFailed(err)))
            ))))
}