import { Action, createSelector } from '@ngrx/store';
import * as HonymoonActions from '../Actions/Honeymoon.action';
import { APPSTATE } from '../HomePageState';
import { Statement } from '@angular/compiler';
export const HoneymoonDestinationInitialState={
  loading:false,
  destinations:[],
  honymoontravelservices:[],
  destinationDetails:{},
  vendorsInHoneymoonTravels:{}

}


export function HonymoonReducer(state = HoneymoonDestinationInitialState, action: HonymoonActions.HonymoonActions) {
  switch (action.type) {
    case HonymoonActions.LOADHONYMOONDESTINATION:
      return {...state,loading:true,destinations:state.destinations,destinationDetails:state.destinationDetails};
    case HonymoonActions.LOADHONYMOONDESTINATION_SUCCESS:
      return {...state,loading:true,destinations:action.payload,destinationDetails:state.destinationDetails};
    case HonymoonActions.LOADHONYMOONDESTINATION_FAILED:
      return state;
    case HonymoonActions.LOADHONYMOONDESTINATIONDETAILS:
      return {...state,loading:true,destinations:state.destinations,destinationDetails:state.destinationDetails};
    case HonymoonActions.LOADHONYMOONDESTINATIONDETAILS_SUCCESS:
      return {...state,loading:true,destinations:state.destinations,destinationDetails:action.payload};
    case HonymoonActions.LOADHONEYMOONTRAVELSERVICES:
        return{...state,loading:true}
    case HonymoonActions.LOADHONEYMOONTRAVELSERVICES_SUCCESS:
        return{...state,loading:false,honymoontravelservices:action.payload}
    case HonymoonActions.LOADHONEYMOONTRAVELSERVICES_FAILED:
        return{...state,loading:false}
    case HonymoonActions.VENDORSINHONEYMOONTRAVEL:
        return{...state,loading:true}
    case HonymoonActions.VENDORSINHONEYMOONTRAVEL_SUCCESS:
        return{...state,vendorsInHoneymoonTravels:action.payload,loading:false}
    case HonymoonActions.VENDORSINHONEYMOONTRAVEL_FAILED:
        return{...state,loading:false}
      default:
      return state;
  }
}
export const GetHonymoonReducer=(state:APPSTATE)=>state;
export const SelectHonymoonDestinations=createSelector(GetHonymoonReducer,(state:any)=>state.honeymoonreducer?state.honeymoonreducer.destinations:null);
export const SelectHonymoonDestinationsDetails=createSelector(GetHonymoonReducer,(state:any)=>state.honeymoonreducer.destinations?state.honeymoonreducer.destinationDetails:null);
export const SelectHonymoonTravelServices=createSelector(GetHonymoonReducer,(state:any)=>state.honeymoonreducer?state.honeymoonreducer.honymoontravelservices:null);
export const SelectVendorsInhonymoonTravels=createSelector(GetHonymoonReducer,(state:any)=>state.honeymoonreducer?state.honeymoonreducer.vendorsInHoneymoonTravels:null);
