import { GetReviews, GetReviewsFailed, GetReviewsSuccess } from "./Reviews.action";

export const GETVIDEOS_BYVENDOR='[Gallery]Get Videos';
export const GETVIDEOS_BYVENDOR_SUCCESS='[Gallery]Get Videos Success';
export const GETVIDEOS_BYVENDOR_FAILED='[Gallery]Get Videos Failed';

export const GETALBUMS_BYVENDOR='[Gallery]Get Albums';
export const GETALBUMS_BYVENDOR_SUCCESS='[Gallery]Get Albums Success';
export const GETALBUMS_BYVENDOR_FAILED='[Gallery]Get Albums Failed';

export const GETALBUMSIMAGES_BYALBUM='[Gallery]Get Albums Images';
export const GETALBUMSIMAGES_BYALBUM_SUCCESS='[Gallery]Get Albums Images Success';
export const GETALBUMSIMAGES_BYALBUM_FAILED='[Gallery]Get Albums Images Failed';

export const GETDEALS_BYVENDOR='[Gallery]Get Deals By Vendor';
export const GETDEALS_BYVENDOR_SUCCESS='[Gallery]Get Deals By Vendor Success';
export const GETDEALS_BYVENDOR_FAILED='[Gallery]Get Deals By Vendor Failed';
export const VIDEOVIEWLOG='[Gallery]VideoViewLog';
export const VIDEOVIEWLOG_SUCCESS='[Gallery]VideoViewLog Success';
export const VIDEOVIEWLOG_FAILED='[Gallery]VideoViewLog Failed';

export const GETPORTFOLIOIMAGES='[Gallery]Get Portfolio Images';
export const GETPORTFOLIOIMAGES_SUCCESS='[Gallery]Get Portfolio Images Success';
export const GETPORTFOLIOIMAGES_FAILED='[Gallery]Get Portfolio Images Failed';

export class GetVideosByVendor{
    public readonly type=GETVIDEOS_BYVENDOR;
    constructor(public vendorid:number){}
}
export class GetVideosByVendorSuccess{
    public readonly type=GETVIDEOS_BYVENDOR_SUCCESS;
    constructor(public payload:any){}
}
export class GetVideosByVendorFailed{
    public readonly type=GETVIDEOS_BYVENDOR_FAILED;
    constructor(public payload:any){}
}
export class GetAlbumsByVendor{
    public readonly type=GETALBUMS_BYVENDOR;
    constructor(public payload:any){}
}
export class GetAlbumsByVendorSuccess{
    public readonly type=GETALBUMS_BYVENDOR_SUCCESS;
    constructor(public payload:any){}
}
export class GetAlbumsByVendorFailed{
    public readonly type=GETALBUMS_BYVENDOR_FAILED;
    constructor(public payload:any){}
}
export class GetAlbumsImagesByAlbum{
    public readonly type=GETALBUMSIMAGES_BYALBUM;
    constructor(public vendorid:number){}
}
export class GetAlbumsImagesByAlbumSuccess{
    public readonly type=GETALBUMSIMAGES_BYALBUM_SUCCESS;
    constructor(public payload:any){}
}
export class GetAlbumsImagesByAlbumFailed{
    public readonly type=GETALBUMSIMAGES_BYALBUM_FAILED;
    constructor(public payload:any){}
}
export class GetDealByVendor{
    public readonly type=GETDEALS_BYVENDOR;
    constructor(public payload:any){}
}
export class GetDealByVendorSuccess{
    public readonly type=GETDEALS_BYVENDOR_SUCCESS;
    constructor(public payload:any){}
}
export class GetDealByVendorFailed{
    public readonly type=GETDEALS_BYVENDOR_FAILED;
    constructor(public payload:any){}
}
export class AddVideoViewLog{
    public readonly type=VIDEOVIEWLOG;
    constructor(public videoId:string){
        console.log(videoId);
    }
}
export class AddVideoViewLogSuccess{
    public readonly type=VIDEOVIEWLOG_SUCCESS;
    constructor(public payload:any){}
}
export class AddVideoViewLogFailed{
    public readonly type=VIDEOVIEWLOG_FAILED;
    constructor(public payload:any){}
}
export class GetPortfolioImages{
    public readonly type=GETPORTFOLIOIMAGES;
    constructor(public payload:string){}
}
export class GetPortfolioImagesSuccess{
    public readonly type=GETPORTFOLIOIMAGES_SUCCESS;
    constructor(public payload:any){}
}
export class GetPortfolioImagesFailed{
    public readonly type=GETPORTFOLIOIMAGES_FAILED;
    constructor(public payload:any){}
}
export type GalleryActions=GetReviews|GetReviewsSuccess|GetReviewsFailed|GetVideosByVendor|GetVideosByVendorSuccess|GetVideosByVendorFailed|
                            GetAlbumsByVendor|GetAlbumsByVendorSuccess|GetAlbumsByVendorFailed|GetAlbumsImagesByAlbum|
                            GetAlbumsImagesByAlbumSuccess|GetAlbumsImagesByAlbumFailed|
                            GetDealByVendor|GetDealByVendorSuccess|GetDealByVendorFailed|AddVideoViewLog|
                            AddVideoViewLogSuccess|AddVideoViewLogFailed|GetPortfolioImages|GetPortfolioImagesSuccess|
                            GetPortfolioImagesFailed;