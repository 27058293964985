import * as searchActions from "../Actions/Listing.action";
import { APPSTATE } from "../HomePageState";
import { createSelector } from "@ngrx/store";

export const InitialSearchState = {
  res: [],
  listingDetails: {},
  loading: false,
  searchlistingvm: null,
  services: [],
  prices: [],
  meta: {},
  listingHeading: "",
  similarVendors: [],
};
export function SearchlistingReducer(
  state = InitialSearchState,
  action: searchActions.SearchActions
) {
  switch (action.type) {
    case searchActions.LOADLISTING:
      return { ...state, loading: true, searchlistingvm: action.payload };
    case searchActions.LISTINGSUCCESS:
      return { ...state, res: action.payload, loading: false };
    case searchActions.LISTINGFAILED: {
      return { ...state, res: [], loading: false };
    }
    case searchActions.LISTINGDETAILS:
      return { ...state, res: state.res, loading: true };
    case searchActions.LISTINGDETAILSUCCESS:
      return { ...state, listingDetails: action.payload, loading: false };
    case searchActions.LOADSERVICES:
      return {
        ...state,
        listingDetails: action.payload,
        loading: true,
        services: action.payload.services,
      };
    case searchActions.SETMETATAGS:
      return { ...state, meta: action.payload };
    case searchActions.LISTINGHEADING:
      return { ...state, listingHeading: action.payload };
    case searchActions.LISTINGFAILED:
      return { ...state, res: action.payload, loading: false };
    case searchActions.GETPRICES:
      return { ...state, loading: true };
    case searchActions.GETPRICES_SUCCESS:
      return { ...state, prices: action.payload, loading: false };
    case searchActions.GETSIMILAR_VENDORS:
      return { ...state, loading: true };
    case searchActions.GETSIMILAR_VENDORS_SUCCESS:
      return { ...state, similarVendors: action.payload, loading: false };
    case searchActions.GETSIMILAR_VENDORS_FAILED:
      return { ...state, loading: false };
    case searchActions.CLEARSEARCHPARAMS:
      return { ...state, searchlistingvm: action.payload, loading: false };
    case searchActions.SETSEARCHLISTINGPARAMS:
      return { ...state, searchlistingvm: action.payload, loading: false };
    default:
      return state;
  }
}
export const getSearchListingReducer = (state: APPSTATE) => state;
export const SelectListingResultLoader = createSelector(
  getSearchListingReducer,
  (state: any) =>
    state.searchlistingreducer ? state.searchlistingreducer.loading : null
);
export const SelectListingResult = createSelector(
  getSearchListingReducer,
  (state: any) =>
    state.searchlistingreducer ? state.searchlistingreducer.res : null
);
export const SelectListingDetails = createSelector(
  getSearchListingReducer,
  (state: any) =>
    state.searchlistingreducer
      ? state.searchlistingreducer.listingDetails
      : null
);
export const SelectSearchListParam = createSelector(
  getSearchListingReducer,
  (state: any) =>
    state.searchlistingreducer
      ? state.searchlistingreducer.searchlistingvm
      : null
);
export const SelectMetaTags = createSelector(
  getSearchListingReducer,
  (state: any) =>
    state.searchlistingreducer ? state.searchlistingreducer.meta : null
);
export const SelectListingHeading = createSelector(
  getSearchListingReducer,
  (state: any) =>
    state.searchlistingreducer
      ? state.searchlistingreducer.listingHeading
      : null
);
export const SelectPrices = createSelector(
  getSearchListingReducer,
  (state: any) =>
    state.searchlistingreducer ? state.searchlistingreducer.prices : null
);
export const SelectSimilarVendors = createSelector(
  getSearchListingReducer,
  (state: any) =>
    state.searchlistingreducer
      ? state.searchlistingreducer.similarVendors
      : null
);
