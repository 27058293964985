import { Action } from '@ngrx/store';

export const LOADFAQS='[HOME]Load Faqs';
export const LOADFAQ__SUCCESS='[Home]Load Faqs Success'
export const LOADFAQ__FAILED='[Home]Load Faqs Failed'
export class Loadfaqs implements Action{
    public readonly type=LOADFAQS;
    constructor(public payload:any){
        
    }
}
export class LoadfaqsSuccess implements Action{
    public readonly type=LOADFAQ__SUCCESS;
    constructor(public payload:any){
        
    }
}
export class LoadfaqsFailed implements Action{
    public readonly type=LOADFAQ__FAILED;
    constructor(public payload:any){
        
    }
}
export type FaqsActions=Loadfaqs|LoadfaqsFailed|LoadfaqsSuccess;