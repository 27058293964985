import { UploadFileSuccess } from "./Couple/MyWeddingInsight.action";

export const USERACTION = '[USERLOGACTION]Like';
export const USERACTIONFAILED = '[USERLOGACTION]Failed';
export const USERACTIONSUCCESS = '[USERLOGACTION]Success';
export const CONVERTMURTOAUD='[COMMON]Convert MUR to AUD';
export const CONVERTMURTOAUD_SUCESS='[COMMON]Convert MUR to AUD Success';
export const CONVERTMURTOAUD_FAILED='[COMMON]Convert MUR to AUD Failed';
export class UserLogAction {
    public readonly type = USERACTION;
    constructor(public payload: any) {
    }
}
export class UserLogActionSuccess {
    public readonly type = USERACTIONSUCCESS;
    constructor(public payload: any) { }
}
export class UserLogActionFailed {
    public readonly type = USERACTIONFAILED;
    constructor(public payload: any) { }
}
export class ConvertMUR_TO_AUD{
    public readonly type=CONVERTMURTOAUD;
    constructor(public amount:number){}
}
export class ConvertMUR_TO_AUD_Success{
    public readonly type=CONVERTMURTOAUD_SUCESS;
    constructor(public payload){}
}
export class ConvertMUR_TO_AUD_failed{
    public readonly type=CONVERTMURTOAUD_FAILED;
    constructor(public payload){}
}

export type UserLogActions = UserLogAction | UserLogActionFailed | UserLogActionSuccess|ConvertMUR_TO_AUD|ConvertMUR_TO_AUD_Success|ConvertMUR_TO_AUD_failed;


export interface IUserLogAction {
    id: number,
    type: string,
    action: string,
    promoted: Boolean,
    slotId: number
}