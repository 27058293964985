import * as fromGuestListActions from '../../Actions/Couple/MyGuestlist.action';
import { Action, createSelector } from '@ngrx/store';
import { APPSTATE } from '../../HomePageState';
export const GuestListInitialState = {
    loading: false,
    guestCount: 0,
    menus: [],
    groups: [],
    guests: [],
    guestCountByAge: {},
    guestCountByMenu: [],
    guestCountBygroup: [],
    invitationCount: {},
    message: {},
    removegroupsuccess:{},
    removemenuComplete:{},
    addgroupComplete:null,
    addmenuComplete:null,
    updateGuestInvitionCompleted:null,
    addUpdateGuestComplete:null,
    removeGuestComplete:null,
    addMenuFailed:null,

}
export function GuestListReducer(state = GuestListInitialState, action: fromGuestListActions.GuestActions) {
    switch (action.type) {
        case fromGuestListActions.ADDUPDATEGROUP:
            return {...state, loading: true}
        case fromGuestListActions.ADDUPDATEGROUPSUCCESS:
            return {...state, loading: false,addgroupComplete:action.payload}
        case fromGuestListActions.ADDUPDATEGROUPFAILED:
            return {...state,addgroupComplete:action.payload,loading:false}
        case fromGuestListActions.ADDUPDATEMENU:
            return {...state, loading: true}
        case fromGuestListActions.ADDUPDATEMENUSUCCESS:
            return {...state, loading: false,addmenuComplete:action.payload}
        case fromGuestListActions.ADDUPDATEMENUFAILED:
            debugger;
            return{...state,loading:false,addMenuFailed:action.payload}
        case fromGuestListActions.ADDUPDATEGUEST:
            return {...state, loading: true,addUpdateGuestComplete:null}
        case fromGuestListActions.ADDUPDATEGUESTSUCCESS:
            return {...state, loading: false,addUpdateGuestComplete:action.payload}
        case fromGuestListActions.GETGROUP:
            return {...state, loading: true}
        case fromGuestListActions.GETGROUPSUCCESS:
            return {...state, loading: false,groups: action.payload}
        case fromGuestListActions.GETGROUPFAILED:
            return {...state,loading:false};
        case fromGuestListActions.GETMENU:
            return {...state, loading: true}
        case fromGuestListActions.GETMENUSUCCESS:
            return {...state, loading: false,menus: action.payload}
        case fromGuestListActions.GETMENUFAILED:
            return {...state,loading:false};
        case fromGuestListActions.GETGUESTLIST:
            return {...state, loading: true}
        case fromGuestListActions.GETGUESTLISTSUCCESS:
            return {...state, loading: false,guests: action.payload}
        case fromGuestListActions.GETGUESTLISTFAILED:
            return {...state,loading:false};
        case fromGuestListActions.GETGUESTCOUNTBYMENU:
            return {...state, loading: true}
        case fromGuestListActions.GETGUESTCOUNTBYMENUSUCCESS:
            return {...state, loading: false,guestCountByMenu: action.payload}
        case fromGuestListActions.GETGUESTCOUNTBYMENUFAILED:
            return {...state,loading:false};
        case fromGuestListActions.GETGUESTCOUNTBYGROUP:
            return {...state, loading: true}
        case fromGuestListActions.GETGUESTCOUNTBYGROUPSUCCESS:
            return {...state, loading: false, guestCountBygroup: action.payload}
        case fromGuestListActions.GETGUESTCOUNTBYGROUPFAILED:
            return {...state, loading: false, guestCountBygroup: state.guestCountBygroup}
        case fromGuestListActions.GETGUESTCOUNTBYAGEGROUP:
            return {...state, loading: true}
        case fromGuestListActions.GETGUESTCOUNTBYAGEGROUPSUCCESS:
            return {...state, loading: false,guestCountByAge: action.payload}
        case fromGuestListActions.GETGUESTCOUNTBYAGEGROUPFAILED:
            return {...state,loading:false};
        case fromGuestListActions.SEARCHMYGUEST:
            return {...state, loading: true}
        case fromGuestListActions.SEARCHMYGUESTSUCCESS:
            return {...state, loading:false, guests: action.payload}
        case fromGuestListActions.SEARCHMYGUESTFAILED:
            return {...state,loading:false};
        case fromGuestListActions.UPDATEINVITATION:
            return {...state, loading: true}
        case fromGuestListActions.UPDATEINVITATIONSUCCESS:
            return {...state, loading: false,updateGuestInvitionCompleted:action.payload}
        case fromGuestListActions.UPDATEINVITATIONFAILED:
            return {...state,loading:false};
        case fromGuestListActions.MYINVITATIONCOUNT:
            return {...state, loading: true}
        case fromGuestListActions.MYINVITATIONCOUNTSUCCESS:
            return {...state, loading: false, invitationCount: action.payload}
        case fromGuestListActions.REMOVEGUEST:
            return { ...state, loading: true }
        case fromGuestListActions.REMOVEGUEST_SUCCESS:
            return { ...state, loading: false,removeGuestComplete:action.payload }
        case fromGuestListActions.REMOVEGUEST_FAILED:
            return { ...state, loading: false, message: action.payload }
        case fromGuestListActions.REMOVE_MENU:
            return {...state,loading:true}
        case fromGuestListActions.REMOVE_MENU_SUCCESS:
            return{...state,loading:false,removemenuComplete:action.payload}
        case fromGuestListActions.REMOVE_MENU_FAILED:
            return{...state,loading:false,removemenuComplete:action.payload}
        case fromGuestListActions.REMOVE_GROUP:
            return{...state,loading:true,removegroupsuccess:{}}
        case fromGuestListActions.REMOVE_GROUP_SUCCESS:
            return{...state,loading:false,removegroupsuccess:action.payload}
        case fromGuestListActions.REMOVE_GROUP_FAILED:
            return{...state,loading:false,removegroupsuccess:action.payload}
        default:
            return state;

    }
}
export const getGuestListReducer = (state: APPSTATE) => state;
export const SelectGroups = createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.groups : null);
export const SelectMenus = createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.menus : null);
export const SelectGuestCountByGroup = createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.guestCountBygroup : null);
export const SelectGuestCountByMenu = createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.guestCountByMenu : null);
export const SelectGuestCountByAgeGroup = createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.guestCountByAge : null);
export const SelectSearchMyGuestList = createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.guests : null);
export const SelectMyInvitationCount = createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.invitationCount : null);
export const SelectRemoveGroupSuccess=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.removegroupsuccess : null);
export const SelectRemoveMenuComplete=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.removemenuComplete : null);
export const SelectAddUpdateGroupCompleted=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.addgroupComplete : null);
export const SelectUpdateInvitionCompleted=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.updateGuestInvitionCompleted : null);
export const SelectAddUpdateMenuCompleted=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.addmenuComplete : null);
export const SelectAddUpdateGuestCompleted=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.addUpdateGuestComplete : null);
export const SelectRemoveGuestCompleted=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.removeGuestComplete : null);
export const SelectGuestLoader=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.loading : null);
export const SelecAddUpdateMenuFailed=createSelector(getGuestListReducer, (state: any) => state.guestlistreducer ? state.guestlistreducer.addMenuFailed : null);
