import * as FromHomepageActions from '../Actions/HomepageMenu.Action';
import { APPSTATE } from '../HomePageState';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export const HomepageInitialState={
    loading:false,
    banners:[],
    promotedVendors:{},
}
export function HomepageReducer(state=HomepageInitialState,action: FromHomepageActions.HomepageMenuActions){
    switch(action.type){
        case FromHomepageActions.LOADBANNERS:
            return {...state,loading:true,banners:state.banners,promotedVendors:state.promotedVendors}
        case FromHomepageActions.LOADBANNERS_SUCCESS:
        return {...state,loading:false,banners:action.payload,promotedVendors:state.promotedVendors}
        case FromHomepageActions.LOADBANNERS_FAILED:
            return{...state,loading:false,banners:state.banners,promotedVendors:state.promotedVendors}
        case FromHomepageActions.LOADDREAMLOCATION:
            return{...state,loading:true,banners:state.banners,promotedVendors:state.promotedVendors}
        case FromHomepageActions.LOADDREAMLOCATIONSUCCESS:
            return{...state,loading:true,banners:state.banners,promotedVendors:action.payload}
        case FromHomepageActions.LOADBANNERPREVIEW:
            return{...state,loading:true}
        case FromHomepageActions.LOADBANNERPREVIEW_SUCCESS:
            return{...state,loading:false,banners:action.payload}
        case FromHomepageActions.LOADBANNERPREVIEW_FAILED:
            return{...state,loading:false}
        default:
            return state;
    }
}
export const getHomepageReducer=(state:APPSTATE)=>state;
export const selectHomePageBanners=createSelector(getHomepageReducer,(state:any)=>state.homepagereducer?state.homepagereducer.banners:null)
export const selectPromotedSuppliers=createSelector(getHomepageReducer,(state:any)=>state.homepagereducer?state.homepagereducer.promotedVendors:null)
