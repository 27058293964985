<div fxLayout='column'>
    <div fxLayout="row">
        <i (click)='closeDialog(0)' class="material-icons closeBtn">clear</i>
    </div>
    <h4 style="margin:0px">Are you sure ? </h4>
    <span style="text-align: center;font-size: 14px;" class='deleteTxt' >delete</span> 
    <span style="text-align: center;font-size: 12px;">{{data}}</span>
    <div fxLayout="row" style="justify-content: space-around;" class="ppActionBtns">
    <button mat-raised-button (click)='closeDialog(1)' class="primaryActionBtn">
        <i class="material-icons">{{cs2.dataservice.getIcon("done")}}</i> Yes !
    </button>
    <button (click)='closeDialog(0)' mat-raised-button class="cancelBtn_Default">
        <i class="material-icons">{{cs2.dataservice.getIcon("cancel")}}</i> Cancel
    </button>
    </div>
</div>