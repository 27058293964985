
export const GETVACENCIES='[Career] Get  Vacencies';
export const GETVACENCIES_SUCCESS='[Career] Get  Vacencies Success';
export const VACENCIES_FAILED='[Career] Get  Vacencies Failed';

export class GetVacencies{
    public readonly type=GETVACENCIES;
    constructor(){}
}
export class GetVacenciesSuccess{
    public readonly type=GETVACENCIES_SUCCESS;
    constructor(public payload:any){}
}
export class GetVacenciesFailed{
    public readonly type=VACENCIES_FAILED;
    constructor(public payload:any){}
}
export type VacenciesActions=GetVacencies|GetVacenciesSuccess|GetVacenciesFailed;