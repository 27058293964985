import { Injectable } from '@angular/core';
// declare var FB:any;
@Injectable({
  providedIn: 'root'
})
export class Cs2SocialService {
 FB: any;
  constructor() { 

  }
  fbLoginInit(appId){
    //(window as any).fbAsyncInit = function() {
    //   this.FB.init({
    //     appId      : appId,
    //     cookie     : true,
    //     xfbml      : true,
    //     version    : 'v3.1'
    //   });
        
    //   this.FB.AppEvents.logPageView();   
        
    //   this.FB.getLoginStatus(function(response) {
    //   this.statusChangeCallback(response);
    // });
    // };
    
    // (function(d, s, id){
    //    var js, fjs = d.getElementsByTagName(s)[0];
    //    if (d.getElementById(id)) {return;}
    //    js = d.createElement(s); js.id = id;
    //    js.src = "https://connect.facebook.net/en_US/sdk.js";
    //    fjs.parentNode.insertBefore(js, fjs);
    //  }(document, 'script', 'facebook-jssdk'));
 // }
 // statusChangeCallback(response){
  //  console.log(response);
  }
}
