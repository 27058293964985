
export const POSTENQUIRIES='[ENQUIRIES]Post Enquiries';
export const POSTENQUIRIES_SUCCESS='[ENQUIRIES]Post Enquiries Success';
export const POSTENQUIRIES_FAILED='[ENQUIRIES]Post Enquiries Failed';
export const MYENQUIRIES='[ENQUIRIES]My Enquiries';
export const MYENQUIRIES_SUCCESS='[ENQUIRIES]My Enquiries Success';
export const MYENQUIRIES_FAILED='[ENQUIRIES]My Enquiries Failed';
export class PostEnquiries{
    public readonly type=POSTENQUIRIES;
    constructor(public payload:any){}
}
export class PostEnquiriesSuccess{
    public readonly type=POSTENQUIRIES_SUCCESS;
    constructor(public payload:any){}
}
export class PostEnquiriesFailed{
    public readonly type=POSTENQUIRIES_FAILED;
    constructor(public payload:any){}
}
export class MyEnquiries{
    public readonly type=MYENQUIRIES;
    constructor(public payload:boolean){}
}
export class MyEnquiriesSuccess{
    public readonly type=MYENQUIRIES_SUCCESS;
    constructor(public payload:any){}
}
export class MyEnquiriesFailed{
    public readonly type=MYENQUIRIES_FAILED;
    constructor(public payload:any){}
}
export type EnquirieActions=PostEnquiries|PostEnquiriesSuccess|PostEnquiriesFailed|
                            MyEnquiries|MyEnquiriesSuccess|MyEnquiriesFailed;