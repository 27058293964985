import * as contactusActions from '../Actions/ContactUs.action';
import { APPSTATE } from '../HomePageState';
import { createSelector } from '@ngrx/store';
export const InitialContactUsState:any={
    messageTypes:[    
    { id: 1, name: 'General Enquiry' },
    { id: 2, name: 'Business Enquiry - Advertising' },
    { id: 3, name: 'Complaints' },
    ],
    data:{}
}
export function ContactUsReducer(state=InitialContactUsState,action:contactusActions.ContactUsActions){
    switch(action.type){
        case contactusActions.LOADSUBJECT:
            return {...state,InitialContactUsState}
            case contactusActions.SENDENQUIRY:
                return {...state}
            case contactusActions.SENDENQUIRY_SUCCESS:
                console.log(action.payload);
                return{...state,data:action.payload}
                default:
                    return{...state}
    }
}
export const ContactUsState=((state:APPSTATE)=>state.contactusreducer);
export const SelectMessageTypes=createSelector(ContactUsState,(state:any)=>state?state.messageTypes:[]);
export const SelectContactUsMsg=createSelector(ContactUsState,(state:any)=>state?state.data:null);
