import * as DealsActions from '../Actions/DealsAndDiscount.action';
import { filter } from 'rxjs/Operators';
import { APPSTATE } from '../HomePageState';
import { createSelector, createFeatureSelector } from '@ngrx/store';
const InitialDealState = { 
  loading:false,
  discounts: [], 
  deals: [], 
  highlitedDeal: {},
  dealsDetails:{}
 };
export function DealsReducer(state = InitialDealState, action: DealsActions.DealsAndDiscountActions) {
  switch (action.type) {
    case DealsActions.LOADDEALS:
      return {...state,loading:true};
    case DealsActions.LOADDEALS_SUCCESS:
      return {...state,deals:action.payload,loading:false}
    case DealsActions.LOADDEALS_FAILED:
      return {...state,loading:false};
    case DealsActions.LOAD_HIGHLITED_DEALS:
      return {...state,loading:true};
    case DealsActions.LOAD_HIGHLITED_DEALS_SUCCESS:
      return {...state,highlitedDeal:action.payload,loading:false};
    case DealsActions.LOAD_HIGHLITED_DEALS_FAILED:
      return {...state,loading:false};
    case DealsActions.LOADDISCOUNT:
      return {...state,loading:true};
    case DealsActions.LOADDISCOUNT_SUCCESS:
      return {...state,discounts:action.payload,loading:false};
    case DealsActions.LOADDEALSDETAILS:
      return {...state,loading:true};
    case DealsActions.LOADDEALSDETAILS_SUCCESS:
      return {...state,dealsDetails:action.payload,loading:false};
      default:
      return state;
  }
}
export function HighlitedDealReducer(state: any, action: DealsActions.DealsAndDiscountActions) {
  switch (action.type) {
    case DealsActions.LOAD_HIGHLITED_DEALS:
      return state;
    case DealsActions.LOAD_HIGHLITED_DEALS_SUCCESS:
      state = action.payload;
      return state;
    case DealsActions.LOAD_HIGHLITED_DEALS_FAILED:
      return state;
    case DealsActions.LOADDEALSDETAILS:
      return{...state,}
    default:
      return state;
  }
}
export const DealsState = (state: APPSTATE) => state.dealsreducer;

// export const SelecthighlitedDeals = createSelector(DealsState, ((state: any) => state ? state : null));
export const SelectAllDealsLoading = createSelector(DealsState, ((state: any) => state ? state.loading : null));
export const SelectAllDeals = createSelector(DealsState, ((state: any) => state ? state.deals : null));
export const SelectAllDiscounts = createSelector(DealsState, ((state: any) => state ? state.discounts : null));
export const SelectDealsDetails = createSelector(DealsState, ((state: any) => state ? state.dealsDetails : null));

