import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Cs2LibService } from "projects/cs2-lib/src/public-api";
import { Observable } from "rxjs";
import { Action } from "rxjs/internal/scheduler/Action";
import { catchError, map, mergeMap } from "rxjs/operators";
import * as fromUserSignupAction from '../Actions/UserSignup.action';
import * as urlmodel from '../../Store/Services/ServiceUrls';
import { of } from "rxjs";
Injectable()
export class UserSignupEffect{
    constructor(public cs2:Cs2LibService,public action:Actions){

    }
    @Effect()
    UserSignup$:Observable<any>=this.action.pipe(ofType(fromUserSignupAction.USERSIGNUP),
    mergeMap((action:any)=>this.cs2.PostData(urlmodel.UrlModel.Common.UserSignup,action.payload)
    .pipe(map(res=>new fromUserSignupAction.UserSignupSuccesss(res),
     catchError(err=>of(new fromUserSignupAction.UserSignupFailed(err)))
    ))))
}