
export const GETPREFERANCESOPTIONS = '[MyAccount] Preferances Options';
export const GETPREFERANCESOPTIONS_SUCCESS = '[MyAccount] Preferances Options Success';
export const GETPREFERANCESOPTIONS_FAILED = '[MyAccount] Preferances Options Failed';
export const UPDATEPREFERANCE = '[MyAccount] Preferances Update';
export const UPDATEPREFERANCE_SUCCESS = '[MyAccount] Preferances Update Success';
export const GETPERSONALINFO = '[MyAccount] Get Personal Info';
export const GETPERSONALINFO_SUCCESS = '[MyAccount] Get Personal Info Success';
export const MYWEDDINGDETAILS = '[MyAccount] My Wedding Details';
export const MYWEDDINGDETAILS_SUCCESS = '[MyAccount] My Wedding Details Success';
export const UPDATEWEDDINGDETAILS = '[MyAccount] Update Wedding Details';
export const UPDATEWEDDINGDETAILS_SUCCESS = '[MyAccount] Update Wedding Details Success';
export const UPDATEPERSONALDETAILS = '[MyAccount] Update Personal Details';
export const UPDATEPERSONALDETAILS_SUCCESS = '[MyAccount] Update Personal Details Success';
export const SETCOUPLEPAGE_TITLE = '[MyAccount]Set Couple Page Title';
export const UPLOADFILE='[MyAccount]Upload File';
export const UPLOADFILE_SUCCESS='[MyAccount]Upload File Success';
export const UPLOADFILE_FAILED='[MyAccount]Upload File Failed';
export const UPDATEACCOUNTIMAGE='[MyAccount]Update Account Image'
export const UPDATEACCOUNTIMAGE_SUCCESS='[MyAccount]Update Account Image Success'
export const UPDATEACCOUNTIMAGE_FAILED='[MyAccount]Update Account Image Failed'

export class GetPreferanceOptions {
    public readonly type = GETPREFERANCESOPTIONS;
    constructor() { }
}
export class GetPreferanceOptionsSuccess {
    public readonly type = GETPREFERANCESOPTIONS_SUCCESS;
    constructor(public payload: any) { }
}
export class GetPreferanceOptionsFailed {
    public readonly type = GETPREFERANCESOPTIONS_FAILED;
    constructor(public payload: any) { }
}
export class UpdatePreferance {
    public readonly type = UPDATEPREFERANCE;
    constructor(public payload: any) {
    }
}
export class UpdatePreferanceSuccess {
    public readonly type = UPDATEPREFERANCE_SUCCESS;
    constructor(public payload: any) {
    }
}
export class GetPersonalInfo {
    public readonly type = GETPERSONALINFO;
    constructor(public payload: any) {
    }
}
export class GetPersonalInfoSuccess {
    public readonly type = GETPERSONALINFO_SUCCESS;
    constructor(public payload: any) {
    }
}
export class MyWeddingDetails {
    public readonly type = MYWEDDINGDETAILS;
    constructor(public payload: any) {
    }
}
export class MyWeddingDetailsSuccess {
    public readonly type = MYWEDDINGDETAILS_SUCCESS;
    constructor(public payload: any) {
    }
}
export class UpdateWeddingDetails {
    public readonly type = UPDATEWEDDINGDETAILS;
    constructor(public payload: any) { }
}
export class UpdateWeddingDetailsSuccess {
    public readonly type = UPDATEWEDDINGDETAILS_SUCCESS;
    constructor(public payload: any) { }
}
export class UpdatePersonalDetails {
    public readonly type = UPDATEPERSONALDETAILS;
    constructor(public payload: any) { }
}
export class UpdatePersonalDetailsSuccess {
    public readonly type = UPDATEPERSONALDETAILS_SUCCESS;
    constructor(public payload: any) { }
}
export class SetCouplePageTitle {
    public readonly type = SETCOUPLEPAGE_TITLE;
    constructor(public payload: string) { }
}
export class UploadFile{
    public readonly type=UPLOADFILE;
    constructor(public payload:any){}
}
export class UploadFileSuccess{
    public readonly type=UPLOADFILE_SUCCESS;
    constructor(public payload:any){}
}
export class UploadFileFailed{
    public readonly type=UPLOADFILE_FAILED;
    constructor(public payload:any){}
}
export class UpdateAccountImage{
    public readonly type=UPDATEACCOUNTIMAGE;
    constructor(public payload:any){}
}
export class UpdateAccountImageSuccess{
    public readonly type=UPDATEACCOUNTIMAGE_SUCCESS;
    constructor(public payload:any){}
}
export class UpdateAccountImageFailed{
    public readonly type=UPDATEACCOUNTIMAGE_FAILED;
    constructor(public payload:any){}
}
export type MyAccountActions = GetPreferanceOptions | GetPreferanceOptionsFailed | GetPreferanceOptionsSuccess |
    UpdatePreferance |UpdatePreferanceSuccess| GetPersonalInfo | GetPersonalInfoSuccess | MyWeddingDetails | MyWeddingDetailsSuccess |
    UpdatePersonalDetails | UpdatePersonalDetailsSuccess | UpdateWeddingDetails | UpdateWeddingDetailsSuccess |
    SetCouplePageTitle|UploadFile|UploadFileSuccess|UploadFileFailed|UpdateAccountImage|UpdateAccountImageSuccess|UpdateAccountImageFailed;