import * as urls from '../../../../home/Store/Services/ServiceUrls';
import * as fromMyinspiration from '../../Actions/Couple/MyInspiration.action';
import { Injectable } from '@angular/core';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/Operators';

@Injectable()
export class MyInspirationEffect {
    constructor(public cs2: Cs2LibService, public action: Actions) {
    }
    @Effect()

    getMyInspirationPhotos$: Observable<any> = this.action.pipe(ofType(fromMyinspiration.MYINSPIRATIONPHOTOS),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.MyInspiration.MyInspirationPhotos , action.payload)
            .pipe(map((res) => new fromMyinspiration.MyinspirationPhotosSuccess(res),
                catchError(err => of(new fromMyinspiration.MyinspirationPhotosFailed()))
            ))));
    @Effect()
    getMyInspirationEvents$: Observable<any> = this.action.pipe(ofType(fromMyinspiration.MYINSPIRATIONEVENTS),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.MyInspiration.MyInspirationEvents + action.filter)
            .pipe(map((res) => new fromMyinspiration.MyinspirationEventsSuccess(res),
                catchError(err => of(new fromMyinspiration.MyinspirationEventsFailed()))
            ))));
    @Effect()
    DeleteMyInspiration$: Observable<any> = this.action.pipe(ofType(fromMyinspiration.DELETEMYINSPIRATION),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.MyInspiration.DeleteMyInspiration + action.payload)
            .pipe(map((res) => new fromMyinspiration.DeleteMyInspirationSuccess(res),
                catchError(err => of(new fromMyinspiration.DeleteMyInspirationFailed(err)))
            ))));
            @Effect()
            MyInspirationDeals$: Observable<any> = this.action.pipe(ofType(fromMyinspiration.MYINSPIRATIONDEAL),
                mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.MyInspiration.MyInspirationDeal)
                    .pipe(map((res) => new fromMyinspiration.MyInspirationDealSuccess(res),
                        catchError(err => of(new fromMyinspiration.MyInspirationDealFailed(err)))
            ))));

}