import * as fromMyGuestlistAction from '../../Actions/Couple/MyGuestlist.action';
import * as urls from '../../../Store/Services/ServiceUrls';
import { Injectable } from '@angular/core';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/Operators';
@Injectable()
export class MyGuestEffect {
    constructor(public cs2: Cs2LibService, public action: Actions) {

    }
    @Effect()
    createUpdateGroup$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.ADDUPDATEGROUP),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.GuestList.CreateUpdateGrooup, action.payload)
            .pipe(map((res: any) => new fromMyGuestlistAction.AddUpdateGrooupSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.AddUpdateGrooupFailed(err)))
            ))));
    @Effect()
    getMyGroup$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.GETGROUP),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.GuestList.GetMyGroup)
            .pipe(map((res: any) => new fromMyGuestlistAction.GetGroupSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.GetGroupFailed(err)))
            ))));
    @Effect()
    removeGroup$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.REMOVE_GROUP),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.GuestList.RemoveGroup,action.payload)
        .pipe(map((res: any) => new fromMyGuestlistAction.RemoveGroupSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.RemoveGroupFailed(err)))
            ))));

    @Effect()
    createUpdateMenu$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.ADDUPDATEMENU),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.GuestList.CraeteUpdateMenu, action.payload)
            .pipe(map((res: any) => new fromMyGuestlistAction.AddUpdateMenuSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.AddUpdateMenuFailed(err.json()))))
            )));
    @Effect()
    getMenu$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.GETMENU),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.GuestList.GetMyMenu)
            .pipe(map((res: any) => new fromMyGuestlistAction.GetMenuSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.GetMenuFailed(err)))
            ))));
    @Effect()
    removeMenu$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.REMOVE_MENU),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.GuestList.RemoveMenu+action.menuId)
            .pipe(map((res: any) => new fromMyGuestlistAction.RemoveMenuSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.RemoveMenuFailed(err)))
            ))));
    @Effect()
    createUpdateGuest$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.ADDUPDATEGUEST),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.GuestList.CreateUpdateGuest, action.payload)
            .pipe(map((res: any) => new fromMyGuestlistAction.AddUpdateGuestSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.AddUpdateGuestFailed(err)))
            ))));
    @Effect()
    getGuestCountByMenu$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.GETGUESTCOUNTBYMENU),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.GuestList.MyGuestCountByMenu)
            .pipe(map((res: any) => new fromMyGuestlistAction.GetGuestCountByMenuSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.GetGuestCountByMenuFailed(err)))
            ))));
    @Effect()
    getGuestCountByGroup$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.GETGUESTCOUNTBYGROUP),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.GuestList.MyGroupGuestCount)
            .pipe(map((res: any) => new fromMyGuestlistAction.GetGuestCountByGroupSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.GetGuestCountByGroupFailed(err)))
            ))));
    @Effect()
    getGuestCountByAgeGroup$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.GETGUESTCOUNTBYAGEGROUP),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.GuestList.MyGuestCount)
            .pipe(map((res: any) => new fromMyGuestlistAction.GetGuestCountByAgeGroupSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.GetGuestCountByAgeGroupFailed(err)))
            ))));
    @Effect()
    MyGuestSearch$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.SEARCHMYGUEST),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.GuestList.MyGuestSearch, action.payload)
            .pipe(map((res: any) => new fromMyGuestlistAction.SearchMyGuestSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.SearchMyGuestFailed(err)))
            ))));
    @Effect()
    UpdateGuestInvitation$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.UPDATEINVITATION),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.GuestList.UpdateGuestInvitation, action.payload)
            .pipe(map((res: any) => new fromMyGuestlistAction.UpdateGuestInvitationSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.UpdateGuestInvitationFailed(err)))
            ))));
    @Effect()
    myInvitationCount$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.MYINVITATIONCOUNT),
        mergeMap((action: any) => this.cs2.getData(urls.UrlModel.User.GuestList.MyInvitationCount)
            .pipe(map((res: any) => new fromMyGuestlistAction.MyInvitationCountSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.MyInvitationCountFailed(err)))
            ))));
    @Effect()
    RemoveGuest$: Observable<any> = this.action.pipe(ofType(fromMyGuestlistAction.REMOVEGUEST),
        mergeMap((action: any) => this.cs2.PostData(urls.UrlModel.User.GuestList.RemoveMyGuest, action.payload)
            .pipe(map((res: any) => new fromMyGuestlistAction.RemoveGuestSuccess(res),
                catchError(err => of(new fromMyGuestlistAction.RemoveGuestFailed(err)))
            ))));

}