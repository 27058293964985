import { Action } from '@ngrx/store';

export const LOADDEALS = '[Deals] Load';
export const LOADDEALS_SUCCESS = '[Deals] Load Deal Success';
export const LOADDEALS_FAILED = '[Deals] Load Deal Failed';
export const LOAD_HIGHLITED_DEALS = '[Deals] Load Highlited Deal';
export const LOAD_HIGHLITED_DEALS_SUCCESS = '[Deals] Load Highlited Deal Success';
export const LOAD_HIGHLITED_DEALS_FAILED = '[Deals] Load Highlited Deal Failed';
export const LOADDISCOUNT='[Deals]Load Discounts';
export const LOADDISCOUNT_SUCCESS='[Deals]Load Discounts Success';
export const LOADDEALSDETAILS='[Deals]Load Deals Details';
export const LOADDEALSDETAILS_SUCCESS='[Deals]Load Deals Details Success';
export class LoadDeals implements Action {
  public readonly type = LOADDEALS;
  constructor(public payload: any) { }
}
export class LoadDealsSuccess implements Action {
  public readonly type = LOADDEALS_SUCCESS;
  constructor(public payload: any) {
    
  }
}
export class LoadDealsFailed implements Action {
  public readonly type = LOADDEALS_FAILED;
  constructor(public payload: any) { }
}

export class LoadHighlitedDeals implements Action {
  public readonly type = LOAD_HIGHLITED_DEALS;
  constructor(public payload: any) { }
}
export class LoadHighlitedDealsSuccess implements Action {
  public readonly type = LOAD_HIGHLITED_DEALS_SUCCESS;
  constructor(public payload: any) { }
}
export class LoadHighlitedDealsFailed implements Action {
  public readonly type = LOAD_HIGHLITED_DEALS_FAILED;
  constructor(public payload: any) { }
}
export class LoadDiscounts{
  public readonly type=LOADDISCOUNT;
  constructor(public payload:any){}
}
export class LoadDiscountsSuccess{
  public readonly type=LOADDISCOUNT_SUCCESS;
  constructor(public payload: any){}
}
export class LoadDealsDetails{
  public readonly type=LOADDEALSDETAILS;
  constructor(public payload: number){

  }
}
export class LoadDealsDetailSuccess{
  public readonly type=LOADDEALSDETAILS_SUCCESS;
  constructor(public payload:any){}
}
export type DealsAndDiscountActions = LoadDeals | LoadDealsSuccess | LoadDealsFailed
  | LoadHighlitedDeals | LoadHighlitedDealsSuccess |LoadDealsDetails|LoadDealsDetailSuccess|
    LoadHighlitedDealsFailed|LoadDiscounts|LoadDiscountsSuccess;
