    <div fxLayout="column">
      <i (click)='dialog.closeAll()' class="material-icons lnk closeBtn">close</i>
    
<mat-progress-bar color='accent' *ngIf='(login$|async)?.processing'></mat-progress-bar>
<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">person_pin</mat-icon>
      Couple
    </ng-template>
    <form [formGroup]='Formlogin'>
      <div fxLayout="column">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName='userName' [(ngModel)]='userName' required>
          <mat-error *ngIf='f.userName.hasError("required") && issubmited'>User name Required</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput type='password' formControlName='password' [(ngModel)]='password' placeholder="Password"
            required>
          <mat-error *ngIf='f.password.hasError("required") && issubmited'>Password Required</mat-error>
        </mat-form-field>
        <button mat-raised-button  class="redGradientBtn" (click)='loginUser("u")'>Login</button>
      </div>
    </form>
    <div fxLayout='column'>
      <a class="forgetPwd" (click)='goto("/home/forgetPassword")' routerLink='/forgetpassword'>Forgot Password</a>
      <span class="socialLogin">Social Login </span>
      <div #gl id="gl" fxLayout="row" style="justify-content: space-evenly;">
        <img (click)='loginWithFb()' class="socialIcons" src="../../../assets/icons/iconfinder_facebook_icon_1399544.svg" alt="" >
        <!-- <img Class='socialIcons'   src="../../../assets/icons/google-icon.svg" alt="" > -->
        <!-- <div class="g-signin2" data-onsuccess="onSignIn"></div>         -->
      </div>
      <span class="notAMember">Not a member ? <b class="signup"
          (click)='goto("/home/usersignup")'>Signup</b></span>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">storefront</mat-icon>
      Vendor
    </ng-template>
    <form [formGroup]='FormVendorLogin'>
      <div fxLayout="column">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName='userName' [(ngModel)]='vendorLoginName' required>
          <mat-error *ngIf='fv.userName.hasError("required") && issubmited'>User name Required</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput type='password' formControlName='password' [(ngModel)]='VenorPassword' placeholder="Password"
            required>
          <mat-error *ngIf='fv.password.hasError("required") && issubmited'>Password Required</mat-error>
        </mat-form-field>
        <button mat-raised-button class="purpleGradientBtn" (click)='loginUser("v")'>Login</button>
      </div>
    </form>
    <div fxLayout="column">
      <a class="forgetPwd" (click)='goto("/home/forgetPassword")' routerLink='/forgetpassword'>
        Forgot Password</a>
      <span class="notAMember">Not a member ? <b class="signup"
          (click)='goto("home/vendorsignup-step1")'>Signup</b></span>
    </div>
  </mat-tab>
</mat-tab-group>
</div>
