import * as urls from '../../../Store/Services/ServiceUrls';
import * as fromMyAccount from '../../Actions/Couple/MyAccount.action';
import { Injectable } from '@angular/core';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/Operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class MyAccountEffect{
    constructor(public cs2:Cs2LibService,public action:Actions){
    }
    @Effect()
    getPreferanceOptions$:Observable<any>=this.action.pipe(ofType(fromMyAccount.GETPREFERANCESOPTIONS),
    switchMap((action:any)=>this.cs2.getData(urls.UrlModel.User.MyAccount.getPreferanceOptions).
    pipe(map((res)=>new fromMyAccount.GetPreferanceOptionsSuccess(res),
    catchError(err=>of(new fromMyAccount.GetPreferanceOptionsFailed(err)))
    ))));
    @Effect()
    getPersonalDetails$:Observable<any>=this.action.pipe(ofType(fromMyAccount.GETPERSONALINFO),
    switchMap((action:any)=>this.cs2.getData(urls.UrlModel.User.MyAccount.getPersonalInfo).
    pipe(map((res)=>new fromMyAccount.GetPersonalInfoSuccess(res),
    catchError(err=>of(new fromMyAccount.GetPreferanceOptionsFailed(err)))
    ))));
    @Effect()
    getMyWeddingDetails$:Observable<any>=this.action.pipe(ofType(fromMyAccount.MYWEDDINGDETAILS),
    switchMap((action:any)=>this.cs2.getData(urls.UrlModel.User.MyAccount.myWeddingDetails).
    pipe(map((res)=>new fromMyAccount.MyWeddingDetailsSuccess(res),
    catchError(err=>of(new fromMyAccount.GetPreferanceOptionsFailed(err)))
    ))));
    @Effect()
    UpdatePreferance$:Observable<any>=this.action.pipe(ofType(fromMyAccount.UPDATEPREFERANCE),
    switchMap((action:any)=>this.cs2.PostData(urls.UrlModel.User.MyAccount.updateWeddingPreferance,action.payload).
    pipe(map((res)=>new fromMyAccount.UpdatePreferanceSuccess(res),
    catchError(err=>of(new fromMyAccount.GetPreferanceOptionsFailed(err)))
    ))));
    @Effect()
    UpdatePersonalDetails$:Observable<any>=this.action.pipe(ofType(fromMyAccount.UPDATEPERSONALDETAILS),
    switchMap((action:any)=>this.cs2.PostData(urls.UrlModel.User.MyAccount.updatePersonalDetails,action.payload).
    pipe(map((res)=>new fromMyAccount.GetPersonalInfo(res),
    catchError(err=>of(new fromMyAccount.GetPreferanceOptionsFailed(err)))
    ))));
    @Effect()
    UpdateWeddingDetails$:Observable<any>=this.action.pipe(ofType(fromMyAccount.UPDATEWEDDINGDETAILS),
    switchMap((action:any)=>this.cs2.PostData(urls.UrlModel.User.MyAccount.updateWeddingDetails,action.payload).
    pipe(map((res)=>new fromMyAccount.MyWeddingDetails(res),
    catchError(err=>of(new fromMyAccount.GetPreferanceOptionsFailed(err)))
    ))));
    @Effect()
    UpLoadImage$:Observable<any>=this.action.pipe(ofType(fromMyAccount.UPLOADFILE),
    switchMap((action:any)=>this.cs2.postImageData(urls.UrlModel.Common.UploadFile,action.payload).
    pipe(map((res)=>new fromMyAccount.UploadFileSuccess(res),
    catchError(err=>of(new fromMyAccount.UploadFileFailed(err)))
    ))));
    @Effect()
    UpdateAccountImage$:Observable<any>=this.action.pipe(ofType(fromMyAccount.UPDATEACCOUNTIMAGE),
    switchMap((action:any)=>this.cs2.postImageData(urls.UrlModel.User.MyAccount.UpdateAccountImage,action.payload).
    pipe(map((res)=>new fromMyAccount.UpdateAccountImageSuccess(res),
    catchError(err=>of(new fromMyAccount.UpdateAccountImageFailed(err)))
    ))));

}