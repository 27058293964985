import { Action, createSelector } from '@ngrx/store';
import {
  LOADBLOGS, LOADBLOGS_FAILED, LOADBLOGS_SUCCESS, LOADBLOGS_TOPICS,
  LOADBLOGS_TOPICS_FAILED, LOADBLOGS_TOPICS_SUCCESS
} from '../Actions/Blog.action';
import * as blogactions from '../Actions/Blog.action';
import { APPSTATE } from '../HomePageState';
import { pipe } from 'rxjs';
export const InitialBlogState = { blogs: {}, blogtopics: [], blogDetails: {}, loading: false };
export function BlogReducer(state = InitialBlogState, action: blogactions.BlogActions) {
  switch (action.type) {
    case LOADBLOGS:
      return { ...state, loading: true };
    case LOADBLOGS_SUCCESS:
      return { ...state, blogs: action.payload, blogtopics: state.blogtopics, blogDetails: state.blogDetails, loading: false };
    case LOADBLOGS_FAILED:
      return { ...state, blogs: state.blogs, blogtopics: state.blogtopics, blogDetails: state.blogDetails };
    case LOADBLOGS_TOPICS:
      return { ...state, blogs: state.blogs, blogtopics: state.blogtopics, blogDetails: state.blogDetails };
    case LOADBLOGS_TOPICS_SUCCESS:
      return { ...state, blogs: state.blogs, blogtopics: action.payload, blogDetails: state.blogDetails };
    case blogactions.LOADBLOGDETAILS:
      return { ...state, blogs: state.blogs, blogtopics: state.blogtopics, blogDetails: state.blogDetails };
    case blogactions.LOADBLOGDETAILS_SUCCESS:
      return { ...state, blogs: state.blogs, blogtopics: state.blogtopics, blogDetails: action.payload };
    default:
      return { ...state, blogs: state.blogs, blogtopics: state.blogtopics, blogDetails: state.blogDetails };
  }
}
export const SelectBlogState = (state: APPSTATE) => state.blogreducer;
export const SelectBlogTopics = createSelector(SelectBlogState, ((state: any) => state ? state.blogtopics : null));
export const SelectBlogs = createSelector(SelectBlogState, ((state: any) => state ? state.blogs : null));
export const SelectBlogsDetails = createSelector(SelectBlogState, ((state: any) => state ? state.blogDetails : null));
export const SelectBlogLoading = createSelector(SelectBlogState, ((state: any) => state.loading ? state.loading : null));