import { createSelector } from "@ngrx/store";
import { GETREVIEWFAILED, GETREVIEWS, GETREVIEWSUCCESS, ReviewsActions } from "../Actions/Reviews.action";
import { APPSTATE } from "../HomePageState";

export const ReviewInitialState={
    reviews:{},
    loading:false,
    getreviewfailed:null
}
export function ReviewReducer(state=ReviewInitialState,action:ReviewsActions){
    switch(action.type){
        case GETREVIEWS:
            return{...state,loading:true}
        case GETREVIEWSUCCESS:
            console.log(action.payload);
            return{...state,loading:false,reviews:action.payload}
        case GETREVIEWFAILED:
            return{...state,loading:false,getreviewsfailed:action.payload}
        default:
            return state
    }
}
export const GetReviewReducer=(state:APPSTATE)=>state;
export const SelectReviews=createSelector(GetReviewReducer,((state:any)=>state.reviewreducer?state.reviewreducer.reviews:null));
export const SelectReviewsLoader=createSelector(GetReviewReducer,((state:any)=>state.reviewreducer?state.reviewreducer.loading:null));