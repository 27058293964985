<div style="max-width: vh; width: vh">
  <mat-toolbar color="accent" style="position: fixed">
    <mat-toolbar-row style="justify-content: space-between">
      <div>
        <span
          mat-icon-button
          (click)="toggleMainMenu()"
          fxShow="true"
          fxHide.gt-sm
        >
          <mat-icon
            style="
              margin-top: 10px !important;
              vertical-align: middle;
              cursor: pointer;
              height: 35px;
            "
            >menu</mat-icon
          >
        </span>
        <span>
          <a
            routerLink="/home"
            (mouseenter)="mouseEnter(25)"
            [ngStyle]="{
              'font-size': cs2.CurrentViewPort == 2 ? '16px' : '18px'
            }"
            style="text-decoration: none; color: white"
          >
            <img
              src="../assets/heart.png"
              width="30px"
              height="30px"
              style="vertical-align: middle; cursor: pointer"
              alt="perfectWeddings"
            />
            Perfect Weddings</a
          ></span
        >
      </div>
      <div
        style="
          justify-content: space-evenly;
          flex-direction: row;
          display: flex;
          width: 75%;
          margin-left: 10%;
        "
        fxShow="true"
        fxHide.lt-md
      >
        <div
          style="margin-left: 2%; font-size: 16px; cursor: pointer"
          (mouseenter)="mouseEnter(0)"
        >
          Wedding Vendors
          <i
            class="material-icons"
            style="line-height: 30px; vertical-align: middle"
            >expand_more</i
          >
        </div>

        <div
          class="tbr"
          *ngFor="let m of homepagemenu$ | async; let i = index"
          style="margin-left: 2%; font-size: 16px; cursor: pointer; width: 10%"
          [routerLink]="m.path"
          (mouseenter)="mouseEnter(2)"
        >
          <span style="position: fixed">{{ m.title }}</span>
        </div>
        <div
          style="display: flex; flex-direction: row"
          *ngIf="(userdetails$ | async) != null"
          fxShow="false"
        >
          <span style="margin-left: 25%; position: absolute; cursor: pointer"
            ><i
              class="material-icons lnk"
              (click)="router.navigateByUrl('/user/mymessages')"
              >chat_bubble_outline</i
            >
            {{ (unreadmessages$ | async) > 0 ? (unreadmessages$ | async) : "" }}
          </span>
        </div>
      </div>
      <div fxLayout="row" class="mobileLoggedInMenu">
        <span *ngIf="currentUser != null">
          <i
            class="material-icons"
            style="margin-left: -50%; cursor: pointer"
            (click)="router.navigateByUrl('/user/mymessages')"
            >chat_bubble_outline</i
          >
          {{ (unreadmessages$ | async) > 0 ? (unreadmessages$ | async) : "" }}
        </span>
        <span
          id="perosn_pinIcon"
          *ngIf="currentUser === null"
          (click)="userNavToggle('null')"
        >
          <i class="material-icons lnk">account_circle</i>
        </span>
        <img
          *ngIf="currentUser !== null"
          style="
            width: 35px;
            height: 35px;
            border-radius: 50%;
            cursor: pointer;
            object-fit: cover;
          "
          [src]="
            currentUser.profilepic ? currentUser.profilepic : cs2.noProfileImg
          "
          alt="Perfect-Wedding-User"
          (click)="userNavToggle('null')"
        />
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="homepageSubmenu" *ngIf="showSubmenu" (mouseleave)="mouseLeave()">
    <div style="column-count: 3; column-gap: 10px; height: 300px">
      <ul class="submenu_ul">
        <li
          style="font-weight: 500; font-size: 12px; height: 40px"
          *ngFor="let sm of submenu$ | async"
          [value]="sm"
          (click)="gotoListing(sm)"
        >
          <i
            *ngIf="sm.categoryId === 0"
            class="material-icons"
            style="width: 8%; margin-right: 2%"
            >format_list_bulleted</i
          >
          <img
            *ngIf="sm.categoryId !== 0"
            class="catIcon"
            [src]="sm.iconImageURL"
            [alt]="sm.categoryName"
          />
          {{ sm.categoryName }}
        </li>
      </ul>
    </div>
    <hr />
    <div fxLayout="row" style="justify-content: center">
      <span class="areyouvendor"> Are you a Vendor ? </span>
      <span
        class="redGradientBtn signup"
        (click)="showSubmenu = !showSubmenu"
        routerLink="/home/vendorsignup"
      >
        Sign up</span
      >
    </div>
  </div>

  <mat-drawer-container
    autosize
    mode="over"
    [ngClass]="cs2.CurrentViewPort == 4 ? 'xlscreen' : ''"
    style="position: inherit"
  >
    <!--Drawer 1-->
    <mat-drawer
      #sidenav
      hasBackdrop="true"
      style="max-height: 100%; height: 100%; z-index: 99999"
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 10px;
          margin-right: 10%;
        "
      >
        <i class="material-icons lnk" (click)="toggleMainMenu()">close</i>
      </div>
      <mat-nav-list
        (click)="sidenavSubmenu.toggle()"
        routerLink="/home/Listing"
      >
        <span>
          <i class="material-icons drawerMenuIcon">people_outline</i>Wedding
          Vendors
          <i class="material-icons submenuNextArror">{{
            cs2.dataservice.getIcon("next")
          }}</i>
        </span>
      </mat-nav-list>
      <mat-nav-list
        *ngFor="let m of homepagemenu$ | async; let i = index"
        (click)="toggleMainMenu()"
        [routerLink]="m.path"
      >
        <span style="font-weight: 500">
          <i class="material-icons drawerMenuIcon">{{ m.icon }}</i
          >{{ m.title }}
        </span>
      </mat-nav-list>
      <mat-nav-list (click)="gotoPage('home/advertise')">
        <i class="material-icons drawerMenuIcon">storefront</i>For Business
      </mat-nav-list>
      <mat-nav-list (click)="gotoPage('home/aboutus')">
        <i class="material-icons drawerMenuIcon">house</i>About Us
      </mat-nav-list>
      <mat-nav-list (click)="gotoPage('home/career')">
        <i class="material-icons drawerMenuIcon">assignment_ind</i>Career
      </mat-nav-list>
      <mat-nav-list (click)="gotoPage('home/faqs')">
        <i class="material-icons drawerMenuIcon">create</i>Faqs
      </mat-nav-list>
      <mat-nav-list (click)="gotoPage('home/contactus')">
        <i class="material-icons drawerMenuIcon">contact_mail</i>Contact Us
      </mat-nav-list>

      <!--Deawer 2-->
      <mat-drawer
        style="margin-left: -15px !important"
        id="mat-drawer2"
        #sidenavSubmenu
        hasBackdrop="true"
      >
        <mat-list
          style="
            width: 100%;
            height: 100vh;
            overflow-y: auto;
            margin-left: -15px;
            font-weight: 500;
          "
        >
          <mat-list-item (click)="sidenavSubmenu.toggle()">
            <i class="material-icons submenuNextArror drawerMenuIcon">{{
              cs2.dataservice.getIcon("back")
            }}</i>
          </mat-list-item>
          <mat-list-item
            *ngFor="let sm of submenu$ | async; let j = index"
            (click)="gotoListing(sm)"
            style="font-weight: 500px; font-size: 14px"
          >
            <img
              class="drawerMenuIcon"
              *ngIf="sm.iconImageURL !== null"
              [src]="sm.iconImageURL"
              [alt]="sm.categoryName"
              width="20"
              height="20"
            />
            {{ sm.categoryName }}
          </mat-list-item>
        </mat-list>
      </mat-drawer>
      <!--Deawer 2 end-->
    </mat-drawer>
    <!--Deawer 3-->
    <mat-drawer
      #userNav
      position="end"
      [ngStyle]="{ width: cs2.setInnerContentSize2(75, 25) }"
      style="
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100vh;
        box-shadow: 0 0 10px;
      "
    >
      <div
        fxLayout="column"
        style="
          width: 100%;
          justify-content: center;
          justify-items: center;
          text-align: center;
        "
      >
        <img
          *ngIf="currentUser !== null"
          style="
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-left: 25%;
            margin-top: 2%;
            object-fit: cover;
          "
          [src]="
            currentUser.profilepic ? currentUser.profilepic : cs2.noProfileImg
          "
          [alt]="currentUser ? currentUser.FirstName : ''"
        />
        <span class="userName"
          ><b style="color: black">Hi</b>
          {{ currentUser ? currentUser.FirstName : "" }}</span
        >
      </div>

      <ul class="usermenu">
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/dashboard')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/img/wedding-ring.png"
            alt="My Wedding"
          />
          My Wedding
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/rfq')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/img/checklist.png"
            alt="Request for Quotes"
          />
          Request for Quotes
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/myinspiration')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/img/lightbulb (1).png"
            alt="My Inspirations"
          />
          My Inspirations
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/mychecklist')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/img/checklist.png"
            alt="Checklist"
          />
          Checklist
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/myisuppliers')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/icons/icons-supplier.png"
            alt="My Suppliers"
          />
          My Suppliers
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/myguestlist')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/img/wedding.png"
            alt="Guest List"
          />
          Guest List
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/mybudget')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/img/budget.png"
            alt="Budget Planner"
          />
          Budget Planner
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/myenquiries')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/img/review.png"
            alt="My Enquiries"
          />
          My Enquiries
        </li>

        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/myreviews')"
          mat-menu-item
        >
          <img
            class="userMenuIcons"
            src="../assets/img/review.png"
            alt="My Reviews"
          />
          My Reviews
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/myaccount')"
          mat-menu-item
        >
          <i
            style="margin-right: 2%; font-size: xx-large"
            class="material-icons-outlined"
            >settings_applications</i
          >
          My Account
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('/user/mymessages')"
          mat-menu-item
        >
          <i
            style="margin-right: 2%; font-size: xx-large"
            class="material-icons"
            >chat_bubble_outline</i
          >
          Messages
        </li>
        <li
          class="lnk mat-nav-list"
          (click)="userNavToggle('user/myfeedback')"
          mat-menu-item
        >
          <i
            class="material-icons-outlined"
            style="margin-right: 2%; font-size: xx-large"
            >thumb_up</i
          >Feedback
        </li>
        <li class="lnk mat-nav-list" (click)="logoutUser()" mat-menu-item>
          <i
            class="material-icons"
            style="margin-right: 2%; font-size: xx-large"
            >exit_to_app</i
          >Log Out
        </li>
      </ul>
    </mat-drawer>
    <!--Deawer 3 end-->
    <mat-drawer-content
      measureScrollOffset="top"
      fxFlexFill
      Style="padding-top:64px!important"
    >
      <router-outlet></router-outlet>
      <div *ngIf="cs2.setCss() == 'mobileView'" class="mobileBrandFooter">
        <span
          style="
            text-align: center;
            width: 75%;
            line-height: 20px;
            font-size: 14px;
          "
          (click)="cs2.goto('home/AdminLogin')"
        >
          <img
            style="width: 30px; height: 30px; vertical-align: middle"
            src="../assets/heart.png"
            alt="perfectWeddings"
          />
          Perfect Weddings &copy; 2016 - {{ CurYear }}
        </span>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
