import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Routes = [

  {path:'',redirectTo:'/home', pathMatch:'full'},
  {path:'home',loadChildren:()=>import('../../home/home.module').then(m=>m.HomeModule)},
  {path:'home/:url',loadChildren:()=>import('../../home/home.module').then(m=>m.HomeModule)},
  { path: 'user', loadChildren: () => import('../../home/user/user.module').then(m => m.UserModule) },
    // { path: 'admin', loadChildren: () => import(`./admin/admin.module`).then(m => m.AdminModule) },
   { path: '**', redirectTo:'home',pathMatch:'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload',initialNavigation:'enabled',scrollPositionRestoration:'enabled'}),
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
