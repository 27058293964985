
export const GETMYREVIEWS='[Reviews]Get My Reviews';
export const GETMYREVIEWS_SUCCESS='[Reviews]Get My Reviews Success';
export const GETMYREVIEWS_FAILED='[Reviews]Get My Reviews Failed';
export const CREATEUPDATEREVIEWS='[Reviews]Create Update Reviews';
export const CREATEUPDATEREVIEWS_SUCCESS='[Reviews]Create Update Reviews Success';
export const CREATEUPDATEREVIEWS_FAILED='[Reviews]Create Update Reviews Failed';
export class GetMyReviews{
    public readonly type=GETMYREVIEWS;
    constructor(public payload:any){}
}
export class GetMyReviewsSuccess{
    public readonly type=GETMYREVIEWS_SUCCESS;
    constructor(public payload:any){}
}
export class GetMyReviewsFailed{
    public readonly type=GETMYREVIEWS_FAILED;
    constructor(public payload:any){
    }
}
export class CreateUpdateReviews{
    public readonly type=CREATEUPDATEREVIEWS;
    constructor(public payload:any){
    }
}
export class CreateUpdateReviewsSuccess{
    public readonly type=CREATEUPDATEREVIEWS_SUCCESS;
    constructor(public payload:any){
    }
}
export class CreateUpdateReviewsFailed{
    public readonly type=CREATEUPDATEREVIEWS_FAILED;
    constructor(public payload:any){
    }
}
export type MyReviewsActions=GetMyReviews|GetMyReviewsSuccess|GetMyReviewsFailed|
                              CreateUpdateReviews|CreateUpdateReviewsSuccess|CreateUpdateReviewsFailed