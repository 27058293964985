import { NgModule, ModuleWithProviders } from '@angular/core';
import { Cs2LibComponent } from './cs2-lib.component';
import { HttpClientModule } from '@angular/common/http';
import { RatingformComponent } from './ratingform/ratingform.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { ImgSliderComponent } from './img-slider/img-slider.component';
import { GraphSwitcherComponent } from './graph-switcher/graph-switcher.component';
import { PagingComponent } from './paging/paging.component';
import { CommonModule } from '@angular/common';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { WebshareComponent } from './webshare/webshare.component';
import { ChatboxComponent } from './chatbox/chatbox.component';
import { Cs2SocketService } from './cs2-socket.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Cs2SocialService } from './cs2-social.service';
import { RatingBarComponent } from './rating-bar/rating-bar.component';
import { RatingDetailsComponent } from './rating-details/rating-details.component';
@NgModule({
  declarations: [Cs2LibComponent, RatingformComponent, FileuploadComponent, ImgSliderComponent,
    GraphSwitcherComponent, PagingComponent, YoutubePlayerComponent, WebshareComponent, ChatboxComponent, 
    RatingBarComponent, RatingDetailsComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,


  ],
  providers: [Cs2SocketService,Cs2SocialService],
  exports: [Cs2LibComponent, RatingformComponent, FileuploadComponent, ImgSliderComponent, GraphSwitcherComponent,
    PagingComponent, YoutubePlayerComponent, WebshareComponent, ChatboxComponent,RatingBarComponent,RatingDetailsComponent],
  entryComponents: [Cs2LibComponent, YoutubePlayerComponent],

})
export class Cs2LibModule {
  static forRoot(config: Cs2LibModule): ModuleWithProviders<any> {
    return {
      ngModule: Cs2LibModule,
      providers: [{ provide: Cs2LibModule, useValue: config }]
    };
  }
}
