
export const USERSIGNUP='[UserSignup]=UserSignup';
export const USERSIGNUPSUCCESS='[UserSignup]=User Signup Success';
export const USERSIGNUPFAILED='[UserSignup]=UserSignup Failed';

export class UserSignup{
    public readonly type=USERSIGNUP;
    constructor(public payload:any){}
}
export class UserSignupSuccesss{
    public readonly type=USERSIGNUPSUCCESS;
    constructor(public payload:any){}
}
export class UserSignupFailed{
    public readonly type=USERSIGNUPFAILED;
    constructor(public payload:any){}
}
export type UserSignupActions=UserSignup|UserSignupSuccesss|UserSignupFailed;