import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { APIService } from "../Services/api.service";
import { Store } from "@ngrx/store";
import { APPSTATE } from "../HomePageState";
import { Observable, of } from "rxjs";
import * as searchActions from "../Actions/Listing.action";
import { mergeMap, map, catchError } from "rxjs/Operators";

@Injectable()
export class SearchEffect {
  constructor(
    public action: Actions,
    public api: APIService,
    public store: Store<APPSTATE>
  ) {}

  @Effect()
  SearchListingEffect$: Observable<any> = this.action.pipe(
    ofType(searchActions.LOADLISTING),
    mergeMap((action: any) =>
      this.api
        .PostData(this.api.objUrls.Application.SearchListing, action.payload)
        .pipe(
          map(
            (res: any) => new searchActions.SearchListingSuccess(res),
            catchError((error) =>
              of(new searchActions.SearchListingFailed(error))
            )
          )
        )
    )
  );
  @Effect()
  ListingDetailEffect$: Observable<any> = this.action.pipe(
    ofType(searchActions.LISTINGDETAILS),
    mergeMap((action: any) =>
      this.api
        .getData(
          this.api.objUrls.Application.ListingDetails +
            action.payload.id +
            "&adtypeId=" +
            action.payload.ispromoted +
            "&loggedInUserId=" +
            action.payload.loggedInUserId
        )
        .pipe(
          map(
            (res) => new searchActions.ListingDetailSuccess(res),
            catchError((error) =>
              of(new searchActions.SearchListingFailed(error))
            )
          )
        )
    )
  );
  @Effect()
  GetPrices$: Observable<any> = this.action.pipe(
    ofType(searchActions.GETPRICES),
    mergeMap((action: any) =>
      this.api.getData(this.api.objUrls.Common.GetFilterPrices).pipe(
        map(
          (res) => new searchActions.GetFilterPricesSuccess(res),
          catchError((error) =>
            of(new searchActions.GetFilterPricesFailed(error))
          )
        )
      )
    )
  );
  @Effect()
  GetSimilarVendors$: Observable<any> = this.action.pipe(
    ofType(searchActions.GETSIMILAR_VENDORS),
    mergeMap((action: any) =>
      this.api
        .PostData(
          this.api.objUrls.Application.GetSimilarVendors,
          action.payload
        )
        .pipe(
          map(
            (res) => new searchActions.GetSimilarVendorsSucccess(res),
            catchError((error) =>
              of(new searchActions.GetSimilarVendorsFailed(error))
            )
          )
        )
    )
  );
}
