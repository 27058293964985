import * as urls from '../../../Store/Services/ServiceUrls';
import * as fromMyMessages from '../../Actions/Couple/MyMessages.action';
import { Injectable } from '@angular/core';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/Operators';

@Injectable()
export class MyMesagesEffect{
    constructor(public cs2:Cs2LibService,public action:Actions){

    }
    @Effect()
    getMyMessages$:Observable<any>=this.action.pipe(ofType(fromMyMessages.GETMYMESSAGES),
    switchMap((action:any)=>this.cs2.PostData(urls.UrlModel.Vendor.Messages.MyMessages,action.payload)
    .pipe(map((res)=>new fromMyMessages.GetMyMessagesSuccess(res),
    catchError(err=>of(new fromMyMessages.GetMyMessagesFailed(err)))
    ))));
    @Effect()
    ReadMessages$:Observable<any>=this.action.pipe(ofType(fromMyMessages.MARKASREAD),
    switchMap((action:any)=>this.cs2.getData(urls.UrlModel.Vendor.Messages.MarkAsRead+action.payload)
    .pipe(map(res=>new fromMyMessages.ReadMessageSuccess(res),
    catchError(err=>of(new fromMyMessages.GetMyMessagesFailed(err)))
    ))));
    @Effect()
    DeleteMessages$:Observable<any>=this.action.pipe(ofType(fromMyMessages.DELETEMYMESSAGE),
    switchMap((action:any)=>this.cs2.PostData(urls.UrlModel.Vendor.Messages.MessageDelete,action.payload)
    .pipe(map(res=>new fromMyMessages.GetMyMessages({filter:0,search:''}),
    catchError(err=>of(new fromMyMessages.GetMyMessagesFailed(err)))
    ))));
    @Effect()
    MarkAsStared$:Observable<any>=this.action.pipe(ofType(fromMyMessages.MARKASSTARED),
    switchMap((action:any)=>this.cs2.getData(urls.UrlModel.Vendor.Messages.MarkAsStarred+action.payload)
    .pipe(map(res=>new fromMyMessages.GetMyMessages({filter:0,search:''}),
    catchError(err=>of(new fromMyMessages.GetMyMessagesFailed(err)))
    ))));
    @Effect()
    PostMessage$:Observable<any>=this.action.pipe(ofType(fromMyMessages.POSTMESSAGE),
    switchMap((action:any)=>this.cs2.PostData(urls.UrlModel.Vendor.Messages.SendMessage,action.payload)
    .pipe(map(res=>new fromMyMessages.GetMyMessages({filter:0,search:''}),
    catchError(err=>of(new fromMyMessages.GetMyMessagesFailed(err)))
    ))));
    @Effect()
    getMessageHistory$:Observable<any>=this.action.pipe(ofType(fromMyMessages.MESSAGEHISTORY),
    switchMap((action:any)=>this.cs2.getData(urls.UrlModel.Vendor.Messages.MessageHistory+action.messageId)
    .pipe(map(res=>new fromMyMessages.GetMessageHistorySuccess(res),
    catchError(err=>of(new fromMyMessages.GetMessageHistoryFailed(err)))
    ))));

}
