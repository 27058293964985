import { Component, OnInit, Inject } from '@angular/core';
import { Cs2LibService } from 'projects/cs2-lib/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.scss']
})
export class ConfirmAlertComponent implements OnInit {
  

  constructor(public cs2:Cs2LibService,public dialogRef:MatDialogRef<ConfirmAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
     }

  ngOnInit() {
   
  }
  closeDialog(value){
      return this.dialogRef.close(value);
  }
}




