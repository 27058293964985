import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class YoutubePlayerService {

  constructor(public sanitizer: DomSanitizer) {

  }
  sanitizeUrl(url) {
    let UrlPart1 = url.split('v=')[1];
    let NewUrl = '';
    console.log(UrlPart1[UrlPart1.length - 1]);
    if (UrlPart1.includes('&')) {
      let NewUrl = 'https://www.youtube.com/embed/' + url.split('v=')[1].split('&')[0] + '?rel=0&autoplay=1&loop=1&playlist=GRonxog5mbw';
      return this.sanitizer.bypassSecurityTrustResourceUrl(NewUrl);
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + UrlPart1 + '?rel=0&autoplay=1&loop=1&playlist=GRonxog5mbw');
    }
  }
  getYoutubeImage(url) {
    if (url.includes('v=') && url.includes('&')) {
      return 'https://img.youtube.com/vi/' + url.split('v=')[1].split('&')[0] + '/default.jpg';

    }
  }
}
