import * as userActionLog from '../Actions/CommonActionLog.action';
import { Action, createSelector } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
export const InitialActionLog={
    loading:false,
    message:'',
    amountInAUD:0
}
export function ActionLogReducer(state=InitialActionLog,action:userActionLog.UserLogActions){
    switch(action.type){
        case userActionLog.USERACTION:
            return{...state,loading:true,message:state.message}
        case userActionLog.USERACTIONSUCCESS:
            return{...state,loading:false,message:action.payload}
        case userActionLog.USERACTIONFAILED:
            return {...state,loading:false,message:action.payload}
        case userActionLog.CONVERTMURTOAUD:
            return{...state,amountInAUD:0,loading:true}
        case userActionLog.CONVERTMURTOAUD_SUCESS:
            return{...state,amountInAUD:action.payload,loading:false}
        case userActionLog.CONVERTMURTOAUD_FAILED:
            return{...state,amountInAUD:0,loading:false}
        default:
            return state;
    }
}
export const GetState=(state:APPSTATE)=>state;
export const GetActionLogReducer=createSelector(GetState,(state:any)=>state.actionlogreducer?state.actionlogreducer:null);
export const SelectAmountInAUD=createSelector(GetState,(state:any)=>state.actionlogreducer?state.actionlogreducer.amountInAUD:null);