import * as fromMyMessages from '../../Actions/Couple/MyMessages.action';
import { APPSTATE } from '../../HomePageState';
import { createSelector } from '@ngrx/store';
export const MyMessageInitState = {
    loading: false,
    messages: [],
    myMsgAllCount: 0,
    myMsgStaredCount: 0,
    myMsgUnreadCount: 0,
    msgDetails: {},
    msgHistory:{}
}
export function MyMessagesReducer(state = MyMessageInitState, action: fromMyMessages.MessagesActions) {
    switch (action.type) {
        case fromMyMessages.GETMYMESSAGES:
            return {...state, loading: true}
        case fromMyMessages.GETMYMESSAGES_SUCCESS:
            return {
                ...state, loading: false, messages: action.payload.messages,
                myMsgAllCount: action.payload.all, myMsgStaredCount: action.payload.starred,
                myMsgUnreadCount: action.payload.unread, msgDetails: state.msgDetails
            }
        case fromMyMessages.GETMSGDETAILS:
            return {...state, loading: false,msgDetails: action.payload}
        case fromMyMessages.POSTMESSAGE:
            return state;
        case fromMyMessages.MARKASSTARED:
            return state;
        case fromMyMessages.GETMYMESSAGES_FAILED:
            return state;
        case fromMyMessages.DELETEMYMESSAGE:
            return state;
        case fromMyMessages.MARKASREAD:
            return state;
        case fromMyMessages.MARKASREAD_SUCCESS:
            return state;
        case fromMyMessages.GETMYMESSAGES_FAILED:
            return state;
        case fromMyMessages.MESSAGEHISTORY:
            return{...state,loading:true}
        case fromMyMessages.MESSAGEHISTORY_SUCCESS:
            return {...state,msgHistory:action.payload,loading:false}
        case fromMyMessages.MESSAGEHISTORY_FAILED:
            return{...state,loading:false}
        default:
            return state;
    }
}
export const getMyMessageReducer = (state: APPSTATE) => state;
export const SelectMyMessages = createSelector(getMyMessageReducer, (state: any) => state.mymessagesreducer ? state.mymessagesreducer.messages : null);
export const SelectmyMsgAllCount = createSelector(getMyMessageReducer, (state: any) => state.mymessagesreducer ? state.mymessagesreducer.myMsgAllCount : null);
export const SelectmyMsgStaredCount = createSelector(getMyMessageReducer, (state: any) => state.mymessagesreducer ? state.mymessagesreducer.myMsgStaredCount : null);
export const SelectmyMsgUnreadCount = createSelector(getMyMessageReducer, (state: any) => state.mymessagesreducer ? state.mymessagesreducer.myMsgUnreadCount : null);
export const SelectmyMsgloading = createSelector(getMyMessageReducer, (state: any) => state.mymessagesreducer ? state.mymessagesreducer.loading : null);
export const SelectmsgDetails = createSelector(getMyMessageReducer, (state: any) => state.mymessagesreducer ? state.mymessagesreducer.msgDetails : null);
export const SelectmsgHistory = createSelector(getMyMessageReducer, (state: any) => state.mymessagesreducer ? state.mymessagesreducer.msgHistory : null);
