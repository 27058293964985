import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { APIService } from '../Services/api.service';
import { Store } from '@ngrx/store';
import { APPSTATE } from '../HomePageState';
import * as blogaction from '../Actions/Blog.action';
import * as urlmodel from '../Services/ServiceUrls';
import { mergeMap, map, catchError } from 'rxjs/Operators';
@Injectable()
export class LoadBlogsEffect {
  constructor(public action: Actions, public api: APIService, public store: Store<APPSTATE>) {
  }
  @Effect()
  loadBlogsTopics$: Observable<any> = this.action.pipe(ofType(blogaction.LOADBLOGS_TOPICS),
    mergeMap((action) => this.api.getData(urlmodel.UrlModel.Application.BlogTopics)
      .pipe(map((res: any) =>
        new blogaction.LoadBlogsTopicSuccess(res),
        catchError(error => of(new blogaction.LoadBlogsTopicFail())
  )))));

  @Effect()
  loadBlogs$: Observable<any> = this.action.pipe(ofType(blogaction.LOADBLOGS),
    mergeMap((action: any) =>
      this.api.PostData(urlmodel.UrlModel.Application.SearchBlog, action.payload)
        .pipe(map((res: any) =>
          new blogaction.LoadBlogsSuccess(res),
          catchError(error => of(new blogaction.LoadBlogsTopicFail())
  )))));
  @Effect()
  loadBlogsDetails$: Observable<any> = this.action.pipe(ofType(blogaction.LOADBLOGDETAILS),
    mergeMap((action: any) =>
      this.api.getData(urlmodel.UrlModel.Application.BlogDetails+ action.payload)
        .pipe(map((res: any) =>
          new blogaction.LoadBlogDetailsSuccess(res),
          catchError(error => of(new blogaction.LoadBlogsTopicFail())
  )))));

}
