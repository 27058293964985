import { Action, createSelector } from '@ngrx/store';
import * as photoactions from '../Actions/Photos.action';
import { APPSTATE } from '../HomePageState';

export const Colors=[
    { colorName: 'pink', isSelected: false, isApplied: false },
    { colorName: 'red', isSelected: false, isApplied: false },
    { colorName: 'orange', isSelected: false, isApplied: false },
    { colorName: 'yellow', isSelected: false, isApplied: false },
    { colorName: 'green', isSelected: false, isApplied: false },
    { colorName: 'Blue', isSelected: false, isApplied: false },
    { colorName: 'purple', isSelected: false, isApplied: false },
    { colorName: 'brown', isSelected: false, isApplied: false },
    { colorName: 'black', isSelected: false, isApplied: false },
    { colorName: 'grey', isSelected: false, isApplied: false },
]
export const PhotoInitialState: any = {
  loading:false,
  colors:Colors,
  photos:[],
  album:{},
};
export function PhotoReducer(state = PhotoInitialState, action: photoactions.PhotoActions) {
  switch (action.type) {
    case photoactions.LOADPHOTOS:
    return {...state,loading:true,colors:state.colors,photos:state.photos,album:state.album};
    case photoactions.LOADPHOTOSSUCCESS:
    return {...state,loading:false,colors:state.colors,photos:action.payload,album:state.album};
    case photoactions.LOADPHOTOSBYALBUMID:
      return{...state,loading:true,colors:state.colors,photos:state.photos,album:state.album}
    case photoactions.LOADPHOTOBYALBUMID_SUCCESS:
      return{...state,loading:false,colors:state.colors,photos:state.photos,album:action.payload}
    case photoactions.LOADPHOTOFAILED:
      return state;
    case photoactions.COMPLETE_PROCESS:
      return{...state,loading:false}
    default:
      return{...state,loading:false}
  }
}
export const getPhotoReducer=(state:APPSTATE)=>state;
export const SelectColors=createSelector(getPhotoReducer,(state:any)=>state.photoreducer?state.photoreducer.colors:null);
export const SelectPhotos=createSelector(getPhotoReducer,(state:any)=>state.photoreducer?state.photoreducer.photos:null);
export const SelectAlbum=createSelector(getPhotoReducer,(state:any)=>state.photoreducer?state.photoreducer.album:null);
export const SelectPhotoLoader=createSelector(getPhotoReducer,(state:any)=>state.photoreducer?state.photoreducer.loading:null);